import './Home.css'
import Header from "./Components/Header.tsx";
import Footer from "./Components/Footer.tsx";
import Pricing from "./Components/Pricing.tsx";
import CLICardComponent from "./CLICardComponent.tsx";
import SiteFaq from "./SiteFaq.tsx";
import SiteFeatures from "./SiteFeatures.tsx";
import HelmetConfig from "../HelmetConfig.tsx";
import ImageCarousel from "./Components/ImageCarousel.tsx";
import CommandsCard from "./Components/CommandsCard.tsx";

function Home() {
    return (
        <>
            <HelmetConfig title="Home" description="Welcome to Kampa.io, the easiest way to set up local environments!" />

            <div className="bg-gray-100">

                <Header/>

                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="mx-auto max-w-4xl py-6 mt-6 lg:mt-12 sm:py-4 lg:py-6">
                        {/*<div className="hidden sm:mb-6 sm:flex sm:justify-center">*/}
                        {/*    <div*/}
                        {/*        className="relative rounded-full px-3 py-1 bg-white text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">*/}
                        {/*        Exciting news: Our tool now supports Docker and Podman {' '}*/}
                        {/*        <a href="#" className="font-semibold text-orange-600">*/}
                        {/*            <span aria-hidden="true" className="absolute inset-0"/>*/}
                        {/*            Read more <span aria-hidden="true">&rarr;</span>*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="text-center">
                            <h1 className="gradient-text font-bold tracking-tight text-3xl lg:text-7xl">
                                Effortless Dev Environments – Pick, Generate, and Code!
                            </h1>
                            <p className="mt-4 text-sm lg:text-lg leading-8 text-gray-700">
                                No more manual setup or maintaining multiple Dockerfiles. Kampa automates everything, generating Dockerfiles and docker-compose.yml for your stack.
                            </p>

                            <p className=" lg:mt-4 text-sm lg:text-lg leading-8 text-gray-700">
                                Powered by Docker and Podman, it ensures consistent environments on macOS, Linux, and Windows—so you can focus on coding, not configuration.
                            </p>

                        </div>
                    </div>

                    <ImageCarousel />

                </div>



                <CLICardComponent />
                <CommandsCard />
                <Pricing />
                <SiteFeatures />
                <SiteFaq />
            </div>

            <Footer/>
        </>
    )
}

export default Home
