import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {Link, useLocation} from "react-router-dom";
import { Dialog, DialogPanel } from "@headlessui/react";
import {useEffect, useState} from "react";
import useUserStore from "../../App/Stores/useUserStore.ts";

interface NavigationItem {
    name: string;
    href: string;
    hash: string;
}

const navigation: NavigationItem[] = [
    { name: 'Features', href: '/', hash: 'features' },
    { name: 'Documentation', href: '/docs/introduction', hash: '#' },
    { name: 'Pricing', href: '/', hash: 'pricing' },
    { name: 'FAQ', href: '/', hash: 'faq' },
    { name: 'Contact', href:'/contact', hash: '' },
];

function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const isLoggedIn = useUserStore(state => state.isLoggedIn);
    const { hash, key } = useLocation();

    useEffect(() => {
        if (hash) {
            const targetElement = document.getElementById(hash.substring(1));
            targetElement?.scrollIntoView({ behavior: "smooth" });
        }
    }, [key, hash]);

    useEffect(() => {
        const handleScroll = () => {
            // Check if user scrolled beyond the viewport height
            setIsScrolled(window.scrollY > window.innerHeight / 2);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (

            <header
                id="header"
                className={`w-full top-0 z-50 transition-all duration-300 ${
                    isScrolled ? "fixed bg-white shadow-md" : "relative"
                }`}
            >
            <div className="inset-x-0 top-0 z-50 max-w-7xl mx-auto sm:px-6 md:px-8 transition-all duration-300">
                <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
                <div className="flex lg:flex-1">
                    <Link
                          to={{
                              pathname: '/',
                              hash: 'header',
                          }}
                          className="-m-2.5 p-1.5">
                        <span className="sr-only">Kampa</span>
                        <img
                            alt="Configure your local environment"
                            src="/images/k.svg"
                            className="h-12 w-auto"
                        />
                    </Link>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        onClick={() => setMobileMenuOpen(true)}
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-12">
                    {navigation.map((item: NavigationItem) => (
                        <Link
                            key={item.name}
                            to={{
                                pathname: item.href,
                                hash: item.hash,
                            }}
                            className="text-sm font-semibold leading-6 text-gray-900"
                        >
                            {item.name}
                        </Link>
                    ))}
                </div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    {!isLoggedIn ? (
                        <>
                            <button className="rounded-full px-4 py-1 opacity-100 mr-4">
                                <Link to="/login" className="text-sm font-semibold leading-6 text-gray-900">
                                    Log in <span aria-hidden="true">&rarr;</span>
                                </Link>
                            </button>
                            <button className="rounded-full px-4 py-1 bg-orange-500 opacity-100">
                                <Link to="/sign-up" className="text-sm font-semibold leading-6 text-white">
                                    Sign up
                                </Link>
                            </button>
                        </>
                    ) : (
                        <button className="rounded-full px-4 py-1 bg-orange-500 opacity-100">
                            <Link to="/app/dashboard" className="text-sm font-semibold leading-6 text-white">
                                Dashboard
                            </Link>
                        </button>
                    )}
                </div>
            </nav>
            </div>

            <Dialog open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} className="lg:hidden">
                <div className="fixed inset-0 z-50"/>
                <DialogPanel
                    className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <Link
                                to={{
                                  pathname: '/',
                                  hash: 'header',
                              }}
                              className="-m-2.5 p-1.5">
                            <span className="sr-only">Kampa</span>
                            <img
                                alt="Configure your local environment"
                                src='/images/k.svg'
                                className="h-12 w-auto"
                            />
                        </Link>
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(false)}
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                {navigation.map((item: NavigationItem) => (
                                    <Link
                                        key={item.name}
                                        to={{
                                            pathname: item.href,
                                            hash: item.hash,
                                        }}
                                        onClick={() => setMobileMenuOpen(false)}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        {item.name}
                                    </Link>
                                ))}
                            </div>
                            <div className="py-6">
                                <Link
                                    to="/login"
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Log in
                                </Link>

                                <Link
                                    to="/sign-up"
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Sign up
                                </Link>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>
    );
}

export default Header;
