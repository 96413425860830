import React from "react";
import { Form, Select } from "antd"; // Assuming you are using Ant Design
import { SelectProps } from "antd/lib/select";
import {ServiceTypeSelectComponentProps} from "../LocalEnvironments/Services/Types.ts";

const ServiceTypeSelectComponent: React.FC<ServiceTypeSelectComponentProps> = ({ name, placeholder, rules, disabled, onChange = () => {}  }) => {
    const onSearch = () => {
        // console.log(`Search: ${value}`);
    };

    const options: SelectProps['options'] = [
        { key: 'application', value: 'application', label: 'Application' },
        { key: 'cache', value: 'cache', label: 'Cache' },
        { key: 'database', value: 'database', label: 'Database' },
        { key: 'developer_tools', value: 'developer_tools', label: 'Developer tools'},
        { key: 'message_broker', value: 'message_broker', label: 'Message Broker' },
        { key: 'monitoring', value: 'monitoring', label: 'Monitoring' },
        { key: 'worker', value: 'worker', label: 'Worker' },
    ];

    return (
        <Form.Item
            label="Type:"
            name={name}
            rules={rules}>
                <Select
                    showSearch
                    disabled={disabled}
                    placeholder={placeholder}
                    optionFilterProp="label"
                    onChange={onChange}
                    onSearch={onSearch}
                    options={options}
            />
        </Form.Item>
    );
};

export default ServiceTypeSelectComponent;
