import { Helmet } from "react-helmet-async";

interface HelmetConfigProps {
    title?: string;
    description?: string;
    keywords?: string;
}

const HelmetConfig: React.FC<HelmetConfigProps> = ({ title, description, keywords }) => {
    return (
        <Helmet>
            <title>{title ? `${title} | Kampa.io` : "Kampa.io - Simplify Local Environment Setup for Development Teams"}</title>
            <meta name="description" content={description || "Kampa.io streamlines local environment setup, enabling development teams to boost productivity with minimal hassle."} />
            <meta name="keywords" content={ keywords || "local environment setup, development tools, team productivity, Kampa.io, development environment management"} />
            <meta name="robots" content="index, follow" />
            <meta name="author" content="Kampa.io" />

            {/* Open Graph Meta Tags */}
            <meta property="og:title" content={title ? `${title} | Kampa.io` : "Kampa.io - Simplify Local Environment Setup for Development Teams"} />
            <meta property="og:description" content={description || "Kampa.io streamlines local environment setup, enabling development teams to boost productivity with minimal hassle."} />
            <meta property="og:url" content="https://kampa.io" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="https://www.kampa.io/images/og-image.webp" />

            {/* Twitter Meta Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title ? `${title} | Kampa.io` : "Kampa.io - Simplify Local Environment Setup for Development Teams"} />
            <meta name="twitter:description" content={description || "Kampa.io streamlines local environment setup, enabling development teams to boost productivity with minimal hassle."} />
            <meta name="twitter:image" content="https://www.kampa.io/images/twitter-image.webp" />
        </Helmet>
    );
};

export default HelmetConfig;
