import {Button, Form} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import EnvironmentItem from "./EnvironmentItem.tsx";

export default function EnvironmentItemTabsService({tabTitle, tabType, itemRefs, form, includeProxy, data}) {
    return (
        <>
            <Form.List name={['services', tabType]}>
                {(fields, { add, remove }) => (
                    <>
                        <Button
                                type="dashed"
                                onClick={() => {
                                const newIndex = fields.length; // Calculate the new index
                                    add(); // Add a new field
                                    setTimeout(() => {
                                        // Scroll to the newly added field
                                        if (itemRefs.current[newIndex]) {
                                            itemRefs.current[newIndex].scrollIntoView({
                                                behavior: "smooth",
                                                block: "start",
                                            });
                                        }
                                }, 100); // Small timeout to ensure DOM is updated
                            }}
                            block
                            icon={<PlusOutlined />}
                        >
                            Add {tabTitle}
                        </Button>

                        {fields.map(({ key, name, ...restField }, index) => (
                        <div
                            key={key}
                            ref={(el) => {
                                 if (el) itemRefs.current[index] = el; // Assign dynamic refs by index
                            }}
                        >
                            <EnvironmentItem
                                uniqueKey={key}
                                form={form}
                                name={name}
                                tabType={tabType}
                                includeProxy={includeProxy}
                                data={data}
                                remove={(fieldName) => {
                                    remove(fieldName); // Remove the item
                                    delete itemRefs.current[index]; // Clean up references
                                }}
                                {...restField}
                            />
                            </div>
                        ))}

                    </>
                )}
            </Form.List>
        </>
    )
}