import React from "react";

const TermsAndConditions: React.FC = () => {
    return <>

        <div className="card-header py-sm-10 mb-12">
            <h1 className="text-xl">Terms And Conditions</h1>
            <p className="card-text">Last modified: February 2, 2025</p>
        </div>
        <div className="card-body">
            <div className="mb-7">
                <h1>Terms and conditions</h1>
                <p>These terms and conditions (“Agreement”) set forth the general terms and conditions of your use of
                    the <a href="https://www.kampa.io">kampa.io</a> website (“Website” or “Service”) and any of its
                    related products and services (collectively, “Services”). This Agreement is legally binding between
                    you (“User”, “you” or “your”) and Kampa (“Kampa”, “we”, “us” or “our”). If you are entering into
                    this Agreement on behalf of a business or other legal entity, you represent that you have the
                    authority to bind such entity to this Agreement, in which case the terms “User”, “you” or “your”
                    shall refer to such entity. If you do not have such authority, or if you do not agree with the terms
                    of this Agreement, you must not accept this Agreement and may not access and use the Website and
                    Services. By accessing and using the Website and Services, you acknowledge that you have read,
                    understood, and agree to be bound by the terms of this Agreement. You acknowledge that this
                    Agreement is a contract between you and Kampa, even though it is electronic and is not physically
                    signed by you, and it governs your use of the Website and Services.</p>
                <div className="toc"><h3>Table of contents</h3>
                    <ol className="toc">
                        <li><a href="#accounts-and-membership">Accounts and membership</a></li>
                        <li><a href="#billing-and-payments">Billing and payments</a></li>
                        <li><a href="#accuracy-of-information">Accuracy of information</a></li>
                        <li><a href="#links-to-other-resources">Links to other resources</a></li>
                        <li><a href="#intellectual-property-rights">Intellectual property rights</a></li>
                        <li><a href="#changes-and-amendments">Changes and amendments</a></li>
                        <li><a href="#acceptance-of-these-terms">Acceptance of these terms</a></li>
                        <li><a href="#contacting-us">Contacting us</a></li>
                    </ol>
                </div>
                <h2 id="accounts-and-membership">Accounts and membership</h2>
                <p>You must be at least 18 years of age to use the Website and Services. By using the Website and
                    Services and by agreeing to this Agreement you warrant and represent that you are at least 18 years
                    of age. If you create an account on the Website, you are responsible for maintaining the security of
                    your account and you are fully responsible for all activities that occur under the account and any
                    other actions taken in connection with it. We may, but have no obligation to, monitor and review new
                    accounts before you may sign in and start using the Services. Providing false contact information of
                    any kind may result in the termination of your account. You must immediately notify us of any
                    unauthorized uses of your account or any other breaches of security. We will not be liable for any
                    acts or omissions by you, including any damages of any kind incurred as a result of such acts or
                    omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine
                    that you have violated any provision of this Agreement or that your conduct or content would tend to
                    damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not
                    re-register for our Services. We may block your email address and Internet protocol address to
                    prevent further registration.</p>
                <h2 id="billing-and-payments">Billing and payments</h2>
                <p>You shall pay all fees or charges to your account in accordance with the fees, charges, and billing
                    terms in effect at the time a fee or charge is due and payable. Where Services are offered on a free
                    trial basis, payment may be required after the free trial period ends, and not when you enter your
                    billing details (which may be required prior to the commencement of the free trial period). If
                    auto-renewal is enabled for the Services you have subscribed for, your payment information will be
                    securely saved and you will be charged automatically in accordance with the term you selected. If,
                    in our judgment, your purchase constitutes a high-risk transaction, we will require you to provide
                    us with a copy of your valid government-issued photo identification, and possibly a copy of a recent
                    bank statement for the credit or debit card used for the purchase. We reserve the right to change
                    products and product pricing at any time. We also reserve the right to refuse any order you place
                    with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per
                    household or per order. These restrictions may include orders placed by or under the same customer
                    account, the same credit card, and/or orders that use the same billing and/or shipping address. In
                    the event that we make a change to or cancel an order, we may attempt to notify you by contacting
                    the email and/or billing address/phone number provided at the time the order was made.</p>
                <h2 id="accuracy-of-information">Accuracy of information</h2>
                <p>Occasionally there may be information on the Website that contains typographical errors, inaccuracies
                    or omissions that may relate to product descriptions, pricing, availability, promotions and offers.
                    We reserve the right to correct any errors, inaccuracies or omissions, and to change or update
                    information or cancel orders if any information on the Website or Services is inaccurate at any time
                    without prior notice (including after you have submitted your order). We undertake no obligation to
                    update, amend or clarify information on the Website including, without limitation, pricing
                    information, except as required by law. No specified update or refresh date applied on the Website
                    should be taken to indicate that all information on the Website or Services has been modified or
                    updated.</p>
                <h2 id="links-to-other-resources">Links to other resources</h2>
                <p>Although the Website and Services may link to other resources (such as websites, mobile applications,
                    etc.), we are not, directly or indirectly, implying any approval, association, sponsorship,
                    endorsement, or affiliation with any linked resource, unless specifically stated herein. We are not
                    responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or
                    individuals or the content of their resources. We do not assume any responsibility or liability for
                    the actions, products, services, and content of any other third parties. You should carefully review
                    the legal statements and other conditions of use of any resource which you access through a link on
                    the Website. Your linking to any other off-site resources is at your own risk.</p>
                <h2 id="intellectual-property-rights">Intellectual property rights</h2>
                <p>“Intellectual Property Rights” means all present and future rights conferred by statute, common law
                    or equity in or in relation to any copyright and related rights, trademarks, designs, patents,
                    inventions, goodwill and the right to sue for passing off, rights to inventions, rights to use, and
                    all other intellectual property rights, in each case whether registered or unregistered and
                    including all applications and rights to apply for and be granted, rights to claim priority from,
                    such rights and all similar or equivalent rights or forms of protection and any other results of
                    intellectual activity which subsist or will subsist now or in the future in any part of the world.
                    This Agreement does not transfer to you any intellectual property owned by Kampa or third parties,
                    and all rights, titles, and interests in and to such property will remain (as between the parties)
                    solely with Kampa. All trademarks, service marks, graphics and logos used in connection with the
                    Website and Services, are trademarks or registered trademarks of Kampa or its licensors. Other
                    trademarks, service marks, graphics and logos used in connection with the Website and Services may
                    be the trademarks of other third parties. Your use of the Website and Services grants you no right
                    or license to reproduce or otherwise use any of Kampa or third party trademarks.</p>
                <h2 id="changes-and-amendments">Changes and amendments</h2>
                <p>We reserve the right to modify this Agreement or its terms related to the Website and Services at any
                    time at our discretion. When we do, we will revise the updated date at the bottom of this page. We
                    may also provide notice to you in other ways at our discretion, such as through the contact
                    information you have provided.</p>
                <p>An updated version of this Agreement will be effective immediately upon the posting of the revised
                    Agreement unless otherwise specified. Your continued use of the Website and Services after the
                    effective date of the revised Agreement (or such other act specified at that time) will constitute
                    your consent to those changes.</p>
                <h2 id="acceptance-of-these-terms">Acceptance of these terms</h2>
                <p>You acknowledge that you have read this Agreement and agree to all its terms and conditions. By
                    accessing and using the Website and Services you agree to be bound by this Agreement. If you do not
                    agree to abide by the terms of this Agreement, you are not authorized to access or use the Website
                    and Services.</p>
                <h2 id="contacting-us">Contacting us</h2>
                <p>If you have any questions, concerns, or complaints regarding this Agreement, we encourage you to
                    contact us using the details below:</p>
                <p><a href="https://www.kampa.io/contact">https://www.kampa.io/contact</a></p>
                <p>This document was last updated on February 2, 2025</p>
            </div>
        </div>


    </>
}

export default TermsAndConditions;