import React, {useEffect, useState} from 'react';
import { Button, Form, Input, message } from 'antd';
import {Template} from "./Types.ts";
import TemplateRepository from "./Repositories/TemplateRepository.ts";
import {useNavigate} from "react-router-dom";
import {BreadcrumbLink} from "../Shared/Types.ts";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import CodeTextAreaComponent from "../Shared/CodeTextAreaComponent.tsx";

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/templates',
        title: 'Templates',
    },
    {
        href: '/app/templates/create',
        title: 'Create',
    },
];

const CreateTemplate: React.FC = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [messageApi, contextHolder] = message.useMessage();
    const [code, setCode] = useState<string>('');

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
    }, []);

    const onFinish = async (values: Template) => {
        try {
            await TemplateRepository.save(values);
            messageApi.open({
                type: 'success',
                content: 'Template created successfully',
            });
            setTimeout(() => navigate('/app/templates'), 2250);
        } catch (error) {
            message.error('Failed to create template: ' + error.message);
        }
    };

    return (
        <>
            {contextHolder}
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Templates</h3>

                <div className="flex bg-white flex-col mt-8">
                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">

                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label="Name:"
                                    name="name"
                                    rules={[{ required: true, message: 'Please input the template name!' }]}
                                >
                                    <Input placeholder="Name" />
                                </Form.Item>

                                <Form.Item
                                    label="Description:"
                                    name="description"
                                    rules={[{ required: true, message: 'Please input the template path!' }]}
                                >
                                    <Input placeholder="description" />
                                </Form.Item>

                                <CodeTextAreaComponent
                                    label={''}
                                    name={'content'}
                                    code={code}
                                    setCode={setCode}
                                    language={'json'}
                                />

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">Save</Button>
                                </Form.Item>
                            </Form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateTemplate;