import React, { useEffect, useState } from 'react';
import {PaginationLink, PaginatedResponse} from "../Types.ts";
import FiltersManager from "./FiltersManage.ts";
import {Spin} from "antd";

type GenericListWithFiltersProps<T> = {
    fetchData: (filters: FiltersManager) => Promise<PaginatedResponse<T>>;
    renderItem: (item: T) => React.ReactNode;
    columns: string[];
    initialFilters: FiltersManager;
};

const GenericListWithFilters = <T,>({ fetchData, renderItem, columns, initialFilters }: GenericListWithFiltersProps<T>) => {
    const [items, setItems] = useState<T[]>([]);
    const [pagination, setPagination] = useState<PaginationLink[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState<FiltersManager>(initialFilters);
    const [total, setTotal] = useState(0);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await fetchData(filters);
                setItems(response.data);
                setPagination(response.links);
                setTotal(response.total);
                setFrom(response.from);
                setTo(response.to);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchItems();
    }, [currentPage, fetchData]);

    const handlePageChange = (url: string | null) => {
        if (url) {
            const page = new URL(url).searchParams.get('page');

            if (page) {
                setFilters((prev: FiltersManager) =>
                    new FiltersManager({
                        ...prev.getAll(),
                        page: parseInt(page, 10),
                    })
                );

                setCurrentPage(parseInt(page, 10));
            }
        }
    };


    if (loading) {
        return <>
            <div className="container px-6 mx-auto">
                <div className="flex flex-col ">
                    <Spin />
                </div>
            </div>
        </>
    }


    return (
    <div className="container px-6 mx-auto">

        <div className="flex flex-col ">
            <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div
                    className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">

                    <table className="min-w-full">
                        <thead>
                        <tr>
                            {columns.map((column, index) => (
                                <th
                                    key={index}
                                    className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                                >
                                    {column}
                                </th>
                            ))}
                        </tr>
                        </thead>

                        <tbody className="min-w-full bg-white">
                        {items.map((item, index) => (
                            <tr key={index}>{renderItem(item)}</tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div className="mt-4">
            <nav className="flex justify-between items-center">
                <div className="flex-1 flex justify-between sm:hidden">
                    <button
                        onClick={() => handlePageChange(pagination.find(link => link.label.includes('Previous'))?.url)}
                      className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      disabled={!pagination.find(link => link.label.includes('Previous'))?.url}
                    >
                        Previous
                    </button>
                    <button
                      onClick={() => handlePageChange(pagination.find(link => link.label.includes('Next'))?.url)}
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      disabled={!pagination.find(link => link.label.includes('Next'))?.url}
                    >
                        Next
                    </button>
                </div>
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                    <div>
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{from}</span> to <span className="font-medium">{to}</span> of <span className="font-medium">{total}</span> results
                        </p>
                    </div>
                    <div>
                        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                            {pagination.map((link, index) => (
                              <button
                                key={index}
                                onClick={() => handlePageChange(link.url)}
                                className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium ${
                                  link.active ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600' : 'bg-white text-gray-500 hover:bg-gray-50'
                                }`}
                                dangerouslySetInnerHTML={{ __html: link.label }}
                              />
                            ))}
                        </nav>
                    </div>
                </div>
            </nav>
        </div>
    </div>
    );
};

export default GenericListWithFilters;