import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import TeamRepository from "./Repositories/TeamRepository";
import {Button, Form, Input, message, Popconfirm} from "antd";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import teamRepository from "./Repositories/TeamRepository.ts";
import {BreadcrumbLink} from "../Shared/Types.ts";
import {Team} from "./Types.ts";
import UsersSelectComponent from "../Shared/UsersSelectComponent.tsx";

const { TextArea } = Input;

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/teams',
        title: 'Teams',
    },
    {
        href: '#',
        title: 'Edit',
    },
];

const EditTeam: React.FC = () => {
    const [form] = Form.useForm();
    const {teamUuid} = useParams();
    const navigate = useNavigate();
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [messageApi, contextHolder] = message.useMessage();
    const [team, setTeam] = useState<Team>();
    const [loading, setLoading] = useState<boolean>(true);

    const onFinish = async (values: Team) => {
        try {
            if (teamUuid != null) {
                values.uuid = teamUuid;
            }

            // return;
            await teamRepository.update(values);

            messageApi.open({
                type: 'success',
                content: 'Team updated successfully',
            });

            setTimeout(() => navigate('/app/teams'), 2250);
        } catch (error) {
            message.error('Failed to update team: ' + error.message);
        }
    };

    const confirm: (uuid) => void = async (uuid) => {
        try {
            await teamRepository.destroy(uuid);

            messageApi.open({
                type: 'success',
                content: 'Team deleted successfully',
            });

            setTimeout(() => navigate('/app/teams'), 2250);
        } catch (error) {
            message.error('Failed to delete team: ' + error.message);
        }
    };

    useEffect(() => {
        setBreadcrumb(breadcrumbs);

        const fetchTeam = async (teamUuid: string | undefined) => {
            try {
                const response:Team = await TeamRepository.findByUuid(teamUuid);
                form.setFieldsValue(response);
                setTeam(response)
            } catch (error) {
                console.error('Error fetching teams:', error);
            }
        };

        fetchTeam(teamUuid);
        setLoading(false)
     }, []);

    if (loading) {
        return <>Loading...</>
    }

    return (
        <>
            {contextHolder}
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Teams</h3>

                <div className="flex bg-white flex-col mt-8">

                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">


                        <div
                            className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <h3 className="text-xl font-medium text-gray-700 mb-7">Update</h3>

                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label="Name:"
                                    name="name"
                                    rules={[{required: true, message: 'Please input the team name!'}]}
                                >
                                    <Input placeholder="Name"/>
                                </Form.Item>

                                <Form.Item label="Description:" name="description">
                                    <TextArea
                                        rows={4}
                                        placeholder="Team's description"
                                        maxLength={150}
                                        showCount
                                    />
                                </Form.Item>

                                <UsersSelectComponent
                                    label={"Members"}
                                    name={"members"}
                                    isMultiple={true}
                                />

                                <UsersSelectComponent
                                    label={"Owners"}
                                    name={"owners"}
                                    isMultiple={true}
                                />

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">Update</Button>
                                </Form.Item>
                            </Form>

                        </div>
                    </div>
                </div>

                <div className="flex bg-white flex-col mt-8">

                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">

                        <div
                            className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <h3 className="text-xl font-medium text-gray-700 mb-4">Delete</h3>

                            <p className="text-sm text-gray-700 mb-4">
                                Deleting this item will remove all associated data and cannot be reversed.
                                Please ensure you have backed up any important information before proceeding.
                            </p>

                            <Popconfirm
                                title="Delete"
                                description="Are you sure to delete this item?"
                                onConfirm={() => confirm(team.uuid)}
                                okText="Yes"
                                cancelText="No"
                                className={''}
                            >
                                <Button danger>Yes, I'll permanently delete it</Button>
                            </Popconfirm>

                        </div>
                    </div>
                </div>


            </div>

        </>
    );
};

export default EditTeam;
