import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Radio, message, TabsProps, Tabs, Popconfirm, Tooltip} from 'antd';
import { Service } from "./Types.ts";
import {Link, useNavigate, useParams} from "react-router-dom";
import { BreadcrumbLink } from "../../Shared/Types.ts";
import useBreadcrumbStore from "../../Stores/useBreadcrumbStore.tsx";
import TeamSelectComponent from "../../Shared/TeamSelectComponent.tsx";
import ServiceTypeSelectComponent from "../../Shared/ServiceTypeSelectComponent.tsx";
import serviceRepository from "./Repositories/ServiceRepository.ts";
import dayjs from 'dayjs';
import ServiceRepository from "./Repositories/ServiceRepository.ts";
import {DocumentPlusIcon} from "@heroicons/react/24/outline";

const { TextArea } = Input;

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/local-environments',
        title: 'Local environments',
    },
    {
        href: '/app/services',
        title: 'Services',
    },
    {
        href: '#',
        title: 'Edit',
    },
];


const EditService: React.FC = () => {
    const [form] = Form.useForm();
    const {serviceUuid} = useParams();
    const navigate = useNavigate();
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [messageApi, contextHolder] = message.useMessage();
    const [activeKey, setActiveKey] = useState<string>("1"); // Initialize with the default active key
    const [data, setData] = useState<Service>();
    const [loading, setLoading] = useState<boolean>(true)
    const handleTabChange = (key) => {
        setActiveKey(key);
    };

    const onFinish = async (values: Service) => {
        try {
            values['uuid'] = serviceUuid
            await serviceRepository.update(values);
            messageApi.open({
                type: 'success',
                content: 'Service updated successfully',
            });
            setTimeout(() => navigate('/app/services'), 2250);
        } catch (error) {
            message.error('Failed to update service: ' + error.message);
        }
    };

    const confirm: (uuid) => void = async (uuid) => {
        try {
            await serviceRepository.destroy(uuid);

            messageApi.open({
                type: 'success',
                content: 'Service deleted successfully',
            });

            setTimeout(() => navigate('/app/services'), 2250);
        } catch (error) {
            message.error('Failed to delete service: ' + error.message);
        }
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Data',
            forceRender: true,
            children: <>
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={data}
                    onFinish={onFinish}
                >

                    <div className={'mb-2'}></div>

                    <Form.Item label="Status:" name="status">
                        <Radio.Group>
                            <Radio.Button value="active">Active</Radio.Button>
                            <Radio.Button value="inactive">Inactive</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="Visibility:" name="is_private">
                        <Radio.Group>
                            <Radio.Button value="false">Public</Radio.Button>
                            <Radio.Button value="true">Private</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <TeamSelectComponent
                        name={'team_uuid'}
                        label={'Team:'}
                        disabled={true}
                        placeholder={'Select one of your teams'}
                        rules={[{required: true, message: 'Select one team'}]}
                    />

                    <ServiceTypeSelectComponent
                        name={'type'}
                        disabled={true}
                        placeholder={'Select the type of the service'}
                        rules={[{required: true, message: 'Select the type of the service'}]}
                    />

                    <Form.Item
                        label="Name:"
                        name="name"
                        rules={[{required: true, message: 'Please input the team name!'}]}
                    >
                        <Input placeholder="Name"/>
                    </Form.Item>
                    <Form.Item label="Description:" name="description">
                        <TextArea
                            rows={4}
                            placeholder="Service's description"
                            maxLength={150}
                            showCount
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </>,
        },
        {
            key: '2',
            label: 'Versions',
            forceRender: true,
            children: <>
                <div className={'mb-2'}></div>

                <table className="min-w-full">
                    <thead>
                    <tr>
                        <th
                            className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                        >
                            Name
                        </th>
                        <th
                            className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                        >
                            Image
                            <Tooltip title="Unique identifier for the image">
                                ?
                            </Tooltip>
                        </th>

                        <th
                            className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                        >
                            Created at
                        </th>
                        <th
                            className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                        >
                        </th>

                    </tr>
                    </thead>

                    <tbody className="min-w-full bg-white">
                    {data?.versions ? (
                        <>
                            {
                                data.versions.map((item, index) => (
                                    <tr key={index}>
                                        <th
                                            className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200"
                                        >
                                            {item.name}
                                        </th>

                                        <th
                                            className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200"
                                        >
                                            {item.image}
                                        </th>
                                        <th
                                            className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200"
                                        >
                                            {dayjs(item.created_at).format('YYYY-MM-DD')}
                                        </th>
                                        <td className="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap border-b border-gray-200">

                                            <Link to={`/app/services/${item.service_uuid}/versions/${item.uuid}`}>
                                                <Button>Edit</Button>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}

                        </>
                    ) : null}
                    </tbody>
                </table>

            </>,
        },
    ];

    useEffect(() => {
        setBreadcrumb(breadcrumbs);

        const fetchService = async (serviceUuid: string | undefined) => {
            try {
                const response: Service = await ServiceRepository.findByUuid(serviceUuid);
                //form.setFieldsValue(response);
                response['is_private'] = response['is_private'] ? 'true' : 'false'
                setData(response)
                setLoading(false)
            } catch (error) {
                console.error('Error fetching service:', error);
            }
        };

        fetchService(serviceUuid);
    }, []);

    if (loading) {
        return <>
            <div className="container px-6 py-8 mx-auto">
                loading
            </div>
        </>
    }

    return (
        <>
            {contextHolder}
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Services</h3>

                { activeKey === '2' ? (

                    <div className="mt-4">
                        <div className="flex flex-wrap -mx-6">
                            <div className=" w-full px-6 sm:w-1/2 xl:w-1/3">
                                <Link to={`/app/services/${serviceUuid}/versions`}>
                                    <div
                                        className="border-gray-200 border-dotted border flex items-center px-5 py-6 bg-white rounded-md shadow-sm">
                                        <div className="p-3 bg-orange-400 bg-opacity-75 rounded-full">
                                            <DocumentPlusIcon className="w-8 h-8 text-white"/>
                                        </div>

                                        <div className="mx-5">
                                            <h4 className="text-2xl font-semibold text-gray-700">New version</h4>
                                            <div className="text-gray-500">Create a new version for an existent service</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                        </div>
                    </div>) : null
                }

                <div className="flex bg-white flex-col mt-8">
                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div
                            className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <Tabs
                                activeKey={activeKey}
                                onChange={handleTabChange}
                                defaultActiveKey="1"
                                items={items}
                            />


                        </div>
                    </div>
                </div>

                { activeKey === '1' ? (
                <div className="flex bg-white flex-col mt-8">

                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">


                        <div
                            className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <h3 className="text-xl font-medium text-gray-700 mb-4">Delete</h3>

                            <p className="text-sm text-gray-700 mb-4">
                                Deleting this item will remove all associated data and cannot be reversed.
                                Please ensure you have backed up any important information before proceeding.
                            </p>

                            <Popconfirm
                                title="Delete"
                                description="Are you sure to delete this item?"
                                onConfirm={() => confirm(serviceUuid)}
                                okText="Yes"
                                cancelText="No"
                                className={''}
                            >
                                <Button danger>Yes, I'll permanently delete it</Button>
                            </Popconfirm>

                        </div>
                    </div>
                </div>) : null}

            </div>

        </>
    );
};

export default EditService;
