import {Parser} from "../Shared/useStreamContent.ts";

export default class LogParser implements Parser {
    private services: Map<string, string> = new Map();

    parse(input: string): string {
        const regex = /^([\w-]+)(\s*\|\s*)(.+)$/gm;

        if (!input.match(regex)) {
            return `<div>${input.trim()}</div>`
        }

        return input.replace(regex, (_, service, separator, logMessage) => {
            if (!this.services.has(service)) {
                this.services.set(service, this.getRandomColor());
            }

            const color = this.services.get(service);

            logMessage = logMessage.replace(/\n/g, ' ')


            return `<div style="background-color: ${color}; color: white; "><span style="font-weight: bold">${service}</span>${separator}${logMessage}</span></div>`;
        });
    }


    private getRandomColor(): string {
        // Generate random H (hue) and S (saturation) values
        const hue = Math.floor(Math.random() * 360); // Full color spectrum
        const saturation = Math.floor(Math.random() * 50) + 50; // 50% to 100% saturation
        const lightness = Math.floor(Math.random() * 30) + 20; // 20% to 50% lightness (prevents very light colors)

        return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    }

}
