import axiosInstance from "../../../axios.ts";
import {PaginatedResponse} from "../../Shared/Types.ts";
import {RegisterData, User} from "../Types.ts";

const register = async (data: RegisterData) => {
    try {
        const response = await axiosInstance.post('/register', data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Registration failed');
    }
};

const findAll = async (page: number, hasPagination: boolean): Promise<PaginatedResponse<User>> => {
    try {
        const response = await axiosInstance.get(`/users?page=${page}&has_pagination=${hasPagination}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'List failed');
    }
};

const findAllWithoutPagination = async (): Promise<User[]> => {
    try {
        const response = await axiosInstance.get(`/users?has_pagination=false`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'List failed');
    }
};

const findByUuid = async (userUuid): Promise<User> => {
    try {
        const response = await axiosInstance.get(`/users/${userUuid}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Find item failed');
    }
}

const update = async (user: User): Promise<boolean> => {
    await axiosInstance.patch(`/users/${user.uuid}`, user);
    return true;
};

const destroy = async (uuid: string): Promise<boolean> => {
    await axiosInstance.delete(`/users/${uuid}`);
    return true;
};

export default {
    register,
    findAll,
    findAllWithoutPagination,
    findByUuid,
    update,
    destroy
};