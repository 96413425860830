import {PlusOutlined} from "@ant-design/icons";

export default function NewProjectCard() {
    return (
        <div className="border-dashed border-2 border-gray-300 rounded-xl flex items-center justify-center w-full max-w-xs h-44 text-gray-500 cursor-pointer hover:border-gray-400">
            <div className="flex flex-col items-center">
                <PlusOutlined className="text-2xl mb-2" />
                <span>New project</span>
            </div>
        </div>
    );
}
