import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
// import {GitHubSVG, GoogleSVG} from "../Components/SocialMediaButtons.tsx";
// import DividerHorizontal from "../Components/DividerHorizontal.tsx";
import useUserStore from "../App/Stores/useUserStore.ts";
import authRepository from "./Repositories/AuthRepository.tsx";
import { Turnstile } from '@marsidev/react-turnstile'
import {Alert} from "antd";
import {defaultConfig} from "../configs.ts";
const VALIDATION_WIDGET_PUBLIC_KEY = import.meta.env.VITE_VALIDATION_WIDGET_PUBLIC_KEY;
const APP_ENV = import.meta.env.VITE_APP_ENV

const SignUp: React.FC = () => {
    const [token, setToken] = useState("");
    const [plan, setPlan] = useState('free');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const setUser = useUserStore(state => state.setUser);
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>('');
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get('plan') != undefined && searchParams.get('plan') != '') {
            setPlan(searchParams.get('plan'))
        }
    }, []);
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (APP_ENV !== defaultConfig.ENVIRONMENT_LOCAL) {
            if (!token) {
                setError('Please complete the Turnstile verification'); // Set the error message to display the alert
                return;
            }
        }

        try {
            const userData = await authRepository.register({
                plan_identifier: plan,
                email,
                username,
                password,
                first_name: firstName,
                last_name: lastName,
                validation_token: token,
            });

            setUser(userData);

            navigate('/app/dashboard');
        } catch (error) {
            setError(error.message ?? 'Invalid data'); // Set the error message to display the alert
        }
    };

    return (
        <>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <Link to="/">
                    <img
                        alt="Kampa"
                        src='/images/k.svg'
                        className="mx-auto h-28 w-auto"
                    />
                </Link>
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Create a new account
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                {error && (
                    <Alert message={error} type="error" showIcon closable onClose={() => setError(null)} className="mb-8" />
                )}

                <form onSubmit={handleSubmit} className="space-y-6">

                    <div>
                        <label htmlFor="plan" className="block text-sm font-medium leading-6 text-gray-900">
                            Plan
                        </label>
                        <div className="mt-2">
                            <select
                                id="plan"
                                name="plan"
                                required
                                value={plan}
                                onChange={(e) => setPlan(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                            >
                                <option value='free'>Free for individuals</option>
                                <option value='small'>Small startup (Up to 15 users) - €199 Yearly</option>
                                <option value='medium'>Medium (Up to 50 users) - €499 Yearly</option>
                            </select>
                        </div>
                    </div>

                    <div className="flex">
                        <div className="flex-1 mr-1">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                First name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    required
                                    autoComplete="firstName"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="flex-1 ml-1">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Last name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    required
                                    autoComplete="lastName"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Email address
                        </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                required
                                autoComplete="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="username"
                                   className="block text-sm font-medium leading-6 text-gray-900">
                                Username
                            </label>
                        </div>
                        <div className="mt-2">
                            <input
                                id="username"
                                name="username"
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                                className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>


                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                Password
                            </label>
                        </div>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                required
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    { APP_ENV != 'local' ? (
                        <div className="mt-4">
                        <Turnstile
                            className={'w-full rounded-md border-0'}
                            onSuccess={(token) => setToken(token)}
                         siteKey={VALIDATION_WIDGET_PUBLIC_KEY}/>
                    </div>
                    ) : null}


                    <div>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                        >
                            Sign up
                        </button>
                    </div>
                </form>


                {/*<DividerHorizontal text={'or continue with'}/>*/}

                {/*<div className="flex items-center justify-between">*/}
                {/*    <a*/}
                {/*        href="#"*/}
                {/*        className="inline-flex items-center rounded-md border border-gray-300 bg-white px-12 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"*/}
                {/*    >*/}
                {/*        <GoogleSVG/>*/}
                {/*        Google*/}
                {/*    </a>*/}
                {/*    <a*/}
                {/*        href="#"*/}
                {/*        className="inline-flex items-center rounded-md border border-gray-300 bg-white px-12 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"*/}
                {/*    >*/}
                {/*        <GitHubSVG/>*/}
                {/*        GitHub*/}
                {/*    </a>*/}
                {/*</div>*/}

                <p className="mt-10 text-center text-sm text-gray-500">
                    Already a member?{' '}
                    <Link to="/login" className="font-semibold leading-6 text-orange-600 hover:text-orange-500">
                    Click here to login
                    </Link>
                </p>
            </div>
        </div>

        </>
    );
};

export default SignUp;
