import React from "react";
import { Link } from "react-router-dom";
import CodeComponent from "./CodeComponent.tsx";

const DocumentQuickStartGuide: React.FC = () => {
    return (
        <div className="container mx-auto p-6 text-gray-900 font-sans">
            <section className="mb-2">
                <h1 className="gradient-text text-4xl font-bold mb-6">Quick Start Guide</h1>
            </section>

            <h2 className="text-2xl font-semibold mb-4">Create Your Account</h2>
            <p className="text-gray-600">
                Start by creating an account. We offer three plans: <strong>Free</strong>, <strong>Small</strong>,
                and <strong>Medium</strong>.
                Choose the plan that best suits your development needs.
            </p>
            <Link to="/sign-up" className="text-blue-500">Sign up here</Link>

            <h2 className="mt-8 text-2xl font-semibold mb-4">Creating a New Environment</h2>

            <p className="text-gray-600">
                To create a new environment, go to the <strong>Environments</strong> page and
                click <strong>Edit</strong> or
                <strong> Add Environment</strong>. Fill in the required details, such
                as <strong>Name</strong>, <strong>Description</strong>,
                and <strong>Identifier</strong>. You can also enable the <strong>Reverse Proxy</strong> if needed.
            </p>

            <p className="text-gray-600">
                Next, add services to your environment by clicking <strong>Add Service</strong>. Choose the
                <strong>Type</strong> (e.g., Developer Tools, Database, or Application), select
                a <strong>Service</strong>,
                and specify its <strong>Version</strong>. Configure the necessary settings, including
                { ' ' } <strong>Host & Port Mappings</strong>, { ' ' }
                <strong>Environment Variables</strong>, and <strong>Volumes</strong>.
            </p>

            <p className="text-gray-600 mb-4">
                Once everything is set up, click <strong>Save</strong> to create your environment.
            </p>


            <h2 className="mt-8 text-2xl font-semibold mb-4">Install the Kampa CLI</h2>

            <p className="text-gray-600">
                Get started by installing the Kampa CLI using the link below:{' '}
                <a href="/docs/installation" className="text-blue-500 hover:underline" target="_blank"
                   rel="noopener noreferrer">
                    Install Kampa CLI
                </a>.
            </p>

            <p className="text-gray-600 mb-4">
                Before using the CLI, generate an authentication token from the <strong>Auth Keys</strong> page in your
                dashboard.
                Once you have your token, log in with the following command:
            </p>

            <CodeComponent content={`
\`\`\`shell
kampa auth login <your_key>
\`\`\`
`}/>


            <h2 className="mt-8 text-2xl font-semibold mb-4">Initialize Your Environment</h2>
            <p className="text-gray-600">
                Use the following command to initialize your environment:
            </p>
            <CodeComponent content={`
\`\`\`shell
kampa env init <environment_identifier>
\`\`\`
`}/>
            <p className="text-gray-600">
                This will configure the environment based on the provided identifier.
            </p>

            <h2 className="mt-8 text-2xl font-semibold mb-4">Installing Dependencies on Services</h2>
            <p className="text-gray-600">
                To install dependencies inside a service container, use:
            </p>
            <CodeComponent content={`
\`\`\`shell
kampa container exec <service> <installation_command>
\`\`\`
`}/>


            <h2 className="mt-8 text-2xl font-semibold mb-4">Updating the Hosts File</h2>
            <p className="text-gray-600">
                If you are using the reverse proxy feature, make sure to update your <code>/etc/hosts</code> file
                with the appropriate mappings for your environment.
            </p>

            <h2 className="mt-8 text-2xl font-semibold mb-4">Starting Your Environment</h2>
            <p className="text-gray-600">
                Start your environment using one of the following commands:
            </p>
            <CodeComponent content={`
\`\`\`shell
docker compose up
\`\`\`
`}/>
            <p className="text-gray-600">or</p>

            <CodeComponent content={`
\`\`\`shell
kampa env start
\`\`\`
`}/>
        </div>
    );
};

export default DocumentQuickStartGuide;
