import { CheckIcon } from '@heroicons/react/20/solid'
import {Link} from "react-router-dom";

const sharedFeatures = [
    'Access to a library of service templates',
    'Share local environment logs',
    'Create and share reusable commands',
];

const tiers = [
    {
        name: 'Growth',
        id: 'tier-growth',
        href: '/sign-up?plan=small',
        pricePerYear: '€199',
        description: "An ideal plan to kickstart your journey with our product, offering core features to support growing teams.",
        features: [
            'Up to 15 users',
            'Up to 5 environments',
            ...sharedFeatures,
            'Ticket-based support',
        ],
        uniqueFeatures: [],
        featured: false,
    },
    {
        name: 'Start up',
        id: 'tier-startup',
        href: '/sign-up?plan=medium',
        pricePerYear: '€499',
        description: 'Comprehensive support and robust infrastructure designed for scaling businesses and microservices.',
        features: [
            'Up to 50 users',
            'Up to 50 environments',
            ...sharedFeatures,
            'Priority support',
            // 'Custom reports with detailed logs',
            // 'Slack integration for notifications',
            'Access to early features',
        ],
        uniqueFeatures: ['Custom reports with detailed logs', 'Slack integration for notifications'],
        featured: true,
    },
];


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Pricing() {
    return (
        <>
            <div id='pricing' className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
                <div aria-hidden="true"
                     className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl">
                    <div
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                        className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
                    />
                </div>
                <div className="mx-auto max-w-4xl text-center">
                    <h2 className="gradient-text text-blue-600 text-xl font-semibold mb-4">Pricing</h2>
                    <p className="text-4xl font-bold text-gray-900 mb-6">
                        Choose the right plan
                    </p>
                </div>
                <p className="mx-auto mt-6 max-w-2xl text-pretty text-center text-lg font-medium text-gray-600 sm:text-xl/8">
                    Our plans are designed for long-term success, with ongoing support, updates, and exclusive features.
                </p>

                <div
                    className="mx-auto mt-6 max-w-2xl text-pretty text-center flex flex-col sm:flex-row justify-center items-center gap-6">
                    <div className="flex items-center text-blue-600">
                        <svg stroke="currentColor" fill="none" viewBox="0 0 24 24" className="w-5 h-5 mr-1">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M5 13l4 4L19 7"></path>
                        </svg>
                        Enjoy a 7-day free trial
                    </div>
                    <div className="flex items-center text-blue-600">
                        <svg stroke="currentColor" fill="none" viewBox="0 0 24 24" className="w-5 h-5 mr-1">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M5 13l4 4L19 7"></path>
                        </svg>
                        No credit card required
                    </div>
                </div>


                <div
                    className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
                    {tiers.map((tier, tierIdx) => (
                        <div
                            key={tier.id}
                            className={classNames(
                                tier.featured ? 'relative bg-gray-900 shadow-2xl' : 'bg-white/60 sm:mx-8 lg:mx-0',
                                tier.featured
                                    ? ''
                                    : tierIdx === 0
                                        ? 'rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none'
                                        : 'sm:rounded-t-none lg:rounded-bl-none lg:rounded-tr-3xl',
                                'rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10',
                            )}
                        >
                            <h3
                                id={tier.id}
                                className={classNames(tier.featured ? 'text-orange-400' : 'text-orange-600', 'text-base/7 font-semibold')}
                            >
                                {tier.name}
                            </h3>
                            <p className="mt-4 flex items-baseline gap-x-2">
                              <span
                                  className={classNames(
                                      tier.featured ? 'text-white' : 'text-gray-900',
                                      'text-5xl font-semibold tracking-tight',
                                  )}
                              >
                                {tier.pricePerYear}
                              </span>
                                <span
                                    className={classNames(tier.featured ? 'text-gray-400' : 'text-gray-500', 'text-base')}>/yearly</span>
                            </p>

                            <p className={classNames(
                                tier.featured ? 'text-gray-300' : 'text-gray-600',
                                'mt-4 text-sm italic'
                            )}>
                                🚀 Early Access Price - Limited time offer
                            </p>

                            <p className={classNames(tier.featured ? 'text-gray-300' : 'text-gray-600', 'mt-6 text-base/7')}>
                                {tier.description}
                            </p>
                            <ul
                                role="list"
                                className={classNames(
                                    tier.featured ? 'text-gray-300' : 'text-gray-600',
                                    'mt-8 space-y-3 text-sm/6 sm:mt-10',
                                )}
                            >
                                {tier.features.map((feature) => (
                                    <li key={feature} className="flex gap-x-3">
                                        <CheckIcon
                                            aria-hidden="true"
                                            className={classNames(tier.featured ? 'text-indigo-400' : 'text-indigo-600', 'h-6 w-5 flex-none')}
                                        />
                                        {feature}
                                    </li>
                                ))}

                            </ul>
                            <Link
                                to={tier.href}
                                aria-describedby={tier.id}
                                className={classNames(
                                    tier.featured
                                        ? 'bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500'
                                        : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline-indigo-600',
                                    'mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10',
                                )}
                            >
                                Get started today
                            </Link>
                        </div>
                    ))}
                </div>

                <div className="container mx-auto py-12 px-6 text-center flex flex-col gap-y-6 sm:gap-y-8 lg:gap-y-4">
                    <p className="text-gray-500 text-base sm:text-lg">
                        For solo developers, we offer a tailored <Link to={'/sign-up?plan=free'}
                                                                       className="text-indigo-600 font-semibold">FREE</Link> plan
                        with essential features to get you started.
                    </p>

                    <p className="text-gray-500 text-base sm:text-lg">
                        For teams with over 50 users, <Link to={'/contact'} className="text-indigo-600 font-semibold">reach
                        out</Link> to discuss a custom plan designed to meet your unique requirements.
                    </p>
                </div>


            </div>

        </>
    )
}