import axiosInstance from "../../../axios.ts";
import {Profile, ProfilePassword} from "../Types.ts";
import {AxiosResponse} from "axios";

const fetchData = async () => {
    try {
        const response: AxiosResponse<Profile> = await axiosInstance.get(`/profile`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Fetch failed');
    }
}

const update = async (profile: Profile): Promise<boolean> => {
    await axiosInstance.patch(`/profile`, profile);
    return true;
};

const updatePassword = async (profile: ProfilePassword): Promise<boolean> => {
    await axiosInstance.patch(`/profile-password`, profile);
    return true;
};

export default {
    fetchData,
    update,
    updatePassword,
}