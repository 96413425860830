import React from 'react';

const DocumentationBasicConcepts: React.FC = () => {
    return (
        <div className="container mx-auto p-6  text-gray-900 font-sans">
            <section className="mb-12">
                <h1 className="gradient-text text-4xl font-bold mb-6">Basic Concepts</h1>

                <div className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Overview</h2>
                    <p className="text-lg leading-relaxed">
                        Kampa is a tool designed to help developers create, share, and manage local development environments effortlessly. It aims to reduce the complexity of setting up development environments, making it easier for teams to collaborate and maintain consistency.
                    </p>
                </div>

                <div className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Environments</h2>
                    <p className="text-lg leading-relaxed">
                        An environment in Kampa is a complete, isolated setup that includes all necessary dependencies, tools, and configurations for a specific project. This ensures that your code runs consistently across different machines, eliminating the "it works on my machine" problem.
                    </p>
                </div>

                <div className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Templates</h2>
                    <p className="text-lg leading-relaxed">
                        Templates in Kampa are predefined configurations for common development setups (e.g., Node.js, Python, Ruby on Rails). These templates can be customized to fit the specific needs of your project, saving time and ensuring best practices are followed.
                    </p>
                </div>

                <div className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Services / Containers</h2>
                    <p className="text-lg leading-relaxed">
                        Kampa uses Docker for containerization technology, to create isolated environments. Containers make your environments portable and reproducible, allowing you to easily share them with your team or deploy them across different machines.
                    </p>
                </div>

                <div className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Commands</h2>
                    <p className="text-lg leading-relaxed">
                        Kampa provides a set of commands to manage your environments efficiently. Key commands include:
                    </p>
                    <ul className="list-inside list-none">
                        <li className="text-lg leading-relaxed">
                            <code className="bg-gray-200 rounded px-2 py-1">kampa env init</code>: Set up a new
                            environment.
                        </li>
                        <li className="text-lg leading-relaxed">
                            <code className="bg-gray-200 rounded px-2 py-1">kampa env start</code>: Start your
                            environment.
                        </li>
                        <li className="text-lg leading-relaxed">
                            <code className="bg-gray-200 rounded px-2 py-1">kampa env update</code>: Update your
                            environment with new changes.
                        </li>
                    </ul>
                </div>

                <div className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Configuration</h2>
                    <p className="text-lg leading-relaxed">
                    Kampa environments are defined using configuration files. These files, written in YAML or JSON, specify the dependencies, tools, and settings required for your project. This allows for easy setup and modification of environments.
                    </p>
                </div>

                <div className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Sharing</h2>
                    <p className="text-lg leading-relaxed">
                        Sharing environments with Kampa is straightforward. By packaging the environment configurations and dependencies, you can easily distribute your setup to team members, ensuring everyone works in a consistent environment.
                    </p>
                </div>

                <div className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Versioning</h2>
                    <p className="text-lg leading-relaxed">
                        Kampa supports versioning of environments, allowing you to track changes and revert to previous configurations if needed. This helps in managing updates and maintaining consistency across different stages of your project.
                    </p>
                </div>

                <div>
                    <h2 className="text-2xl font-semibold mb-4">Plugins</h2>
                    <p className="text-lg leading-relaxed">
                        Extend Kampa’s functionality with plugins. Plugins add extra capabilities, such as integrations with other tools or support for additional languages and frameworks. The growing ecosystem of plugins enhances Kampa’s versatility.
                    </p>
                </div>

            </section>
        </div>
    );
};

export default DocumentationBasicConcepts;
