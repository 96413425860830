import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

const ScrollToTop = () => {
    const { pathname, hash } = useLocation();

    useEffect(() => {
        setTimeout(() => {
            if (hash) {
                const element: HTMLElement = document.getElementById(hash.substring(1)); // Remove "#" and find the element

                // console.log(element.id)
                if (element && element.id != 'header') {
                    element.scrollIntoView({ behavior: "smooth", block: "start" });
                    return;
                }
            }

            window.scrollTo({ top: 0, behavior: "smooth" });
        }, 100);
    }, [pathname, hash]);

    return <Outlet />;
};

export default ScrollToTop;
