import React, {useState} from 'react';
import {Link, Outlet} from "react-router-dom";
import Notification from "./Notifications/Notification.tsx";
import {

    CommandLineIcon,
    HomeIcon, KeyIcon, LifebuoyIcon,
    ServerStackIcon,
    UserGroupIcon, UserIcon, RocketLaunchIcon
} from "@heroicons/react/24/outline";
import Breadcrumbs from "./Breadcrumb.tsx";
import useUserStore from "./Stores/useUserStore.ts";
import Onboard from "./Onboard/Onboard.tsx";
import {hasFeature} from "./Organizations/FeaturesHelper.tsx";

const iconSize:number = 22;

const AppLayout: React.FC = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const bgSidebar = 'bg-slate-500';
    const borderColor = 'border-gray-50'; // `border-orange-100`
    const bgContentColor = 'bg-gray-100';
    const firstName = useUserStore((state) => state.firstName);
    const role = useUserStore((state) => state.role);
    const plan = useUserStore((state) => state.organization.plan_name)
    const features = useUserStore((state) => state.organization.features)

    return (
        <>
            <Onboard />
            <div className={`flex h-screen ${bgContentColor}`}>
                {sidebarOpen && (
                    <div
                        onClick={() => setSidebarOpen(false)}
                        className="fixed inset-0 z-20 transition-opacity bg-black opacity-50 lg:hidden"
                    ></div>
                )}

                <div
                    className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 transform ${bgSidebar} lg:translate-x-0 lg:static lg:inset-0 ${
                        sidebarOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'
                    }`}
                >
                    <div className="flex  ml-5 mt-8">
                        <div className="flex">
                            <div className={'p-2'}>
                                <img src='/images/k-white.svg' width='24' alt="Environment with easy"/>
                            </div>
                            {/*<span className="mx-2 text-2xl font-semibold text-white">Kampa</span>*/}
                        </div>
                    </div>

                    <nav className="flex flex-col h-5/6">
                        <div className="flex-grow ">
                            <Link to={'/app/dashboard'} className="flex items-center px-6 py-2 mt-4 text-gray-100">
                                <HomeIcon height={iconSize}/>
                                <span className="mx-3">Dashboard</span>
                            </Link>

                            <Link to={'/app/commands'} className="flex items-center px-6 py-2 mt-4 text-gray-100">
                                <CommandLineIcon height={iconSize} />
                                <span className="mx-3">Commands</span>
                            </Link>

                            <Link to={'/app/local-environments'} className="flex items-center px-6 py-2 mt-4 text-gray-100">
                                <ServerStackIcon height={iconSize}/>
                                <span className="mx-3">Local Environments</span>
                            </Link>

                            {plan?.toLowerCase() !== 'free' ? (
                            <>
                                { hasFeature(features, "has_experimental_features") ?  (
                                    <Link to={'/app/deployments'} className="flex items-center px-6 py-2 mt-4 text-gray-100">
                                        <RocketLaunchIcon height={iconSize}/>
                                        <span className="mx-3">Deployments</span>
                                    </Link>
                                ) : null }
                                <Link to={'/app/teams'} className="flex items-center px-6 py-2 mt-4 text-gray-100">
                                    <UserGroupIcon height={iconSize}/>
                                    <span className="mx-3">Teams</span>
                                </Link>

                                <Link to={'/app/users'} className="flex items-center px-6 py-2 mt-4 text-gray-100">
                                    <UserIcon height={iconSize}/>
                                    <span className="mx-3">Users</span>
                                </Link>
                            </>) : null}

                        </div>

                        <div>
                            <div className="relative flex items-center">
                                <div className="flex-grow border-t border-gray-400 opacity-25"></div>
                            </div>

                            <Link to={'/app/client'} className="flex items-center px-6 py-2 mt-4 text-gray-100">
                                <CommandLineIcon height={iconSize}/>
                                <span className="mx-3">Kampa Client</span>
                            </Link>

                            { role === 'manager' ? (
                                <Link to={'/app/keys'} className="flex items-center px-6 py-2 mt-4 text-gray-100">
                                    <KeyIcon height={iconSize}/>
                                    <span className="mx-3">Auth Keys</span>
                                </Link> ) : null }

                            { role === 'super_admin' ? (<>
                                <Link to={'/app/documentations'} className="flex items-center px-6 py-2 mt-4 text-gray-100">
                                    <KeyIcon height={iconSize}/>
                                    <span className="mx-3">Doc Manager</span>
                                </Link>

                                <Link to={'/app/templates'} className="flex items-center px-6 py-2 mt-4 text-gray-100">
                                    <KeyIcon height={iconSize}/>
                                    <span className="mx-3">Template Manager</span>
                                </Link>
                            </>) : null }

                            <Link to="/docs/introduction" className="flex items-center px-6 py-2 mt-4 text-gray-100">
                                <LifebuoyIcon height={iconSize}/>
                                <span className="mx-3">Documentation</span>
                            </Link>
                        </div>
                    </nav>
                </div>

                <div className="flex flex-col flex-1 overflow-hidden">
                    <section className={`flex items-center justify-between px-6 py-4 bg-white border-b-1 ${borderColor}`}>
                        <div className="flex items-center">
                            <button onClick={() => setSidebarOpen(true)}
                                    className="text-gray-500 focus:outline-none lg:hidden">
                                <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4 6H20M4 12H20M4 18H11"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></path>
                                </svg>
                            </button>

                            <Breadcrumbs />

                        </div>

                        <div className="flex items-center">

                            <div className="relative">
                                <button
                                    onClick={() => setNotificationOpen(!notificationOpen)}
                                    className="flex mx-4 text-gray-600 focus:outline-none"
                                >
                                    <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M15 17H20L18.5951 15.5951C18.2141 15.2141 18 14.6973 18 14.1585V11C18 8.38757 16.3304 6.16509 14 5.34142V5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5V5.34142C7.66962 6.16509 6 8.38757 6 11V14.1585C6 14.6973 5.78595 15.2141 5.40493 15.5951L4 17H9M15 17V18C15 19.6569 13.6569 21 12 21C10.3431 21 9 19.6569 9 18V17M15 17H9"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                    </svg>
                                </button>

                                {notificationOpen && (
                                    <Notification/>
                                )}
                            </div>

                            <div className="relative">
                                <button
                                    onClick={() => setDropdownOpen(!dropdownOpen)}
                                    // className="relative block w-8 h-8 overflow-hidden rounded-full shadow focus:outline-none"
                                    className="relative block h-8 overflow-hidden focus:outline-none"
                                >
                                    { firstName }
                                    {/*<img*/}
                                    {/*    className="object-cover w-full h-full"*/}
                                    {/*    src="https://images.unsplash.com/photo-1528892952291-009c663ce843?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=296&amp;q=80"*/}
                                    {/*    alt="Your avatar"*/}
                                    {/*/>*/}

                                </button>

                                {dropdownOpen && (
                                    <div>
                                        <div
                                            onClick={() => setDropdownOpen(false)}
                                            className="fixed inset-0 z-10 w-full h-full"
                                        ></div>

                                        <div
                                            className="absolute right-0 z-10 w-48 mt-2 overflow-hidden bg-white rounded-md shadow-xl"
                                        >
                                            <Link to="/app/profile"
                                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white">
                                                Profile
                                            </Link>

                                            { role === 'manager' ? (<Link to="/app/billing"
                                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white">
                                                Billing
                                            </Link>) : null }


                                            <Link to='/logout'
                                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white">
                                                Logout
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>

                    <main className={`flex-1 overflow-x-hidden overflow-y-auto ${bgContentColor}`}>
                        <Outlet/>
                    </main>

                </div>
            </div>
        </>
    );
};

export default AppLayout;
