import axios, {
    AxiosInstance,
    AxiosResponse,
    InternalAxiosRequestConfig,
    RawAxiosRequestHeaders,
} from "axios";
import useUserStore from "./App/Stores/useUserStore.ts";

const API_URL = import.meta.env.VITE_API_URL;

const headers: RawAxiosRequestHeaders = {
    "Content-Type": "application/json",
};

const axiosInstance: AxiosInstance = axios.create({
    baseURL: API_URL + "api/",
    headers: headers,
});

axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        const { token } = useUserStore.getState();

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            console.log(error);
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
