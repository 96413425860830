import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from "react-router-dom";
import DocumentationRepository from "./Repositories/DocumentationRepository.ts";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import { Documentation } from "../../App/DocumentationManager/Types.ts";

const DocumentationFromBackend: React.FC = () => {
    const { path } = useParams();
    const [documentation, setDocumentation] = useState<Documentation>();
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDocumentation = async (path: string | undefined) => {
            try {
                const response: Documentation = await DocumentationRepository.findByPath(path);
                setDocumentation(response);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching documentations:', error);
                setLoading(false);
                navigate('/docs/introduction')
            }
        };

        fetchDocumentation(path);
    }, [path]);

    if (loading) {
        return <>Loading...</>;
    }

    return (
        <div className="container mx-auto p-6 text-gray-900 font-sans">
            <section className="mb-12">
                {/* Main Title */}
                <h1 className="gradient-text text-4xl font-bold mb-6">{documentation?.name}</h1>

                {/* Render Markdown without editor */}
                <div className="prose max-w-none">
                    <MDEditor.Markdown
                        source={documentation?.content}
                        rehypePlugins={[[rehypeSanitize]]}
                    />
                </div>
            </section>
        </div>
    );
};

export default DocumentationFromBackend;
