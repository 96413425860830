const KeyTypeEnum = {
    LOCAL: 'local',
    STAGE: 'stage',
    PRODUCTION: 'production',
};

const KeyAuthTypeEnum = {
    BASIC: 'basic',
    SSH: 'ssh',
};

const KeyOwnerTypeEnum = {
    USER: 'user',
    TEAM: 'team',
};

export type Key = {
    uuid: string;
    organization_uuid: string;
    status: string;
    type: string;
    auth_type: string;
    keyable_type: string;
    keyable_uuid: string;
    owner_type: string | null;
    owner_name: string | null;
    key: string;
    updated_at: string;
    deleted_at: string | null;
};



export const KeyAuthTypeOptions = [
    { label: 'basic', value: KeyAuthTypeEnum.BASIC, disabled: false },
    { label: 'ssh', value: KeyAuthTypeEnum.SSH, disabled: true },
];

export const KeyTypeOptions = [
    { label: 'Local', value: KeyTypeEnum.LOCAL, disabled: false },
    { label: 'Stage', value: KeyTypeEnum.STAGE, disabled: true },
    { label: 'Production', value: KeyTypeEnum.PRODUCTION, disabled: true },
];

export const KeyOwnerTypeOptions = [
    { label: 'Local', value: KeyOwnerTypeEnum.USER, disabled: false },
    { label: 'Team', value: KeyOwnerTypeEnum.TEAM, disabled: true },
];