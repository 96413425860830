import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from "../Components/Header.tsx";
import Footer from "../Components/Footer.tsx";

const SitePoliciesLayout: React.FC = () => {
    return (
        <>
            <div className="min-h-screen">
                <div className={'bg-gray-100 '}>
                    <Header />

                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-12">
                        <div className="rounded-lg  p-6">

                            <Outlet/>

                        </div>
                    </div>
                </div>

                <Footer/>
            </div>
        </>
    );
};

export default SitePoliciesLayout;
