import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

export default function SiteFaq() {
    const faqItems = [
        {
            key: '1',
            question: 'What is Kampa?',
            answer: 'Kampa is a tool designed to simplify the creation, configuration, and management of local development environments for teams and developers.',
        },
        {
            key: '2',
            question: 'How does Kampa work?',
            answer: 'Kampa uses a CLI to set up local environments automatically, minimizing conflicts and saving time by automating dependencies, repositories, and services.',
        },
        {
            key: '3',
            question: 'What technologies does Kampa support?',
            answer: 'Kampa supports GoLang, Python with Django or Flask, PHP with Laravel or Symfony, Node.js with React, Angular, or Vue, and more.',
        },
        {
            key: '4',
            question: 'Is Kampa free?',
            answer: 'Kampa offers a free plan for solo developers. For teams, we have paid plans starting at €199 per year.',
        },
        {
            key: '5',
            question: 'Does Kampa work with Docker?',
            answer: 'Yes, Kampa fully supports Docker and Podman for environment setups.',
        },
        {
            key: '6',
            question: 'Can I share environments with my team?',
            answer: 'Absolutely. Kampa makes it easy to share environments with team members for collaboration.',
        },
        {
            key: '7',
            question: 'What is included in the free plan?',
            answer: 'The free plan includes core features like environment creation and basic CLI commands for individual developers.',
        },
        {
            key: '8',
            question: 'What support options are available?',
            answer: 'Paid plans include ticket-based or priority support, depending on the plan, and access to detailed custom reports.',
        },
        // {
        //     key: '10',
        //     question: 'Can I integrate Kampa with Slack?',
        //     answer: 'Yes, Slack integration is available in the Start-up plan and above for real-time notifications.',
        // },
        {
            key: '9',
            question: 'Does Kampa support custom environments?',
            answer: 'Yes, Kampa allows you to configure custom environments tailored to your project needs.',
        },
    ];

    return (
        <section id="faq" role="main" className="py-10 bg-gray-50">
            <div className="container mx-auto px-4">
                <div className="max-w-2xl mx-auto text-center">
                    <h2 className="gradient-text text-blue-600 text-xl font-semibold mb-4">FAQ</h2>
                    <p className="text-4xl font-bold text-gray-900 mb-6">
                        Find answers to common questions about Kampa below.
                    </p>
                </div>

                <div className="mt-8 max-w-4xl mx-auto space-y-4">
                    {faqItems.map((item) => (
                        <Disclosure key={item.key}>
                            {({ open }) => (
                                <div className="bg-white rounded-lg shadow-md">
                                    <Disclosure.Button className="flex justify-between w-full px-6 py-4 text-left text-lg font-medium text-gray-900 hover:bg-gray-100 focus:outline-none">
                                        <span>{item.question}</span>
                                        <ChevronUpIcon
                                            className={`${
                                                open ? 'transform rotate-180' : ''
                                            } w-5 h-5 text-gray-500`}
                                        />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-6 py-4 text-gray-700">
                                        {item.answer}
                                    </Disclosure.Panel>
                                </div>
                            )}
                        </Disclosure>
                    ))}
                </div>


                <div className="hidden sm:mb-6 sm:flex sm:justify-center mt-8">
                    <div
                        className="relative rounded-full px-3 py-1 bg-white text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">

                        Still have questions? {' '}

                        <Link to="/contact" className="text-blue-600 font-medium hover:underline">Contact us
                             <span aria-hidden="true">&rarr;</span></Link>
                    </div>
                </div>

            </div>
        </section>
    );
}
