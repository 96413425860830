import React, { useEffect, useState } from 'react';
import NotificationRepository from "./Repositories/NotificationRepository.tsx";
import { AuditLogEntry } from "./Types.ts";
import GetNotificationIcon from "./NotificationHelper.tsx";
import {Link} from "react-router-dom";

const Notification: React.FC = () => {
    const [notifications, setNotifications] = useState<AuditLogEntry[] | null>(null);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await NotificationRepository.findAllWithoutPagination();
                setNotifications(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchItems();
    }, []);

    return (
        <div className="absolute right-0 z-10 mt-2 overflow-hidden bg-white rounded-lg shadow-xl w-80">
            {notifications && notifications.length > 0 ? (
                notifications.map((notification) => (
                    <a
                        key={notification.uuid}
                        href="#"
                        className="flex items-center px-4 py-3 -mx-2 text-gray-600 hover:text-white hover:bg-indigo-600"
                    >
                        {/* Uncomment if you have an avatar field in AuditLogEntry */}
                        {/*<img*/}
                        {/*    className="object-cover w-8 h-8 mx-1 rounded-full"*/}
                        {/*    src={notification.avatar}*/}
                        {/*    alt="avatar"*/}
                        {/*/>*/}

                        { GetNotificationIcon(notification.auditable_type)}
                        <p className="mx-2 text-sm">
                            <span className="font-bold">{notification.username}</span>{' '}
                            {notification.label}{' '}
                            <br />
                            <span className="text-gray-400">{notification.occurred_at}</span>
                        </p>
                    </a>
                ))
            ) : (
                <p className="p-4 text-center text-gray-500">No notifications</p>
            )}

            <p className="text-center p-3 border-t border-gray-200">
                <Link to={ '/app/notifications'}>
                    View all notifications
                </Link>
            </p>
        </div>
    );
};

export default Notification;
