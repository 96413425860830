import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import UserInviteRepository from "./Repositories/UserInviteRepository.ts";
import {Button, Form, Input, message, Popconfirm, Select} from "antd";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import {BreadcrumbLink} from "../Shared/Types.ts";
import {UserInvite} from "./Types.ts";

// const { TextArea } = Input;

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/users',
        title: 'User',
    },
    {
        href: '/app/users/#invites',
        title: 'User Invites',
    },
    {
        href: '#',
        title: 'Edit',
    },
];

const EditUserInvite: React.FC = () => {
    const [form] = Form.useForm();
    const {userInviteUuid} = useParams();
    const navigate = useNavigate();
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [messageApi, contextHolder] = message.useMessage();
    const [userInvite, setUserInvite] = useState<UserInvite>();

    const confirm: (uuid) => void = async (uuid) => {
        try {
            await UserInviteRepository.destroy(uuid);

            messageApi.open({
                type: 'success',
                content: 'UserInvite deleted successfully',
            });

            setTimeout(() => navigate('/app/users'), 2250);
        } catch (error) {
            message.error('Failed to delete user: ' + error.message);
        }
    };

    useEffect(() => {
        setBreadcrumb(breadcrumbs);

        const fetchUserInvite = async (userInviteUuid: string | undefined) => {
            try {
                const response:UserInvite = await UserInviteRepository.findByUuid(userInviteUuid);
                form.setFieldsValue(response);
                setUserInvite(response)
            } catch (error) {
                console.error('Error fetching user invite:', error);
            }
        };

        fetchUserInvite(userInviteUuid);
     }, []);

    return (
        <>
            {contextHolder}
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">User Invites</h3>

                <div className="flex bg-white flex-col mt-8">

                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">

                        <div
                            className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <h3 className="text-xl font-medium text-gray-700 mb-7">Update</h3>

                            <Form
                                layout="vertical"
                                form={form}

                            >
                                <Form.Item
                                    label="Name:"
                                    name="invitee_name"
                                >
                                    <Input
                                        placeholder="Name"
                                        disabled={true}
                                    />
                                </Form.Item>


                                <Form.Item
                                    label="Email:"
                                    name="invitee_email"
                                >
                                    <Input
                                        disabled={true}
                                        placeholder="Email" />
                                </Form.Item>


                                <Form.Item
                                    label="Role:"
                                    name="role"
                                >
                                    <Select
                                        disabled={true}
                                        options={[
                                            { value: 'manager', label: 'Manager'},
                                            { value: 'user', label: 'Common user'},
                                        ]}
                                    />
                                </Form.Item>
                            </Form>

                        </div>
                    </div>
                </div>


                { userInvite?.accepted_at ? null : (
                <div className="flex bg-white flex-col mt-8">

                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">

                        <div
                            className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <h3 className="text-xl font-medium text-gray-700 mb-4">Delete</h3>

                            <p className="text-sm text-gray-700 mb-4">
                                Deleting this item will remove all associated data and cannot be reversed.
                                Please ensure you have backed up any important information before proceeding.
                            </p>

                            <Popconfirm
                                title="Delete"
                                description="Are you sure to delete this item?"
                                onConfirm={() => confirm(userInvite?.uuid)}
                                okText="Yes"
                                cancelText="No"
                                className={''}
                            >
                                <Button danger>Yes, I'll permanently delete it</Button>
                            </Popconfirm>

                        </div>
                    </div>
                </div>
                )}

            </div>

        </>
    );
};

export default EditUserInvite;
