import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import UserRepository from "./Repositories/UserRepository.ts";
import {Button, Form, Input, message, Popconfirm, Select} from "antd";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import {BreadcrumbLink} from "../Shared/Types.ts";
import {User} from "./Types.ts";

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/users',
        title: 'User',
    },
    {
        href: '#',
        title: 'Edit',
    },
];

const EditUser: React.FC = () => {
    const [form] = Form.useForm();
    const {userUuid} = useParams();
    const navigate = useNavigate();
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [messageApi, contextHolder] = message.useMessage();
    const [user, setUser] = useState<User>();

    const onFinish = async (values: User) => {
        try {
            if (userUuid != null) {
                values.uuid = userUuid;
            }

            await UserRepository.update(values);

            messageApi.open({
                type: 'success',
                content: 'User updated successfully',
            });

            setTimeout(() => navigate('/app/users'), 2250);
        } catch (error) {
            message.error('Failed to update user: ' + error.message);
        }
    };

    const confirm: (uuid) => void = async (uuid) => {
        try {
            await UserRepository.destroy(uuid);

            messageApi.open({
                type: 'success',
                content: 'User deleted successfully',
            });

            setTimeout(() => navigate('/app/users'), 2250);
        } catch (error) {
            message.error('Failed to delete user: ' + error.message);
        }
    };

    useEffect(() => {
        setBreadcrumb(breadcrumbs);

        const fetchUser = async (userUuid: string | undefined) => {
            try {
                const response:User = await UserRepository.findByUuid(userUuid);
                form.setFieldsValue(response);
                setUser(response)
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };

        fetchUser(userUuid);
     }, []);

    return (
        <>
            {contextHolder}
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Users</h3>

                <div className="flex bg-white flex-col mt-8">

                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">

                        <div
                            className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <h3 className="text-xl font-medium text-gray-700 mb-7">Update</h3>

                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label="First Name:"
                                    name="first_name"
                                >
                                    <Input
                                        placeholder="Name"
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Last Name:"
                                    name="last_name"
                                >
                                    <Input
                                        placeholder="Last Name"
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Role:"
                                    name="role"
                                >
                                    <Select

                                        options={[
                                            { value: 'manager', label: 'Manager'},
                                            { value: 'user', label: 'Common user'},
                                        ]}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Email:"
                                    name="email"
                                >
                                    <Input
                                        disabled={true}
                                        placeholder="Email" />
                                </Form.Item>


                                <Form.Item
                                    label="Username:"
                                    name="username"
                                >
                                    <Input
                                        disabled={true}
                                        placeholder="Username"
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">Update</Button>
                                </Form.Item>
                            </Form>

                        </div>
                    </div>
                </div>


                <div className="flex bg-white flex-col mt-8">

                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">

                        <div
                            className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <h3 className="text-xl font-medium text-gray-700 mb-4">Delete</h3>

                            <p className="text-sm text-gray-700 mb-4">
                                Deleting this item will remove all associated data and cannot be reversed.
                                Please ensure you have backed up any important information before proceeding.
                            </p>

                            <Popconfirm
                                title="Delete"
                                description="Are you sure to delete this item?"
                                onConfirm={() => confirm(user?.uuid)}
                                okText="Yes"
                                cancelText="No"
                                className={''}
                            >
                                <Button danger>Yes, I'll permanently delete it</Button>
                            </Popconfirm>

                        </div>
                    </div>
                </div>


            </div>

        </>
    );
};

export default EditUser;
