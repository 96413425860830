import {
    CodeBracketIcon,
    CubeIcon,
    GlobeAltIcon,
    UsersIcon,
    Cog6ToothIcon,
    ChartBarIcon,
} from '@heroicons/react/24/solid';

export default function SiteFeatures() {
    const features = [
        {
            title: "Simplified Environment Setup",
            description:
                "Create and configure local development environments in seconds. No more manual setups or conflicts.",
            icon: <CodeBracketIcon className="w-14 h-14 text-orange-400" />,
        },
        {
            title: "Docker and Podman Support",
            description:
                "Seamlessly integrate with Docker or Podman for reliable containerized development workflows.",
            icon: <CubeIcon className="w-14 h-14 text-orange-400" />,
        },
        {
            title: "Multi-Language Support",
            description:
                "Compatible with GoLang, Python, PHP, Node.js, Java, .NET Core, and more frameworks and technologies.",
            icon: <GlobeAltIcon className="w-14 h-14 text-orange-400" />,
        },
        {
            title: "Fast Team Collaboration",
            description:
                "Share environments easily with your team to boost productivity and reduce friction during collaboration.",
            icon: <UsersIcon className="w-14 h-14 text-orange-400" />,
        },
        {
            title: "Automation and Templates",
            description:
                "Use pre-built templates and automated scripts to save time and avoid repetitive configuration tasks.",
            icon: <Cog6ToothIcon className="w-14 h-14 text-orange-400" />,
        },
        {
            title: "Scalable Plans",
            description:
                "From solo developers to enterprise teams, choose a plan that fits your needs with robust features and support.",
            icon: <ChartBarIcon className="w-14 h-14 text-orange-400" />,
        },
    ];

    return (
        <section id="features" className="relative isolate bg-white py-24 px-8 sm:py-32 lg:px-16">
            <div
                aria-hidden="true"
                className="absolute inset-x-0 -top-10 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
            >
                <div
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                    className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#f97316] to-[#fb923c] opacity-10"
                />

            </div>
            <div className="mx-auto max-w-4xl text-center">
                <h2 className="gradient-text text-blue-600 text-xl font-semibold mb-4">Features</h2>
                <p className="text-4xl font-bold text-gray-900 mb-6">
                    Discover the tools that make local development more efficient
                </p>
                <p className="mx-auto mt-6 max-w-2xl text-lg font-medium text-gray-600 sm:text-xl/8">
                    From automation to collaboration, explore how Kampa transforms your local development experience.
                </p>
            </div>

            <div className="mx-auto mt-16 grid max-w-7xl grid-cols-1 gap-y-12 sm:gap-y-16 md:grid-cols-2 lg:grid-cols-3 lg:gap-x-12 lg:gap-y-20">
                {features.map((feature, index) => (
                    <div
                        key={index}
                        className="flex flex-col items-center text-center bg-white/80 shadow-lg rounded-3xl p-10 hover:shadow-xl transition-shadow"
                    >
                        <div className="mb-6">{feature.icon}</div>
                        <h3 className="text-xl font-semibold text-gray-800 mb-4">
                            {feature.title}
                        </h3>
                        <p className="text-gray-600 text-base">{feature.description}</p>
                    </div>
                ))}
            </div>
        </section>
    );
}
