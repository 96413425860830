import { useEffect, useState } from "react";
import {Tour, TourProps} from "antd";
import useOnboardStore from "../Stores/useOnboarding.ts";

const Onboard = () => {
    const isOnboarded = useOnboardStore((state) => state.isOnboarded);
    const setOnboarded = useOnboardStore((state) => state.setOnboarded);

    const [open, setOpen] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<number>(0);

    useEffect(() => {
        if (!isOnboarded) {
            setTimeout(() => setOpen(true), 1500);
        }
    }, [isOnboarded]);

    useEffect(() => {
        if (isOnboarded) {
            setOpen(false);
        }
    }, [isOnboarded]);

    const steps: TourProps["steps"] = [
        { title: "Environments", description: "Create and manage your local development environments effortlessly. Start with a template or configure a custom setup to fit your needs." },
        { title: "Services", description: "Didn't find the services you need (Applications, Databases, etc.)? You can create your own by specifying the Dockerfile and dependencies, making them available for your environment." },
        { title: "Keys", description: "Before installing the CLI, generate and configure your API key to ensure secure access and authentication." },
        { title: "Kampa CLI", description: "With your API key set up, it's time to configure the CLI. This tool will help you manage your environments and streamline your development workflows." },
        { title: "Commands", description: "Create reusable custom commands for your organization. Use `kampa cmd list` to view available commands, then execute the desired one with `kampa cmd run <identifier>`." },
        { title: "Documentation", description: "Looking for more details? Explore our comprehensive documentation or reach out to our support team for assistance." },
    ];

    const handleClose = () => {
        setOpen(false);
        setCurrentStep(0);
        console.log('teste');
        setOnboarded(true);
    };

    const handleNext = (step: number) => {
        setCurrentStep(step);
        // console.log(step, steps.length);
    };

    if (isOnboarded) return null;

    return (
        <>
            <Tour
                open={open}
                onClose={handleClose}
                steps={steps}
                current={currentStep}
                onChange={handleNext}
            />
        </>
    );
};

export default Onboard;
