// EnvironmentsList.tsx
import React, {useEffect} from 'react';
import { Link } from "react-router-dom";
import EnvironmentRepository from './Repositories/EnvironmentRepository.ts';
import { Environment } from './Types.ts';
import GenericList from "../../Shared/GenericList.tsx";
import {ServerStackIcon, Square3Stack3DIcon} from "@heroicons/react/24/outline";
import {BreadcrumbLink} from "../../Shared/Types.ts";
import useBreadcrumbStore from "../../Stores/useBreadcrumbStore.tsx";
import { Button } from 'antd';
import {CodeBracketSquareIcon} from "@heroicons/react/24/solid";
import {PlusOutlined} from "@ant-design/icons";

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/local-environments',
        title: 'Local Environments',
    },
];

export const renderEnvironmentItem = (environment: Environment) => (
    <>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="flex items-center">
                <div className="ml-4">
                    <div className="text-sm font-medium leading-5 text-gray-900">{environment.name}</div>
                </div>
            </div>
        </td>

        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="flex items-center">
                <div className="ml-4">
                    <div className="text-sm font-medium leading-5 text-gray-900">{environment.identifier}</div>
                </div>
            </div>
        </td>

        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="text-sm leading-5 text-gray-900">{environment.description}</div>
        </td>

        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <span
                className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">{environment.status}</span>
        </td>

        <td className="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap border-b border-gray-200">

            <Link to={`/app/local-environments/${environment.uuid}`}>
                <Button>Edit</Button>
            </Link>
            { ' ' }
            <Link to={`/app/logs/?environment_uuid=${environment.uuid}`}>
                <Button>See logs</Button>
            </Link>

        </td>
    </>
);

const EnvironmentsList: React.FC = () => {
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
    }, []);

    return (
        <>
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Environments</h3>

                <div className="mt-5">
                    <div className="flex gap-4 "> {/* gap entre os botões e margem abaixo do grupo */}
                        <Link to={'/app/local-environments/create'}>
                            <Button
                                type="default"
                                className="flex items-center gap-2 border border-gray-300 shadow-sm rounded-xl px-3 py-3 text-sm font-medium text-gray-800 hover:border-gray-400 hover:shadow-md transition"
                                icon={
                                    <span className="relative">
                                      <ServerStackIcon className="w-5 h-5 text-gray-700"/>
                                      <PlusOutlined className="absolute -bottom-1 -right-1 text-[11px] bg-white rounded-full text-blue-500" />
                                    </span>
                                }
                            >
                                New environment
                            </Button>
                        </Link>


                        <Link to={'/app/local-environments/templates'}>
                            <Button
                                type="default"
                                className="flex items-center gap-2 border border-gray-300 shadow-sm rounded-xl px-3 py-3 text-sm font-medium text-gray-800 hover:border-gray-400 hover:shadow-md transition"
                                icon={
                                    <span className="relative">
                                      <CodeBracketSquareIcon className="w-5 h-5 text-gray-700"/>
                                      <PlusOutlined className="absolute -bottom-1 -right-1 text-[11px] bg-white rounded-full text-blue-500" />
                                    </span>
                                }
                            >
                                Use a Template
                            </Button>
                        </Link>


                        <Link to={'/app/services'}>
                            <Button
                                type="default"
                                className="flex items-center gap-2 border border-gray-300 shadow-sm rounded-xl px-3 py-3 text-sm font-medium text-gray-800 hover:border-gray-400 hover:shadow-md transition"
                                icon={
                                    <span className="relative">
                                      <Square3Stack3DIcon className="w-5 h-5 text-gray-700"/>
                                    </span>
                                }
                            >
                                Services
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="mt-2"></div>

            <GenericList<Environment>
                fetchData={EnvironmentRepository.findAll}
                renderItem={renderEnvironmentItem}
                columns={['Name', 'Identifier', 'Description', 'Status', '']}
            />
        </>
    );
}

export default EnvironmentsList;
