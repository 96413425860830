import React, {useState} from "react";
import SubscriptionsRepository from "./Repositories/SubscriptionsRepository.ts";
import {useNavigate} from "react-router-dom";
import {Button, Form, message, Switch} from "antd";
import useUserStore from "../Stores/useUserStore.ts";

const SubscriptionActivateForm: React.FC = () => {
    const [form] = Form.useForm()
    const formRef = React.useRef(null)
    const navigate = useNavigate()
    const [buttonLoading, setButtonLoading] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const updateOrganizationStatus = useUserStore((state) => state.updateOrganizationStatus);

    const onFinish = async (values) => {
        setButtonLoading(true)

        if (values.accept === undefined || values.accept !== true) {
            messageApi.open({
                type: 'error',
                content: 'You need to accept the term before continue',
            });
            setButtonLoading(false)
            return;
        }

        if (values.accept === true) {
            const response = await SubscriptionsRepository.activateSubscription(values)

            if (response) {
                updateOrganizationStatus("active");

                messageApi.open({
                    type: 'success',
                    content: 'You have activated your subscription',
                });

                setTimeout(() => navigate('/app/billing'), 2250);
            }
        }
    }

    return (
        <>
            {contextHolder}

            <div className="p-4 bg-white shadow rounded-lg">
                <h4 className="mb-5 text-lg font-semibold">Activate your plan</h4>

                <div className="pb-0">

                    <p className={'text-sm'}>
                        Seize the opportunity now and activate your subscription to keep enjoying premium content and
                        exclusive services, specially designed to meet your needs.<br/><br/>We're excited to have you on
                        board!<br/><br/>
                    </p>

                    <Form
                        form={form}
                        name="customerInfo"
                        onFinish={onFinish}
                        layout="vertical"
                        scrollToFirstError
                        className="p-0"
                        ref={formRef}
                    >
                        <Form.Item
                            name="accept"
                            label={
                                <div dangerouslySetInnerHTML={{ __html: "Please accept the terms to activate your plan." }} />
                            }
                            valuePropName="checked"
                        >
                            <Switch checkedChildren="Yes" />
                        </Form.Item>

                        <Form.Item className="mt-5">
                            <Button
                                loading={buttonLoading}
                                type="primary"
                                size="large"
                                className="bg-oggo-colour text-white px-4 py-2 rounded-md"
                                htmlType="submit"
                            >
                                Activate Subscription
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default SubscriptionActivateForm;