import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import useUserStore from '../App/Stores/useUserStore.ts';

const Logout: React.FC = () => {
    const clearUser = useUserStore((state) => state.clearUser);

    useEffect(() => {
        clearUser();
    }, [clearUser]);

    return <Navigate to="/login" />;
};

export default Logout;