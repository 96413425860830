import React, {useEffect} from 'react';
import { Button, Form, Input, message } from 'antd';
import {Documentation} from "./Types.ts";
import DocumentationRepository from "./Repositories/DocumentationRepository.ts";
import {useNavigate} from "react-router-dom";
import {BreadcrumbLink} from "../Shared/Types.ts";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/documentations',
        title: 'Documentations',
    },
    {
        href: '/app/documentations/create',
        title: 'Create',
    },
];

const CreateDocumentation: React.FC = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
    }, []);

    const onFinish = async (values: Documentation) => {
        try {
            await DocumentationRepository.save(values);
            messageApi.open({
                type: 'success',
                content: 'Documentation created successfully',
            });
            setTimeout(() => navigate('/app/documentations'), 2250);
        } catch (error) {
            message.error('Failed to create documentation: ' + error.message);
        }
    };

    return (
        <>
            {contextHolder}
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Documentations</h3>

                <div className="flex bg-white flex-col mt-8">
                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">

                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label="Name:"
                                    name="name"
                                    rules={[{ required: true, message: 'Please input the documentation name!' }]}
                                >
                                    <Input placeholder="Name" />
                                </Form.Item>

                                <Form.Item
                                    label="Path:"
                                    name="path"
                                    rules={[{ required: true, message: 'Please input the documentation path!' }]}
                                >
                                    <Input placeholder="path" />
                                </Form.Item>

                                <Form.Item
                                    label="Content:"
                                    name="content"
                                    rules={[{ required: true, message: 'Please input the documentation content!' }]}
                                >
                                    <MDEditor
                                        previewOptions={{
                                            skipHtml: true,
                                            rehypePlugins: [[rehypeSanitize]],
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">Save</Button>
                                </Form.Item>
                            </Form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateDocumentation;