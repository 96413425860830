import {Feature, FeatureUsage, OrganizationFeatureUsage} from "./Types.ts";

export function getFeature(features: OrganizationFeatureUsage, name: string): FeatureUsage | undefined {
    return features.find(feature => feature.name === name);
}

export function hasFeature(features: Feature[] | null | undefined, name: string): boolean {
    return (features ?? []).some(
        (feature) => name === feature.name && feature.value === 'true'
    );
}
