import React from 'react';

const DocumentationBoostRepoUpgradesWithKampa: React.FC = () => {
    return (
        <div className="container mx-auto p-6 text-gray-900 font-sans">
            <section className="mb-12">
                <h1 className="gradient-text text-4xl font-bold mb-6">Boost Repo Upgrades With Kampa</h1>

                <div className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">The fields?</h2>
                    <p className="text-lg leading-relaxed">
                        Kampa.io is a powerful tool designed to simplify the setup and sharing of local development
                        environments. It provides developers with the ability to create consistent, reproducible
                        environments that can be easily shared across teams. No more "it works on my machine" problems –
                        Kampa.io ensures that every member of your team is working in the same environment, minimizing
                        configuration issues and maximizing productivity.
                    </p>
                </div>

            </section>
        </div>
    );
};

export default DocumentationBoostRepoUpgradesWithKampa;
