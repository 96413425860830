// Profile.tsx
import React, {useEffect} from 'react';
import {BreadcrumbLink} from "../Shared/Types.ts";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import ProfilePersonalData from "./ProfilePersonalData.tsx";
import ProfilePassword from "./ProfilePassword.tsx";

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/profile',
        title: 'Profile',
    },
];

const Profile: React.FC = () => {
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
    }, []);

    return (
        <>
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Profile</h3>

                <div className="mt-8  p-4 bg-white border-b border-gray-200 shadow sm:rounded-lg">
                    <ProfilePersonalData/>
                </div>


                <div className={'mt-8  p-4 bg-white border-b border-gray-200 shadow sm:rounded-lg'}>

                    <ProfilePassword/>
                </div>


            </div>
        </>
    );
}

export default Profile;
