import axiosInstance from "../../../axios.ts";
import {Template} from "../Types.ts";
import {PaginatedResponse} from "../../Shared/Types.ts";

const findAll = async (page: number): Promise<PaginatedResponse<Template>> => {
    try {
        const response = await axiosInstance.get(`/templates?page=${page}&has_pagination=true`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'List failed');
    }
};

const findAllWithoutPagination = async (): Promise<Template[]> => {
    try {
        const response = await axiosInstance.get(`/templates?has_pagination=false`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'List failed');
    }
};

const findByUuid = async (uuid: string | undefined) => {
    try {
        const response = await axiosInstance.get(`/templates/${uuid}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Fetch failed');
    }
}

const save = async (template: Template): Promise<Template> => {
    try {
        const response = await axiosInstance.post(`/templates`, template);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Save failed');
    }
};

const update = async (template: Template): Promise<boolean> => {
    await axiosInstance.patch(`/templates/${template.uuid}`, template);
    return true;
};

const destroy = async (uuid: string): Promise<boolean> => {
    await axiosInstance.delete(`/templates/${uuid}`);
    return true;
};

export default {
    save,
    findAll,
    findAllWithoutPagination,
    findByUuid,
    update,
    destroy
};