import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import KeyRepository from "./Repositories/KeyRepository.ts";
import { Key } from "./Types";
import GenericList from "../Shared/GenericList.tsx";
import { KeyIcon } from "@heroicons/react/24/outline";
import { BreadcrumbLink } from "../Shared/Types.ts";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import { Button } from "antd";
import {PlusOutlined} from "@ant-design/icons";

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: "/app/keys",
        title: "Keys",
    },
];

const renderKeyItem = (key: Key) => (
    <>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="flex items-center">
                <div className="ml-4">
                    <div className="text-sm font-medium leading-5 text-gray-900">
                        {key.type}
                    </div>
                </div>
            </div>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="text-sm leading-5 text-gray-900">{key.auth_type}</div>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="text-sm leading-5 text-gray-900">{key.owner_type || "N/A"}</div>
        </td>

        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="text-sm leading-5 text-gray-900">{key.owner_name || "N/A"}</div>
        </td>

        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="text-sm leading-5 text-gray-900">{key.key}</div>
        </td>

        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
      <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
        {key.status}
      </span>
        </td>

        <td className="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap border-b border-gray-200">
            <Link to={`/app/keys/${key.uuid}`}>
                <Button>Edit</Button>
            </Link>
        </td>
    </>
);

const KeyList: React.FC = () => {
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
    }, []);

    return (
        <>
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Keys</h3>


                <div className="mt-5">
                    <div className="flex gap-4 ">

                        <Link to={'/app/keys/create'}>
                            <Button
                                type="default"
                                className="flex items-center gap-2 border border-gray-300 shadow-sm rounded-xl px-3 py-3 text-sm font-medium text-gray-800 hover:border-gray-400 hover:shadow-md transition"
                                icon={
                                    <span className="relative">
                                      <KeyIcon className="w-5 h-5 text-gray-700"/>
                                      <PlusOutlined className="absolute -bottom-1 -right-1 text-[11px] bg-white rounded-full text-blue-500" />
                                    </span>
                                }
                            >
                                New key
                            </Button>
                        </Link>

                    </div>
                </div>
            </div>

            <GenericList<Key>
                fetchData={KeyRepository.findAll}
                renderItem={renderKeyItem}
                columns={["Type", "Auth Type", "Owner Type", "Owner", "Key", "Status", ""]}
            />
        </>
    );
};

export default KeyList;
