import { useState, useEffect } from "react";
import axiosInstance from "../../axios.ts";
import useUserStore from "../Stores/useUserStore.ts";

export interface Parser {
    parse(input: string): string;
}

const useStreamedContent = (
    url: string,
    parser?: Parser,
) => {
    const [data, setData] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const { token } = useUserStore.getState();

    useEffect(() => {
        if (!url) return;

        const fetchStream = async () => {
            setData(""); // Reset data
            setLoading(true);

            try {
                const headers: HeadersInit = {
                    "Content-Type": "application/json",
                    Authorization: "bearer " + token,
                };

                const response = await fetch(axiosInstance.defaults.baseURL + url, {
                    method: "GET",
                    headers: headers,
                });

                if (!response.ok || !response.body) {
                    throw new Error(`HTTP Error! Status: ${response.status}`);
                }

                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                const ansiEscapeRegex = new RegExp("\x1B(?:[@-Z\\-_]|\[[0-?]*[ -/]*[@-~])", "g");
                let done = false;
                let buffer = ""; // Buffer to accumulate split log lines

                while (!done) {
                    const { value, done: isDone } = await reader.read();
                    done = isDone;

                    if (value) {
                        buffer += decoder.decode(value, { stream: true });

                        let newlineIndex;
                        while ((newlineIndex = buffer.indexOf("\n")) !== -1) {
                            let line = buffer.slice(0, newlineIndex);
                            buffer = buffer.slice(newlineIndex + 1);

                            line = line.replace(ansiEscapeRegex, "");

                            if (parser) {
                                line = parser.parse(line);
                            }

                            if (line.trim()) {
                                setData((prev) => prev + line + "\n");
                            }
                        }
                    }
                }

                if (buffer.trim()) {
                    setData((prev) => prev + buffer);
                }
            } catch (error) {
                console.error("Streaming error:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchStream();
    }, [url]);

    return { data, loading };
};

export default useStreamedContent;
