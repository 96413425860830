import React, {useEffect} from "react";
import SubscriptionsRepository from "./Repositories/SubscriptionsRepository.ts";
import SubscriptionInvoices from "./SubscriptionInvoices.tsx";
import { useState } from "react";
import {Invoice} from "./Types.ts";
import useUserStore from "../Stores/useUserStore.ts";
import SubscriptionActivateForm from "./SubscriptionActivateForm.tsx";
import SubscriptionCancel from "./SubscriptionCancel.tsx";

const Billing: React.FC = () => {
    const [data, setData] = useState<Invoice[]>([])
    const [loading, setLoading] = useState<boolean>(true);
    const organization = useUserStore((state) => state.organization);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response: Invoice[] = await SubscriptionsRepository.findAllInvoices();
                setData(response.map((invoice) => {
                    if (invoice.total != 0) {
                        invoice.total = invoice.total / 100
                    }

                    if (invoice.amount_paid != 0) {
                        invoice.amount_paid = invoice.amount_paid / 100
                    }

                    return invoice
                }))
                setLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchItems();
    }, []);

    if (loading) {
        return <>Loading</>
    }

    return (
        <>

            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Billing</h3>

                <div className="mt-8"></div>

                {organization.status === "trial" && organization.plan_identifier !== "free" ? (
                        <div className="flex bg-white flex-col mb-8">
                            <SubscriptionActivateForm/>
                        </div>
                ) : null}

                <div className="flex bg-white flex-col">
                    <SubscriptionInvoices propData={data}/>
                </div>

                <div className="mt-8 flex bg-white flex-col">
                    <SubscriptionCancel />
                </div>
            </div>

            {/*<SubscriptionCustomerInfo propData={data.customer} />*/}

            {/*{data.customer.is_family_plan ? <SubscriptionDeleteCustomer /> : null}*/}

        </>
    )
}


export default Billing;