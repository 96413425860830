// Breadcrumbs.tsx
import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import useBreadcrumbStore from "./Stores/useBreadcrumbStore";
import { HomeIcon } from '@heroicons/react/24/solid'; // Adjust import path as needed

const Breadcrumbs: React.FC = () => {
    const links = useBreadcrumbStore((state) => state.links);

    const items = links.map((item, index) => ({
        key: index,
        title: (
            <Link to={item.href || '#'}>
                {item.title === 'HOME_ICON'
                    ? <HomeIcon height={16} className="mt-1" />
                    : item.title?.toString() || ''}
            </Link>
        ),
    }));

    return <Breadcrumb items={items} className={'ml-2'} />;
};

export default Breadcrumbs;