// components/ProjectCard.tsx
import {
    EllipsisOutlined,
    GlobalOutlined,
    DeploymentUnitOutlined,
    DatabaseOutlined
} from '@ant-design/icons';
import AkamaiLogo from "../Images/AkamaiLogo.tsx";

interface ProjectCardProps {
    name: string;
    servers?: number;
    cameras?: number;
    deployments?: number;
}

export default function ProjectCard({ name, servers = 0, cameras = 0, deployments = 0 }: ProjectCardProps) {
    const total: number = servers + cameras + deployments;

    return (
        <div className="bg-gradient-to-br from-gray-500 to-gray-700 text-white rounded-xl p-4 shadow-lg w-full max-w-xs relative">
            <div className="flex justify-between items-center mb-4">
                <div className="flex items-center gap-3">
                    <div className="bg-white rounded p-2 flex items-center justify-center">
                        <AkamaiLogo />
                    </div>
                    <span className="text-lg font-semibold">{name}</span>
                </div>
                <EllipsisOutlined className="text-xl cursor-pointer" />
            </div>

            <div className="flex gap-2 mb-4">
                <span className="bg-white/10 px-3 py-1 rounded-full text-sm">Owner</span>
                <span className="bg-white/10 px-3 py-1 rounded-full text-sm">1 Member</span>
            </div>

            {(servers > 0 || cameras > 0 || deployments > 0) && (
                <div className="flex gap-4 text-sm mt-4 text-gray-200">
                    {servers > 0 && <div className="flex items-center gap-1"><GlobalOutlined /> {servers}</div>}
                    {cameras > 0 && <div className="flex items-center gap-1"><DatabaseOutlined /> {cameras}</div>}
                    {deployments > 0 && <div className="flex items-center gap-1"><DeploymentUnitOutlined /> {deployments}</div>}
                </div>
            )}

            <div className="text-right mt-2 text-sm text-white/70">+ { total }</div>
        </div>
    );
}
