import { useState } from "react";
import {Badge, Form, Tabs} from "antd";
import {
    AppstoreOutlined,
    DatabaseOutlined,
    DatabaseFilled,
    ToolOutlined,
    MessageOutlined,
    FundOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import EnvironmentItemTabsService from "./EnvironmentItemTabsService.tsx";

export default function EnvironmentItemTabs({ itemRefs, form, includeProxy, data }) {
    const [activeKey, setActiveKey] = useState("1");

    const services = Form.useWatch('services', form) || {};

    const counters = {
        application: services.application?.length || 0,
        cache: services.cache?.length || 0,
        database: services.database?.length || 0,
        developer_tools: services.developer_tools?.length || 0,
        brokers: services.brokers?.length || 0,
        monitoring: services.monitoring?.length || 0,
        worker: services.worker?.length || 0,
    };


    const items = [
        {
            key: "1",
            label: (
                <span className={`p-2 flex items-center gap-2 ${activeKey === "1" ? "text-orange-600 font-semibold" : ""}`}>
                    <AppstoreOutlined /> Application
                    <Badge count={counters.application} />
                </span>
            ),
            children: <EnvironmentItemTabsService tabTitle={'application'} tabType={'application'} form={form} includeProxy={includeProxy} itemRefs={itemRefs} data={data} />,
            forceRender: true,
        },
        {
            key: "2",
            label: (
                <span className={`p-2 flex items-center gap-2 ${activeKey === "2" ? "text-orange-600 font-semibold" : ""}`}>
                    <DatabaseOutlined /> Cache
                    <Badge count={counters.cache} />
                </span>
            ),
            children: <EnvironmentItemTabsService tabTitle={'cache'} tabType={'cache'} form={form} includeProxy={includeProxy} itemRefs={itemRefs} data={data} />,
            forceRender: true,
        },
        {
            key: "3",
            label: (
                <span className={`p-2 flex items-center gap-2 ${activeKey === "3" ? "text-orange-600 font-semibold" : ""}`}>
                    <DatabaseFilled /> Database
                    <Badge count={counters.database} />
                </span>
            ),
            children: <EnvironmentItemTabsService tabTitle={'database'} tabType={'database'} form={form} includeProxy={includeProxy} itemRefs={itemRefs} data={data} />,
            forceRender: true,
        },
        {
            key: "4",
            label: (
                <span className={`p-2 flex items-center gap-2 ${activeKey === "4" ? "text-orange-600 font-semibold" : ""}`}>
                    <ToolOutlined /> Developer tools
                    <Badge count={counters.developer_tools} />
                </span>
            ),
            children: <EnvironmentItemTabsService tabTitle={'developer tool'} tabType={'developer_tools'} form={form} includeProxy={includeProxy} itemRefs={itemRefs} data={data} />,
            forceRender: true,
        },
        {
            key: "5",
            label: (
                <span className={`p-2 flex items-center gap-2 ${activeKey === "5" ? "text-orange-600 font-semibold" : ""}`}>
                    <MessageOutlined /> Message Broker
                    <Badge count={counters.brokers} />
                </span>
            ),
            children: <EnvironmentItemTabsService tabTitle={'message broker'} tabType={'message_broker'} form={form} includeProxy={includeProxy} itemRefs={itemRefs} data={data} />,
            forceRender: true,
        },
        {
            key: "6",
            label: (
                <span className={`p-2 flex items-center gap-2 ${activeKey === "6" ? "text-orange-600 font-semibold" : ""}`}>
                    <FundOutlined /> Monitoring
                    <Badge count={counters.monitoring} />
                </span>
            ),
            children: <EnvironmentItemTabsService tabTitle={'monitoring tool'} tabType={'monitoring'} form={form} includeProxy={includeProxy} itemRefs={itemRefs} data={data} />,
            forceRender: true,
        },
        {
            key: "7",
            label: (
                <span className={`p-2 flex items-center gap-2 ${activeKey === "7" ? "text-orange-600 font-semibold" : ""}`}>
                    <SettingOutlined /> Worker
                    <Badge count={counters.worker} />
                </span>
            ),
            children: <EnvironmentItemTabsService tabTitle={'worker'} tabType={'worker'} form={form} includeProxy={includeProxy} itemRefs={itemRefs} data={data} />,
            forceRender: true,
        },
    ];

    return (
        <div className="w-full">
            <div className="w-full mt-8">
                <Tabs
                    defaultActiveKey="1"
                    activeKey={activeKey}
                    onChange={setActiveKey}
                    destroyInactiveTabPane={false}
                    items={items}
                />
            </div>
        </div>
    );
}
