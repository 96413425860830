import React, {useEffect} from 'react';
import { Link } from "react-router-dom";
import {Command} from './Types';
import {CommandLineIcon} from "@heroicons/react/24/outline";
import {BreadcrumbLink} from "../Shared/Types.ts";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import { Button } from 'antd';
import GenericListWithFilters from "../Shared/ListWIthFilters/GenericListWithFilters.tsx";
import CommandRepository from "./Repositories/CommandRepository.ts";
import FiltersManager from "../Shared/ListWIthFilters/FiltersManage.ts";
import {PlusOutlined} from "@ant-design/icons";

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/commands',
        title: 'Commands',
    },
];

const renderCommandItem = (command: Command) => (
    <>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="flex items-center">
                <div className="ml-4">
                    <div className="text-sm font-medium leading-5 text-gray-900">{command?.team ?? '-'}</div>
                </div>
            </div>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="flex items-center">
                <div className="ml-4">
                    <div className="text-sm font-medium leading-5 text-gray-900">{command.name}</div>
                </div>
            </div>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="flex items-center">
                <div className="ml-4">
                    <div className="text-sm font-medium leading-5 text-gray-900">{command.identifier}</div>
                </div>
            </div>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="text-sm leading-5 text-gray-900">{command.description}</div>
        </td>

        <td className="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap border-b border-gray-200">

            <Link to={`/app/commands/${command.uuid}`}>
                <Button>Edit</Button>
            </Link>
        </td>
    </>
);

const CommandsList: React.FC = () => {
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
    }, []);

    return (
        <>
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Commands</h3>

                <div className="mt-5">
                    <div className="flex gap-4 ">
                        <Link to={'/app/commands/create'}>
                            <Button
                                type="default"
                                className="flex items-center gap-2 border border-gray-300 shadow-sm rounded-xl px-3 py-3 text-sm font-medium text-gray-800 hover:border-gray-400 hover:shadow-md transition"
                                icon={
                                    <span className="relative">
                                      <CommandLineIcon className="w-5 h-5 text-gray-700"/>
                                      <PlusOutlined className="absolute -bottom-1 -right-1 text-[11px] bg-white rounded-full text-blue-500" />
                                    </span>
                                }
                            >
                                New command
                            </Button>
                        </Link>
                    </div>
                </div>

            </div>

            <GenericListWithFilters<Command>
                fetchData={CommandRepository.findAll}
                renderItem={renderCommandItem}
                initialFilters={new FiltersManager({
                    page: 1,
                    hasPagination: true,
                })}
                columns={['Team', 'Name', 'Identifier', 'Description', '']}
            />
        </>
    );
}

export default CommandsList;
