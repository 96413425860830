import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, Input, message, Radio, Spin, Switch} from 'antd';
import {Environment, EnvironmentService} from "./Types.ts";
import {useNavigate, useParams} from "react-router-dom";
import { BreadcrumbLink } from "../../Shared/Types.ts";
import useBreadcrumbStore from "../../Stores/useBreadcrumbStore.tsx";
import EnvironmentRepository from "./Repositories/EnvironmentRepository.ts";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import {strictSanitizeSchema} from "../../Shared/SanitizeTags.ts";
import EnvironmentItemTabs from "./EnvironmentItemTabs.tsx";
import TemplateRepository from "../../TemplatesManager/Repositories/TemplateRepository.ts";
import {Template} from "../../TemplatesManager/Types.ts";

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/local-environments',
        title: 'Local Environments',
    },
    {
        href: '#',
        title: 'Create from template',
    },
];

const CreateEnvironmentFromTemplate: React.FC = () => {
    const [form] = Form.useForm();
    const {templateUuid} = useParams();
    const navigate = useNavigate();
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [messageApi, contextHolder] = message.useMessage();
    const [data, setData] = useState<Environment>();
    const [loading, setLoading] = useState<boolean>(true)
    const [includeProxy, setIncludeProxy] = useState<boolean>(false)
    const itemRefs = useRef({});

    const onFinish = async (values: Environment) => {
        try {
            values['services'] = Object.values(values.services)
                .filter(Array.isArray)
                .flat();

            await EnvironmentRepository.save(values);
            messageApi.open({
                type: 'success',
                content: 'Environment created successfully',
            });
            setTimeout(() => navigate('/app/local-environments'), 2250);
        } catch (error) {
            const msg = error.response?.data?.message || error.message || 'An unknown error occurred.';
            message.error(`Failed to update environment: ${msg}`);
        }
    };

    useEffect(() => {
        setBreadcrumb(breadcrumbs);

        const fetchEnvironment = async (templateUuid: string | undefined) => {
            try {
                const response: Template = await TemplateRepository.findByUuid(templateUuid);
                const preData = JSON.parse(response.content)
                const groupedServices = (Array.isArray(preData.services) ? preData.services : []).reduce(
                    (acc, service) => {
                        const key = service.service_type;
                        if (!acc[key]) {
                            acc[key] = [];
                        }
                        acc[key].push(service);
                        return acc;
                    },
                    {} as Record<string, EnvironmentService[]>
                );

                setData({
                    ...preData,
                    services: groupedServices,
                });

                setIncludeProxy(preData.include_proxy)

                setLoading(false);
            } catch (error) {
                console.error('Error fetching service:', error);
            }
        };

        fetchEnvironment(templateUuid);
    }, []);

    if (loading) {
        return <>
            <div className="container px-6 py-8 mx-auto">
                <Spin size="small" />
                loading
            </div>
        </>
    }

    return (
        <>
            {contextHolder}
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Environments</h3>
                <div className="flex bg-white flex-col mt-8">
                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div
                            className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={onFinish}
                                initialValues={{
                                    ...data,
                                    instructions: data?.instructions ?? "",
                                    services: data?.services ? data.services : [],
                                }}
                            >
                                <Form.Item label="Status:" name="status">
                                    <Radio.Group>
                                        <Radio.Button value="active">Active</Radio.Button>
                                        <Radio.Button value="inactive">Inactive</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item
                                    label="Name:"
                                    name="name"
                                    rules={[{required: true, message: 'Please input the environment name!'}]}
                                >
                                    <Input placeholder="Name"/>
                                </Form.Item>

                                <Form.Item label="Description:" name="description">
                                    <Input
                                        placeholder="Environment's short description"
                                        maxLength={150}
                                        showCount
                                    />
                                </Form.Item>


                                <Form.Item label="Setup & Usage Instructions" name="instructions">
                                    <MDEditor
                                        previewOptions={{
                                            skipHtml: true,
                                            rehypePlugins: [[rehypeSanitize, strictSanitizeSchema]],
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Identifier:"
                                    name="identifier"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input the environment name!'
                                        },
                                        {
                                            pattern: /^[a-zA-Z-]+$/,
                                            message: "Only letters (a-z, A-Z) and hyphens (-) are allowed, without spaces",
                                        },
                                    ]}
                                >
                                    <Input placeholder="unique-identifier"/>
                                </Form.Item>

                                <Form.Item label="Include Reverse Proxy?" name="include_proxy">
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onChange={() => setIncludeProxy(!includeProxy)}
                                    />
                                </Form.Item>

                                <EnvironmentItemTabs
                                    itemRefs={itemRefs}
                                    form={form}
                                    includeProxy={includeProxy}
                                    data={data}
                                />

                                <Form.Item
                                    className={'mt-8'}>
                                    <Button type="primary" htmlType="submit">
                                    Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default CreateEnvironmentFromTemplate;