import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import {User, UserInvite} from './Types';
import GenericList from "../Shared/GenericList.tsx";
import {UserGroupIcon} from "@heroicons/react/24/outline";
import {BreadcrumbLink} from "../Shared/Types.ts";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import {Button, Tabs, TabsProps} from 'antd';
import UserRepository from "./Repositories/UserRepository.ts";
import '../Shared/customTabs.css';
import UserInviteRepository from "./Repositories/UserInviteRepository.ts";
import dayjs from "dayjs";
import {PlusOutlined} from "@ant-design/icons";

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/users',
        title: 'Users',
    },
];

const renderUserItem = (user: User) => (
    <>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="text-sm leading-5 text-gray-900">{user.role}</div>
        </td>

        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="flex items-center">
                <div className="ml-4">
                    <div
                        className="text-sm font-medium leading-5 text-gray-900">{user.first_name} {user.last_name}</div>
                </div>
            </div>
        </td>

        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="text-sm leading-5 text-gray-900">{user.email}</div>
        </td>

        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="text-sm leading-5 text-gray-900">{user.username}</div>
        </td>


        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="text-sm leading-5 text-gray-900">{user.status}</div>
        </td>

        <td className="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap border-b border-gray-200">

            <Link to={`/app/users/${user.uuid}`}>
                <Button>Edit</Button>
            </Link>
        </td>
    </>
);

const renderUserInviteItem = (invite: UserInvite) => (
    <>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="text-sm leading-5 text-gray-900">{invite.role}</div>
        </td>

        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div
                className="text-sm font-medium leading-5 text-gray-900">
                {invite.invitee_name}
            </div>
        </td>

        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div
                className="text-sm font-medium leading-5 text-gray-900">
                {invite.invitee_email}
            </div>
        </td>

        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="text-sm leading-5 text-gray-900">{invite.status}</div>
        </td>

        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="text-sm leading-5 text-gray-900">
                {invite.accepted_at ? dayjs(invite?.accepted_at).format('YYYY-MM-DD HH:mm') : '-'}
            </div>
        </td>

        <td className="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap border-b border-gray-200">

            <Link to={`/app/users/users-invites/${invite.uuid}`}>
                <Button>Edit</Button>
            </Link>
        </td>
    </>
);

const UsersList: React.FC = () => {
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [activeKey, setActiveKey] = useState<string>("1"); // Initialize with the default active key

    const handleTabChange = (key) => {
        setActiveKey(key);
    };

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
    }, []);

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Users',
            forceRender: true,
            children: <GenericList<User>
                fetchData={UserRepository.findAll}
                renderItem={renderUserItem}
                columns={['Role', 'Name', 'Email', 'Username', 'Status', '']}
            />
        },
        {
            key: '2',
            label: 'Invites',
            forceRender: true,
            children: <GenericList<UserInvite>
                fetchData={UserInviteRepository.findAll}
                renderItem={renderUserInviteItem}
                columns={['Role', 'Invitee name', 'Invitee email', 'Status', 'Status at', '']}
            />
        }
    ];

    return (
        <>
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Users</h3>

                <div className="mt-5">
                    <div className="flex gap-4 ">

                        <Link to={'/app/users/invite'}>
                            <Button
                                type="default"
                                className="flex items-center gap-2 border border-gray-300 shadow-sm rounded-xl px-3 py-3 text-sm font-medium text-gray-800 hover:border-gray-400 hover:shadow-md transition"
                                icon={
                                    <span className="relative">
                                      <UserGroupIcon className="w-5 h-5 text-gray-700"/>
                                      <PlusOutlined className="absolute -bottom-1 -right-1 text-[11px] bg-white rounded-full text-blue-500" />
                                    </span>
                                }
                            >
                                New user invite
                            </Button>
                        </Link>

                    </div>
                </div>
            </div>

            <Tabs
                className="list-users-tabs"
                activeKey={activeKey}
                onChange={handleTabChange}
                defaultActiveKey="1"
                items={items}
                tabBarExtraContent={{
                    left: <div style={{ marginLeft: '25px' }}></div>,
                    right: <div style={{ marginRight: '25px' }}></div>,
                }}
            />

        </>
    );
}

export default UsersList;
