export default class FiltersManager {
    private filters: Record<string, any>;

    constructor(initialFilters: Record<string, any> = {}) {
        this.filters = initialFilters;
    }

    // Get a filter value by key
    get(key: string): any {
        return this.filters[key];
    }

    // Set or update a filter
    set(key: string, value: any): void {
        this.filters[key] = value;
    }

    // Remove a filter
    remove(key: string): void {
        delete this.filters[key];
    }

    // Get all filters
    getAll(): Record<string, any> {
        return { ...this.filters };
    }
}