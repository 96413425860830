import {isRouteErrorResponse, Link, useRouteError} from "react-router-dom";

export function ErrorBoundary() {
    const error = useRouteError(); // ✅ Fetches error from React Router

    if (isRouteErrorResponse(error)) {
        return (
            <div style={{ textAlign: "center", padding: "20px" }}>
                <h1>
                    {error.status} {error.statusText}
                </h1>
                <p>{error.data || "Something went wrong."}</p>
            </div>
        );
    } else if (error instanceof Error) {
        return (
            <section className="flex items-center h-screen p-16 bg-gray-50 dark:bg-gray-700">
                <div className="container flex flex-col items-center ">
                    <div className="flex flex-col gap-6 max-w-md text-center">
                        <h2 className="font-extrabold text-9xl text-gray-600 dark:text-gray-100">
                            <span className="sr-only">Error</span>WoW
                        </h2>
                        <p className="text-2xl md:text-3xl dark:text-gray-300">
                            Something went wrong</p>
                        <Link to="/"
                              className="px-8 py-4 text-xl font-semibold rounded bg-orange-500 text-gray-50 hover:text-gray-200">Back
                            to home</Link>
                    </div>
                </div>
            </section>
        );
    } else {
        return <h1 style={{ textAlign: "center" }}>Unknown Error</h1>;
    }
}
