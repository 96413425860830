import React, { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import useUserStore from "../App/Stores/useUserStore.ts";

const ProtectedRoute: React.FC = () => {
    const isLoggedIn = useUserStore((state) => state.isLoggedIn);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    return isLoggedIn ? <Outlet /> : null;
};

export default ProtectedRoute;
