import {Button, Form, Input, message, Select} from "antd";
import {useEffect, useState} from "react";
import {Profile as ProfileType} from "./Types.ts";
import ProfileRepository from "./Repositories/ProfileRepository.ts";
import {useNavigate} from "react-router-dom";
import useUserStore from "../Stores/useUserStore.ts";

const ProfilePersonalData = () => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [data, setData] = useState<ProfileType | undefined>();
    const [loading, setLoading] = useState<boolean>(true)
    const navigate = useNavigate();
    const setUser = useUserStore(state => state.setUser);
    const user = useUserStore();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response: ProfileType = await ProfileRepository.fetchData();
                setData(response);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching service:', error);
            }
        };

        fetchData();
    }, []);

    const onFinish = async (values: ProfileType) => {
        try {
            await ProfileRepository.update(values);
            messageApi.open({
                type: 'success',
                content: 'Profile updated successfully',
            });

            setUser({
                ...user,
                first_name: values.first_name,
                last_name: values.last_name,
            });

            setTimeout(() => navigate('/app/profile'), 2250);
        } catch (error) {
            message.error('Failed to update profile: ' + error.message);
        }
    };

    if (loading) {
        return <>
            <div className="container px-6 py-8 mx-auto">
            loading
            </div>
        </>
    }


    return (
        <>
            {contextHolder}

            <h2 className="text-xl font-semibold mb-6">Update personal data</h2>

            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                initialValues={data}
            >
                <Form.Item
                    label="First Name:"
                    name="first_name"
                >
                    <Input
                        placeholder="Name"
                    />
                </Form.Item>

                <Form.Item
                    label="Last Name:"
                    name="last_name"
                >
                    <Input
                        placeholder="Last Name"
                    />
                </Form.Item>

                <Form.Item
                    label="Role:"
                    name="role"

                >
                    <Select
                        disabled
                        options={[
                            {value: 'manager', label: 'Manager'},
                            {value: 'user', label: 'Common user'},
                        ]}
                    />
                </Form.Item>

                <Form.Item
                    label="Email:"
                    name="email"
                >
                    <Input
                        disabled={true}
                        placeholder="Email"/>
                </Form.Item>


                <Form.Item
                    label="Username:"
                    name="username"
                >
                    <Input
                        disabled={true}
                        placeholder="Username"
                    />
                </Form.Item>


                <Form.Item
                    className={'mt-8'}>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}


export default ProfilePersonalData;