import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Alert } from 'antd';
import authRepository from './Repositories/AuthRepository.js';

const ResetPassword: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [form, setForm] = useState({
    email: '',
    token: '',
    password: '',
    password_confirmation: '',
  });
  const [success, setSuccess] = useState<string | null>(null);
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      setForm((prevForm) => ({
        ...prevForm,
        token,
      }));
    }
  }, [token]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (form.password !== form.password_confirmation) {
      setError("Passwords do not match. Please try again.");
      return;
    }

    try {
      const response = await authRepository.resetPassword(form);
      if (response?.errors) {
        setError(response.errors.email);
      } else {
        setSuccess('Password has been successfully reset!');
        setError(null);
      }
    } catch (error) {
      setError(error.message ?? 'Invalid data');
      setSuccess(null);
    }
  };

  return (
      <>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <Link to="/">
              <img
                  alt="Kampa"
                  src='/images/k.svg'
                  className="mx-auto h-28 w-auto"
              />
            </Link>
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Reset your password
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">

            {!success ? (
                <>
                  {error && (
                      <Alert
                          message={error}
                          type="error"
                          showIcon
                          closable
                          onClose={() => setError(null)}
                          className="mb-8"
                      />
                  )}


                  <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                      <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            required
                            autoComplete="email"
                            value={form.email}
                            onChange={(e) =>
                                setForm({ ...form, email: e.target.value })
                            }
                            className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                          htmlFor="password"
                          className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Password
                      </label>
                      <div className="mt-2">
                        <input
                            id="password"
                            name="password"
                            type="password"
                            required
                            autoComplete="new-password"
                            value={form.password}
                            onChange={(e) =>
                                setForm({ ...form, password: e.target.value })
                            }
                            className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                          htmlFor="password_confirmation"
                          className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Confirm your password
                      </label>
                      <div className="mt-2">
                        <input
                            id="password_confirmation"
                            name="password_confirmation"
                            type="password"
                            required
                            autoComplete="new-password"
                            value={form.password_confirmation}
                            onChange={(e) =>
                                setForm({
                                  ...form,
                                  password_confirmation: e.target.value,
                                })
                            }
                            className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div>
                      <button
                          type="submit"
                          className="flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                      >
                        Recover password
                      </button>
                    </div>
                  </form>
                </>
            ) : (
              <>

              <Alert
              message={success}
                type="success"
                showIcon
                closable
                onClose={() => setSuccess(null)}
                className="mb-8"
              />

              <Link
                  type="button"
                  className="mt-4 flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                  to={'/login'}
              >
                Log in
              </Link>
            </>
            )}

          </div>
        </div>
      </>
  );
};

export default ResetPassword;
