import React from "react";
import useStreamedContent from "../../Shared/useStreamContent.ts";
import { Spin } from "antd";
import LogParser from "../LogParser.tsx";

interface LogReaderProps {
    url: string;
}

const LogReader: React.FC<LogReaderProps> = ({ url }) => {
    const { data, loading } = useStreamedContent(url, new LogParser());

    return (
        <div>
            <h2 className="text-xl font-semibold mb-4">Streamed Log Content</h2>

            <div className="flex-1 overflow-hidden">
                <div className="h-full w-full max-h-[calc(100vh-64px)] overflow-y-auto border border-gray-600 p-4 rounded-md text-sm">
                    {loading && (
                        <p>
                            <Spin /> Loading...
                        </p>
                    )}

                    <pre style={{ whiteSpace: "pre-wrap"  }}><div dangerouslySetInnerHTML={{ __html: data }} /></pre>
                </div>
            </div>
        </div>
    );
};

export default LogReader;
