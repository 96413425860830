import SubscriptionsRepository from "./Repositories/SubscriptionsRepository.ts";
import {Invoice} from "./Types.ts";
import dayjs from 'dayjs'
import React from "react";
import {message} from "antd";

type SubscriptionInvoicesProps = {
    propData: Invoice[];
}

const locale = navigator.language || "en-US";

const dateTimeOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
};

const SubscriptionInvoices: React.FC<SubscriptionInvoicesProps> = ({ propData }) => {
    const [messageApi, contextHolder] = message.useMessage();

    const downloadPdf = (link: string) => {
        window.location.href = link;
    };

    const sendPaymentLinkToEmail = async (invoiceId: string) => {
        try {
            const response = await SubscriptionsRepository.sendInvoiceToEmail(invoiceId);
            if (response) {
                messageApi.open({
                    type: 'success',
                    content: 'The email with the invoice was sent to your email.',
                });
            }
        } catch (error) {
            messageApi.open({
                type: 'error',
                content: 'Error sending invoice email.',
            });
        }
    };

    return (
        <>
        {contextHolder}
            <div className="p-4 bg-white shadow rounded-lg">
                <h4 className="mb-5 text-lg font-semibold">Invoices</h4>

                <div className="p-3 pb-0">
                    {propData.length === 0 ? (
                        <p>No invoices available</p>
                    ) : (
                        propData.map((invoice) => (
                            <React.Fragment key={invoice.id}>
                                <div className="flex flex-col md:flex-row md:items-center justify-between gap-y-4">
                                    {/* Date Range */}
                                    <div className="w-full md:w-1/4">
                                        <b>
                                            {`${new Intl.DateTimeFormat(locale, dateTimeOptions).format(
                                                dayjs(invoice.lines[0].period.start).toDate()
                                            )} to ${new Intl.DateTimeFormat(locale, dateTimeOptions).format(
                                                dayjs(invoice.lines[0].period.end).toDate()
                                            )}`}
                                        </b>
                                    </div>

                                    {/* Plan Description */}
                                    <div className="w-full md:w-1/4">
                                        Plan: {invoice.lines[0].description}
                                    </div>

                                    {/* Invoice Amount */}
                                    <div className="w-full md:w-1/4">
                                        <span
                                            className={`px-3 py-1 text-white text-sm rounded ${
                                                invoice.paid ? "bg-green-500" : "bg-red-500"
                                            }`}
                                        >
                                            {new Intl.NumberFormat(locale, {
                                                style: "currency",
                                                currency: invoice.currency,
                                            }).format(invoice.paid ? invoice.amount_paid : invoice.total)}
                                        </span>
                                    </div>

                                    {/* Action Button */}
                                    <div className="w-full md:w-1/4">
                                        {invoice.paid ? (
                                            <button
                                                onClick={() => downloadPdf(invoice.invoice_pdf)}
                                                className="w-full md:w-auto flex items-center justify-center px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
                                            >
                                                Download Receipt
                                            </button>
                                        ) : (
                                            <button
                                                onClick={() => sendPaymentLinkToEmail(invoice.id)}
                                                className="w-full md:w-auto flex items-center justify-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                            >
                                                Send Payment Link
                                            </button>
                                        )}
                                    </div>
                                </div>

                                <hr className="my-4 border-gray-300" />
                            </React.Fragment>
                        ))
                    )}
                </div>
            </div>
        </>
    );
};


export default SubscriptionInvoices;
