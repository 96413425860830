// NotificationsList.tsx
import React, {useEffect} from 'react';
import GenericList from "../Shared/GenericList.tsx";
import {BreadcrumbLink} from "../Shared/Types.ts";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import NotificationRepository from "./Repositories/NotificationRepository.tsx";
import {AuditLogEntry} from "./Types.ts";
import GetNotificationIcon from "./NotificationHelper.tsx";

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/notifications',
        title: 'Notifications',
    },
];

export const renderNotificationItem = (entry: AuditLogEntry) => (
    <>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="flex items-center">
                <div className="ml-4">
                    <div className="text-sm font-medium leading-5 text-gray-900">{entry.username}</div>
                </div>
            </div>
        </td>

        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className={'flex flex-auto'}>
                { GetNotificationIcon(entry.auditable_type)}
                <div className=" text-sm leading-5 text-gray-900">
                    {entry.label}</div>
            </div>
        </td>



        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="text-sm leading-5 text-gray-900">{entry.occurred_at}</div>
        </td>
    </>
);

const NotificationsList: React.FC = () => {
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
    }, []);

    return (
        <>
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Notifications</h3>

                <div className="mt-8"></div>

                <div className="relative flex py-5 items-center">
                    <div className="flex-grow border-t border-gray-200"></div>
                </div>


            </div>

            <GenericList<AuditLogEntry>
                fetchData={NotificationRepository.findAll}
                renderItem={renderNotificationItem}
                columns={['Username', 'Action', 'Datetime']}
            />
        </>
    );
}

export default NotificationsList;
