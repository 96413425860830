import React, { useEffect } from 'react';
import { Button, Form, Input, message, Switch, Select } from 'antd';
import { Command } from "./Types.ts";
import CommandRepository from "./Repositories/CommandRepository.ts";
import { useNavigate } from "react-router-dom";
import { BreadcrumbLink } from "../Shared/Types.ts";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import TeamSelectComponent from "../Shared/TeamSelectComponent.tsx";

const { Option } = Select;

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/commands',
        title: 'Commands',
    },
    {
        href: '/app/commands/create',
        title: 'Create',
    },
];

const CreateCommand: React.FC = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [messageApi, contextHolder] = message.useMessage();
    const typeValue = Form.useWatch('type', form);
    const isFixedContainer = Form.useWatch('is_fixed_container', form);

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
    }, []);

    const onFinish = async (values: Command) => {
        console.log(values)
        try {
            await CommandRepository.save(values);
            messageApi.open({
                type: 'success',
                content: 'Command created successfully',
            });
            setTimeout(() => navigate('/app/commands'), 2250);
        } catch (error) {
            console.log(error);
            message.error('Failed to create command: ' + error);
        }
    };


    return (
        <>
            {contextHolder}
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Commands</h3>

                <div className="flex bg-white flex-col mt-8">
                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">

                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={onFinish}
                            >
                                <TeamSelectComponent
                                    name="team_uuid"
                                    label="Team:"
                                    placeholder="Select one of your teams"
                                    includeAll={true}
                                    rules={[{ required: false, message: 'Select one team' }]}
                                />

                                <Form.Item
                                    label="Execution Type:"
                                    name="type"
                                    rules={[{ required: true, message: 'Please select where to run the command!' }]}
                                >
                                    <Select placeholder="Select execution type">
                                        <Option value="host">Host</Option>
                                        <Option value="container">Container</Option>
                                    </Select>
                                </Form.Item>

                                {typeValue === 'container' && (
                                    <Form.Item
                                        label="Is Fixed Container?"
                                        name="is_fixed_container"
                                        valuePropName="checked"
                                    >
                                        <Switch />
                                    </Form.Item>
                                )}

                                {typeValue === 'container' && isFixedContainer && (
                                    <Form.Item
                                        label="Container Name:"
                                        name="container"
                                        rules={[{ required: true, message: 'Please input the container name!' }]}
                                    >
                                        <Input placeholder="Container" />
                                    </Form.Item>
                                )}

                                <Form.Item
                                    label="Name:"
                                    name="name"
                                    rules={[{ required: true, message: 'Please input the command name!' }]}
                                >
                                    <Input placeholder="Name" />
                                </Form.Item>

                                <Form.Item
                                    label="Identifier:"
                                    name="identifier"
                                    rules={[
                                        { required: true, message: 'Please input the identifier!' },
                                        {
                                            pattern: /^[a-zA-Z0-9_:.-]+$/,
                                            message: 'Only letters, numbers, colons (:), underscores (_), hyphens (-), and periods (.) are allowed!'
                                        }
                                    ]}
                                >
                                    <Input placeholder="Identifier" />
                                </Form.Item>



                                <Form.Item
                                    label="Description (optional):"
                                    name="description"
                                >
                                    <Input.TextArea placeholder="Command description" rows={3} />
                                </Form.Item>

                                <Form.Item
                                    label="Command:"
                                    name="command"
                                    rules={[{ required: true, message: 'Please input the command!' }]}
                                >
                                    <Input placeholder="Enter command here" />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">Save</Button>
                                </Form.Item>
                            </Form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateCommand;
