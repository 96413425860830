import Header from "./Components/Header.tsx";
import Footer from "./Components/Footer.tsx";
import React from "react";
import {Link} from "react-router-dom";
import HelmetConfig from "../HelmetConfig.tsx";

const SiteAbout: React.FC = () => {
    return (
        <>
            <HelmetConfig title="About Us" description="Learn more about Kampa.io and our mission to simplify local development." />

            <div className="bg-gray-100 min-h-screen">
                <Header/>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-12">
                    <section id="about" role="main" className="py-16">
                        <div className="container mx-auto px-6 lg:w-3/4 text-center">
                            <h2 className="gradient-text text-blue-600 text-xl font-semibold mb-4">
                                About Kampa
                            </h2>

                            <p className="text-4xl font-bold text-gray-900 mb-6">
                                Making Development Easier
                            </p>

                            <p className="text-lg text-gray-700 leading-relaxed mb-8">
                                Kampa helps developers set up, share, and manage local development environments
                                without the usual headaches. No more wasting hours fixing dependencies or
                                troubleshooting setups—your environment is ready when you are.
                            </p>
                        </div>
                    </section>

                    <section id="why" className="py-6">
                        <div className="container mx-auto px-6 lg:w-3/4 text-center">
                            <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                                Why We Built Kampa
                            </h3>
                            <p className="text-lg text-gray-700 leading-relaxed">
                                We were tired of wasting time on repetitive setup tasks every time we started a new
                                project or onboarded a teammate. Kampa was born from the need to simplify local
                                development, so you can focus on writing code instead of fixing environments.
                            </p>
                        </div>
                    </section>

                    <section id="features" className="py-6">
                        <div className="container mx-auto px-6 lg:w-3/4">
                            <h3 className="text-2xl font-semibold text-gray-900 text-center mb-6">
                                What Makes Kampa Different?
                            </h3>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div className="p-6 bg-white shadow-md rounded-lg">
                                    <h4 className="text-xl font-semibold text-blue-600">🚀 Fast & Simple Setup</h4>
                                    <p className="text-gray-700 mt-2">Get your local environment running in
                                        minutes.</p>
                                </div>
                                <div className="p-6 bg-white shadow-md rounded-lg">
                                    <h4 className="text-xl font-semibold text-blue-600">🛠 Consistent Across
                                        Teams</h4>
                                    <p className="text-gray-700 mt-2">No more "it works on my machine" problems.</p>
                                </div>
                                <div className="p-6 bg-white shadow-md rounded-lg">
                                    <h4 className="text-xl font-semibold text-blue-600">🔗 Share & Collaborate</h4>
                                    <p className="text-gray-700 mt-2">Easily share setups with teammates.</p>
                                </div>
                                <div className="p-6 bg-white shadow-md rounded-lg">
                                    <h4 className="text-xl font-semibold text-blue-600">🔓 No Vendor Lock-In</h4>
                                    <p className="text-gray-700 mt-2">Use your own stack, no restrictions.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="contact" className="py-6 text-center">
                        <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                            Get Involved
                        </h3>
                        <p className="text-lg text-gray-700 leading-relaxed mb-6">
                            Kampa is built for developers, by developers. Your feedback helps us improve. Have
                            suggestions or want to contribute? We’d love to hear from you!
                        </p>
                        <Link
                            to="/contact"
                            className="rounded-full px-4 py-1 bg-orange-500 opacity-100 text-white p-4">
                            Contact Us
                        </Link>
                    </section>
                </div>
            </div>

            <Footer/>

        </>
);
};

export default SiteAbout;