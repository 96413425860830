import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, Input, message, Switch} from 'antd';
import { Environment } from "./Types.ts";
import {useNavigate} from "react-router-dom";
import { BreadcrumbLink } from "../../Shared/Types.ts";
import useBreadcrumbStore from "../../Stores/useBreadcrumbStore.tsx";
import EnvironmentRepository from "./Repositories/EnvironmentRepository.ts";
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";
import {strictSanitizeSchema} from "../../Shared/SanitizeTags.ts";
import EnvironmentItemTabs from "./EnvironmentItemTabs.tsx";

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/local-environments',
        title: 'Local Environments',
    },
    {
        href: '/app/local-environments/create',
        title: 'Create',
    },
];

const CreateEnvironment: React.FC = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [messageApi, contextHolder] = message.useMessage();
    const [includeProxy, setIncludeProxy] = useState<boolean>(false)
    const itemRefs = useRef({});

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
    }, []);

    const onFinish = async (values: Environment) => {
        values['services'] = Object.values(values.services)
            .filter(Array.isArray)
            .flat();

        try {
            await EnvironmentRepository.save(values);
            messageApi.open({
                type: 'success',
                content: 'Environment created successfully',
            });
            setTimeout(() => navigate('/app/local-environments'), 2250);
        } catch (error) {
            message.error('Failed to create environment: ' + error.message);
        }
    };

    return (
        <>
            {contextHolder}
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Environments</h3>
                <div className="flex bg-white flex-col mt-8">
                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div
                            className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <Form.Provider>

                                <Form
                                    layout="vertical"
                                    form={form}
                                    onFinish={onFinish}
                                >
                                    <Form.Item
                                        label="Name:"
                                        name="name"
                                        rules={[{required: true, message: 'Please input the environment name!'}]}
                                    >
                                        <Input placeholder="Name"/>
                                    </Form.Item>

                                    <Form.Item label="Description:" name="description">
                                        <Input
                                            placeholder="Environment's short description"
                                            maxLength={150}
                                            showCount
                                        />
                                    </Form.Item>


                                    <Form.Item label="Setup & Usage Instructions" name="instructions">
                                        <MDEditor
                                            previewOptions={{
                                                skipHtml: true,
                                                rehypePlugins: [[rehypeSanitize, strictSanitizeSchema]],
                                            }}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Identifier:"
                                        name="identifier"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input the environment name!'
                                            },
                                            {
                                                pattern: /^[a-zA-Z-]+$/,
                                                message: "Only letters (a-z, A-Z) and hyphens (-) are allowed, without spaces",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="unique-identifier"/>
                                    </Form.Item>

                                    <Form.Item label="Include Reverse Proxy?" name="include_proxy">
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            onChange={() => setIncludeProxy(!includeProxy)}
                                        />
                                    </Form.Item>

                                    <EnvironmentItemTabs
                                        itemRefs={itemRefs}
                                        form={form}
                                        includeProxy={includeProxy}
                                        data={{}}
                                    />

                                    <Form.Item
                                        className={'mt-8'}>
                                        <Button type="primary" htmlType="submit">
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Form.Provider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateEnvironment;
