// KeysList.tsx
import React, {useEffect} from 'react';
import { Link } from "react-router-dom";
import TemplateRepository from './Repositories/TemplateRepository.ts';
import { Template } from './Types';
import GenericList from "../Shared/GenericList.tsx";
import {UserGroupIcon} from "@heroicons/react/24/outline";
import {BreadcrumbLink} from "../Shared/Types.ts";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import { Button } from 'antd';

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/templates',
        title: 'Template`s',
    },
];

const renderTemplateItem = (template: Template) => (
    <>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="flex items-center">
                <div className="ml-4">
                    <div className="text-sm font-medium leading-5 text-gray-900">{template.name}</div>
                </div>
            </div>
        </td>

        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="flex items-center">
                <div className="ml-4">
                    <div className="text-sm font-medium leading-5 text-gray-900">{template.description}</div>
                </div>
            </div>
        </td>

        <td className="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap border-b border-gray-200">

            <Link to={`/app/templates/${template.uuid}`}>
                <Button>Edit</Button>
            </Link>
        </td>
    </>
);

const TemplatesList: React.FC = () => {
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
    }, []);

    return (
        <>
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Templates</h3>

                <div className="mt-4">
                    <div className="flex flex-wrap -mx-6">
                        <div className=" w-full px-6 sm:w-1/2 xl:w-1/3">
                            <Link to={'/app/templates/create'}>
                                <div
                                    className="border-gray-200 border-dotted border flex items-center px-5 py-6 bg-white rounded-md shadow-sm">
                                    <div className="p-3 bg-orange-400 bg-opacity-75 rounded-full">
                                        <UserGroupIcon className="w-8 h-8 text-white"/>
                                    </div>

                                    <div className="mx-5">
                                        <h4 className="text-2xl font-semibold text-gray-700">New template</h4>
                                        <div className="text-gray-500">Create and collaborate with a new template</div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                    </div>
                </div>

                <div className="mt-8"></div>

                <div className="relative flex py-5 items-center">
                    <div className="flex-grow border-t border-gray-200"></div>
                </div>
            </div>

            <GenericList<Template>
                fetchData={TemplateRepository.findAll}
                renderItem={renderTemplateItem}
                columns={['Name', 'Description', '']}
            />
        </>
    );
}

export default TemplatesList;
