const arrow:string = '<span class="text-orange-400">➜</span>' //
const ok:string = '<span class="text-green-300">✔</span>' // ➜
// const repository:string = ' <span class="gradient-text">project</span><span class="text-blue-600"> git:(</span><span class="text-pink-600">main</span><span class="text-blue-600">)</span> <span class="text-amber-300">✗</span>'
const repository:string = ' <span class="gradient-text">project</span> <span class="text-amber-300">✗</span>'

export const typedExample:string[] = [
    arrow + repository + ' kampa auth login<br />' +
    'Please insert your token:<br />'+
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9<br />'+
    '<br />'+
    'Token successfuly set<br /><br />'+
    ''+
    arrow + repository + ' kampa env init <br />' +
    'Please insert your project: (ex: acme-team-x)<br />' +
    'Installing the project ... <br />' +
    ok + ' The environment is valid<br />' +
    ok + ' Checking folders structure<br />' +
    ok + ' Checking kampa structure<br />' +
    ok + ' Installing services<br />' +
    ok + ' Clonning repositories<br />' +
    ok + ' Creating the reverse proxy<br />' +
    ok + ' Docker compose file created<br /><br />' +
    '<span class="bg-emerald-600">Project installed succesfully</span><br />'+
    '<br />' +
    'This project created the following local domains<br />' +
    'https://kampa.local<br />' +
    'https://api.kampa.local<br /><br />' +
    '<span class="text-pink-600">Running docker compose up</span>'
]