import React from "react";
import Header from "./Components/Header.tsx";
import Footer from "./Components/Footer.tsx";

interface ChangelogEntry {
    version: string;
    date: string;
    changes: string[];
}

const changelogData: ChangelogEntry[] = [
    {
        version: "0.2.0",
        date: "2025-03-16",
        "changes": [
            "Enhanced logging functions! 🎉 Kampa Logs is now available — sync your local environment logs with the site dashboard to get help from your team more easily. Check it out: kampa logs! 🚀"
        ]
    },
    {
        version: "0.1.1",
        date: "2025-03-08",
        "changes": [
            "Enhanced readability of multiple commands.",
            "Updated 'kampa env start' to more efficiently check if the containers are running." +
            "Modified 'kampa env stop' to prevent the removal of images and containers.",
            "Added 'kampa env destroy', which now removes all images and containers associated with the environment."
        ]
    },
    {
        version: "0.1.0",
        date: "2025-03-01",
        changes: [
            "Added new command, kampa env rebuild which will call docker compose build --no-cache",
            "Kampa now supports custom cmd for environment services",
        ],
    },
    {
        version: "0.0.9",
        date: "2025-02-20",
        changes: [
            "When updating an environment, you will be prompted to run docker compose build --no-cache at the end.",
            "When updating an environment, if a service does not have a Git repository, its folder and contents will not be removed."
        ],
    },
    {
        version: "0.0.8",
        date: "2025-02-18",
        changes: [
            "Upon completing the kampa env update, it now automatically runs docker compose build --no-cache.",
        ],
    },
    {
        version: "0.0.7",
        date: "2025-02-15",
        changes: [
            "The kampa auth login command now supports passing the token directly, eliminating the need for manual input via prompt.",
            "Support to .env creation when creating the services.",
        ],
    },
    {
        version: "0.0.6",
        date: "2025-02-10",
        changes: [
            "Fixed the entrypoint creation and usage",
            "Enhanced the `kampa env update` command for better performance and reliability.",
        ],
    },
    {
        version: "0.0.5",
        date: "2025-02-10",
        changes: [
            "Improved message formatting during service initialization for better readability.",
        ],
    },
];


const SiteCliChangelog: React.FC = () => {
    return (
        <>
            <div className="bg-gray-100 min-h-screen">

                <Header/>

                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-12">
                    <section id="about" role="main" className="py-16">
                        <div className="container mx-auto px-6 lg:w-3/4">

                            <div className={'text-center mb-12'}>
                                <h2 className="gradient-text text-blue-600 text-xl font-semibold mb-4">
                                    Changelog
                                </h2>

                                <p className="text-4xl font-bold text-gray-900 mb-6">
                                    Stay up to date with all the latest changes and improvements in our CLI. 🚀
                                </p>
                            </div>

                            <div className="w-full mx-auto p-6 bg-gray-900 text-white rounded-2xl shadow-lg">
                                <div className="space-y-6">
                                    {changelogData.map((entry, index) => (
                                        <div key={index} className="bg-gray-800 p-4 rounded-lg">
                                            <div className="flex justify-between items-center mb-2">
                                                <h3 className="text-xl font-semibold">
                                                    Version {entry.version}
                                                </h3>
                                                <span className="text-sm text-gray-400">{entry.date}</span>
                                            </div>
                                            <ul className="list-disc list-inside space-y-1 text-gray-300">
                                                {entry.changes.map((change, idx) => (
                                                    <li key={idx}>{change}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default SiteCliChangelog;
