import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import DocumentationRepository from "./Repositories/DocumentationRepository.ts";
import {Button, Form, Input, message, Popconfirm} from "antd";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import documentationRepository from "./Repositories/DocumentationRepository.ts";
import {BreadcrumbLink} from "../Shared/Types.ts";
import {Documentation} from "./Types.ts";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/documentations',
        title: 'Documentations',
    },
    {
        href: '#',
        title: 'Edit',
    },
];

const EditDocumentation: React.FC = () => {
    const [form] = Form.useForm();
    const {documentationUuid} = useParams();
    const navigate = useNavigate();
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [messageApi, contextHolder] = message.useMessage();
    const [documentation, setDocumentation] = useState<Documentation>();
    const [loading, setLoading] = useState<boolean>(true);

    const onFinish = async (values: Documentation) => {
        try {
            if (documentationUuid != null) {
                values.uuid = documentationUuid;
            }
            await documentationRepository.update(values);

            messageApi.open({
                type: 'success',
                content: 'Documentation updated successfully',
            });

            setTimeout(() => navigate('/app/documentations'), 2250);
        } catch (error) {
            message.error('Failed to update documentation: ' + error.message);
        }
    };

    const confirm: (uuid) => void = async (uuid) => {
        try {
            await documentationRepository.destroy(uuid);

            messageApi.open({
                type: 'success',
                content: 'Documentation deleted successfully',
            });

            setTimeout(() => navigate('/app/documentations'), 2250);
        } catch (error) {
            message.error('Failed to delete documentation: ' + error.message);
        }
    };

    useEffect(() => {
        setBreadcrumb(breadcrumbs);

        const fetchDocumentation = async (documentationUuid: string | undefined) => {
            try {
                const response:Documentation = await DocumentationRepository.findByUuid(documentationUuid);
                form.setFieldsValue(response);
                setDocumentation(response)
            } catch (error) {
                console.error('Error fetching documentations:', error);
            }
        };

        fetchDocumentation(documentationUuid);
        setLoading(false)
     }, []);

    if (loading) {
        return <>Loading...</>
    }

    return (
        <>
            {contextHolder}
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Documentations</h3>

                <div className="flex bg-white flex-col mt-8">

                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">


                        <div
                            className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <h3 className="text-xl font-medium text-gray-700 mb-7">Update</h3>

                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label="Name:"
                                    name="name"
                                    rules={[{required: true, message: 'Please input the documentation name!'}]}
                                >
                                    <Input placeholder="Name"/>
                                </Form.Item>

                                <Form.Item
                                    label="Path:"
                                    name="path"
                                    rules={[{required: true, message: 'Please input the documentation path!'}]}
                                >
                                    <Input placeholder="Path"/>
                                </Form.Item>

                                <Form.Item
                                    label="Content:"
                                    name="content"
                                    rules={[{ required: true, message: 'Please input the documentation content!' }]}
                                >
                                    <MDEditor
                                        previewOptions={{
                                            skipHtml: true,
                                            rehypePlugins: [[rehypeSanitize]],
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">Update</Button>
                                </Form.Item>
                            </Form>

                        </div>
                    </div>
                </div>

                <div className="flex bg-white flex-col mt-8">

                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">

                        <div
                            className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <h3 className="text-xl font-medium text-gray-700 mb-4">Delete</h3>

                            <p className="text-sm text-gray-700 mb-4">
                                Deleting this item will remove all associated data and cannot be reversed.
                                Please ensure you have backed up any important information before proceeding.
                            </p>

                            <Popconfirm
                                title="Delete"
                                description="Are you sure to delete this item?"
                                onConfirm={() => confirm(documentation.uuid)}
                                okText="Yes"
                                cancelText="No"
                                className={''}
                            >
                                <Button danger>Yes, I'll permanently delete it</Button>
                            </Popconfirm>

                        </div>
                    </div>
                </div>


            </div>

        </>
    );
};

export default EditDocumentation;
