import { useState } from "react";
import { Form, Input, Button, message } from "antd";
import ProfileRepository from "./Repositories/ProfileRepository.ts";

const ProfilePassword = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const response = await ProfileRepository.updatePassword(values);

            if (response) {
                message.success("Password updated successfully");
            }
            // navigate(`/app/dashboard`);
        } catch (error) {
            message.error("Failed to update password");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <h2 className="text-xl font-semibold mb-6">Update Password</h2>

            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                className="space-y-4"
            >
                <Form.Item
                    name="current_password"
                    label="Current Password"
                    rules={[{ required: true, message: "Please enter your current password" }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="new_password"
                    label="New Password"
                    rules={[{ required: true, message: "Please enter a new password" }]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="password_confirmation"
                    label="Confirm New Password"
                    dependencies={["new_password"]}
                    hasFeedback
                    rules={[
                        { required: true, message: "Please confirm your new password" },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("new_password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject("The passwords do not match");
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>

        </>
    );
};

export default ProfilePassword;
