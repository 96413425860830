import React, { useEffect, useState } from 'react';
import {
    Form,
    Row,
    Col,
    Divider,
    Select,
    Button,
    Input,
    InputNumber,
    Tabs,
    Popconfirm,
    Tooltip, FormInstance, Spin
} from 'antd';
import ServiceSelectComponent from '../../Shared/ServiceSelectComponent.tsx';
import ServiceRepository from '../Services/Repositories/ServiceRepository.ts';
import ServiceVersionRepository from '../Services/Repositories/ServiceVersionRepository.ts';
import { DeleteOutlined } from '@ant-design/icons';
import './environmentItem.css';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import CodeTextAreaComponent from "../../Shared/CodeTextAreaComponent.tsx";

type Ports = {
    [key: number]: number; // or [key: string]: number if keys might be strings
};

type Variables = {
    [key: string]: string
}

type Volumes = {
    [key: string]: string
}

const isToRenderProxy = (includeProxy, serviceType) => {
    if (!includeProxy) {
        return false;
    }

    const allowedServiceTypes = ["application", "developer_tools", "message_broker", "monitoring"];
    return allowedServiceTypes.includes(serviceType);
};

const isToShowEnvFile = (serviceType) => {
    return serviceType === 'application' || serviceType === 'worker';
};

type EnvironmentItemProps = {
    uniqueKey?: string | number;
    tabType?: string;
    name: number;
    remove: any;
    form?: FormInstance,
    data?: any,
    includeProxy?: boolean;
};

const EnvironmentItem: React.FC<EnvironmentItemProps> = ({ uniqueKey, tabType, name, remove, form, data, includeProxy }) => {
    const [serviceOptions, setServiceOptions] = useState([]);
    const [serviceVersions, setServiceVersions] = useState([]);
    const [showCustomPorts, setShowCustomPorts] = useState<boolean>(false);
    const [showCustomVariables, setShowCustomVariables] = useState<boolean>(false);
    const [showCustomVolumes, setShowCustomVolumes] = useState<boolean>(false);
    const [ports, setPorts] = useState<Ports>({}); // Initialize with an empty object
    const [variables, setVariables] = useState<Variables>({}); // Initialize with an empty object
    const [volumes, setVolumes] = useState<Volumes>({}); // Initialize with an empty object
    const [code, setCode] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [customCmdPlaceholder, setCustomCmdPlaceholder] = useState<string>('');

    const mapper = (data: any[]) => {
        return data.map((service) => ({
            label: service.name,
            value: service.uuid,
            ...service,
        }));
    };

    const handleRemoveService = (name) => {
        const servicePath = `services.${tabType}.${name}`;

        form.resetFields([
            `${servicePath}.ports`,
            `${servicePath}.variables`,
            `${servicePath}.volumes`,
        ]);

        remove(name);
    };

    const handleServiceChange = async (value: string) => {
        try {
            const serviceVersionResponse = await ServiceVersionRepository.findAll(value);
            const mappedVersions = mapper(serviceVersionResponse);
            setServiceVersions(mappedVersions);
        } catch (error) {
            console.error(error);
        }
    };

    const handleServiceVersionChange = (value: string) => {
        try {
            const version = serviceVersions.find((item) => item.value === value);

            if (version) {
                const portFields: Ports = {};
                version.ports?.forEach((port) => {
                    portFields[port.number] = port.number;
                });

                setPorts(portFields);

                const variableFields: Record<string, string> = {};

                // Populate variableFields from API response
                version.variables?.forEach((variable) => {
                    variableFields[variable.name] = variable.default;
                });

                form.setFields(
                    Object.keys(variableFields).map((key) => ({
                        name: [`services`, tabType, name, 'variables', key],
                        value: variableFields[key],
                    }))
                );

                // setVariables((prev) => ({ ...prev, ...variableFields }));
                setVariables(variableFields);

                // form.setFieldsValue({
                //     [`services.${name}.variables`]: variableFields,
                // });

                // Set Volumes
                const volumeFields = {};
                version.volumes?.forEach((volume) => {
                    volumeFields[volume.container_folder] = volume.local_folder;
                });

                form.setFields(
                    Object.keys(volumeFields).map((key) => ({
                        name: [`services`, tabType, name, 'volumes', key],
                        value: volumeFields[key],
                    }))
                );

                setVolumes(volumeFields)

                setCustomCmdPlaceholder(version.custom_cmd);

                /*form.setFields([
                    {
                        name: ["services", tabType, name, "custom_cmd"],
                        value: version.custom_cmd,
                    },
                ]);*/

            }
        } catch (error) {
            console.error('Error setting service version:', error);
        }
    };

    const renderPorts = () => {
        return (
            <>
                <Divider orientation={"left"} orientationMargin={'15px'} className="small-divider">Default Service Ports</Divider>

                {ports && Object.keys(ports).length > 0 ? (
                    Object.keys(ports).map((port) => (
                        <Form.Item
                            key={`static-port-${port}`}
                            label={
                                <Tooltip title="Specify the port on your host machine. This port will map to the container's port.">
                                    <span>Host Port Mapping (Container Port: {port})</span>
                                </Tooltip>
                            }
                            name={[name, 'ports', port]}
                            rules={[{ required: true, message: 'Port number is required. Please enter a valid port.' }]}
                        >
                            <InputNumber
                                min={0}
                                style={{ minWidth: 120 }}
                                placeholder={`Enter host port for container port ${port}`}
                            />
                        </Form.Item>
                    ))
                ) : (
                    'No ports'
                )}


                { showCustomPorts ? (
                    <>
                        <Divider orientation={"left"} orientationMargin={'15px'}  className="small-divider">Environment Custom Ports</Divider>

                        <Button
                            type="dashed"
                            block onClick={() => setShowCustomPorts(false)} className={'mb-3.5'}>Hide custom ports</Button>

                        <Form.List name={[name, 'custom_ports']}>
                            {(fields, { add, remove }) => (
                                <>
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add({ from: null, to: null })}
                                            block
                                            icon={<PlusOutlined />}
                                        >
                                            Add Custom Port
                                        </Button>
                                    </Form.Item>

                                    {fields.map(({ key, name }) => (
                                        <Row key={key} gutter={16} align="middle" style={{ marginBottom: '16px' }}>
                                            {/* From Field */}
                                            <Col span={10}>
                                                <Form.Item
                                                    label="From:"
                                                    name={[name, 'from']}
                                                    rules={[{ required: true, message: 'Please input this field' }]}
                                                >
                                                    <InputNumber placeholder="Port Number" min={0} />
                                                </Form.Item>
                                            </Col>

                                            {/* To Field */}
                                            <Col span={10}>
                                                <Form.Item
                                                    label="To:"
                                                    name={[name, 'to']}
                                                    rules={[{ required: true, message: 'Please input this field' }]}
                                                >
                                                    <InputNumber placeholder="Port Number" min={0} />
                                                </Form.Item>
                                            </Col>

                                            {/* Remove Button */}
                                            <Col span={4} style={{ textAlign: 'center' }}>
                                                <MinusCircleOutlined
                                                    onClick={() => remove(name)}
                                                    style={{
                                                        color: 'red',
                                                        fontSize: '18px',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                                </>
                            )}
                        </Form.List>
                    </>
                ) : (<Button type={"dashed"} block onClick={() => setShowCustomPorts(true)}>Show custom ports</Button>)}
            </>
        );
    };

    const renderVariables = () => {
        return (
            <>
                <Divider orientation="left" orientationMargin="15px" className="small-divider">
                    Default Service Variables
                </Divider>

                {variables && Object.keys(variables).length > 0 ? (
                    Object.keys(variables).map((variable) => (
                        <Form.Item
                            key={variable}
                            label={variable}
                            name={[name, 'variables', variable]}
                            // initialValue={variables[variable]}
                            rules={[{ required: true, message: `Please input the variable: ${variable}` }]}
                        >
                                <Input placeholder={`Default: ${variables[variable]}`} />
                        </Form.Item>
                    ))
                ) : (
                    <p>No variables configured</p>
                )}

                { showCustomVariables ? (
                    <>
                        <Divider orientation={"left"} orientationMargin={'15px'}  className="small-divider">Environment Custom Variables</Divider>

                        <Button
                            type="dashed"
                            block onClick={() => setShowCustomVariables(false)} className={'mb-3.5'}>Hide custom variables</Button>

                        <Form.List name={[name, 'custom_variables']}>
                            {(fields, { add, remove }) => (
                                <>
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add({ variable: null, value: null })}
                                            block
                                            icon={<PlusOutlined />}
                                        >
                                            Add Custom Variable
                                        </Button>
                                    </Form.Item>

                                    {fields.map(({ key, name }) => (
                                        <Row key={key} gutter={16} align="middle" style={{ marginBottom: '16px' }}>
                                            {/* Variable Field */}
                                            <Col span={10}>
                                                <Form.Item
                                                    label="Variable:"
                                                    name={[name, 'variable']}
                                                    rules={[{ required: true, message: 'Please input this field' }]}
                                                >
                                                    <Input placeholder="Input the variable name" />
                                                </Form.Item>
                                            </Col>

                                            {/* Value Field */}
                                            <Col span={10}>
                                                <Form.Item
                                                    label="Value:"
                                                    name={[name, 'value']}
                                                    rules={[{ required: true, message: 'Please input this field' }]}
                                                >
                                                    <Input placeholder="Input the variable value" />
                                                </Form.Item>
                                            </Col>

                                            {/* Remove Button */}
                                            <Col span={4} style={{ textAlign: 'center' }}>
                                                <MinusCircleOutlined
                                                    onClick={() => remove(name)}
                                                    style={{
                                                        color: 'red',
                                                        fontSize: '18px',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                                </>
                            )}

                        </Form.List>
                    </>
                ) : (<Button type={"dashed"} block onClick={() => setShowCustomVariables(true)}>Show custom variables</Button>)}
            </>
        );
    }

    const renderVolumes = () => {
        return (
            <>
                <Divider orientation="left" orientationMargin="15px" className="small-divider">
                    Default Service Volumes
                </Divider>

                {volumes && Object.keys(volumes).length > 0 ? (
                    Object.keys(volumes).map((volume) => (
                        <Form.Item
                            key={volume}
                            label={
                                <Tooltip title="Specify the local path on your host machine that will be mapped to this container path.">
                                    <span>Volume Mapping (Container Path: {volume}): Use a dot (.) to refer to the service/repository root.</span>
                                </Tooltip>
                            }
                            name={[name, 'volumes', volume]}
                            rules={[{ required: true, message: `Please specify the local path for volume: ${volume}` }]}
                        >
                            <Input placeholder={`Enter local path to map to container path: ${volume}`} />
                        </Form.Item>
                    ))
                ) : (
                    <p>No volumes configured</p>
                )}

                { showCustomVolumes ? (
                    <>
                        <Divider orientation={"left"} orientationMargin={'15px'}  className="small-divider">Environment Custom Volumes</Divider>

                        <Button
                            type="dashed"
                            block onClick={() => setShowCustomVolumes(false)} className={'mb-3.5'}>Hide custom volumes</Button>

                        <Form.List name={[name, 'custom_volumes']}>
                            {(fields, {add, remove}) => (
                                <>
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add({local: null, container: null})}
                                            block
                                            icon={<PlusOutlined/>}
                                        >
                                            Add Custom Volume
                                        </Button>
                                    </Form.Item>

                                    {fields.map(({ key, name }) => (
                                        <Row key={key} gutter={16} align="middle" style={{ marginBottom: '16px' }}>
                                            {/* Local Folder Field */}
                                            <Col span={10}>
                                                <Form.Item
                                                    label="Local folder:"
                                                    name={[name, 'local']}
                                                    rules={[{ required: true, message: 'Please input this field' }]}
                                                >
                                                    <Input placeholder="Input the local folder" />
                                                </Form.Item>
                                            </Col>

                                            {/* Container Folder Field */}
                                            <Col span={10}>
                                                <Form.Item
                                                    label="Container folder:"
                                                    name={[name, 'container']}
                                                    rules={[{ required: true, message: 'Please input this field' }]}
                                                >
                                                    <Input placeholder="Input the container folder" />
                                                </Form.Item>
                                            </Col>

                                            {/* Remove Button */}
                                            <Col span={4} style={{ textAlign: 'center' }}>
                                                <MinusCircleOutlined
                                                    onClick={() => remove(name)}
                                                    style={{
                                                        color: 'red',
                                                        fontSize: '18px',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    ))}

                                </>
                            )}
                        </Form.List>
                    </>
                ) : (<Button type={"dashed"} block onClick={() => setShowCustomVolumes(true)}>Show custom volumes</Button>)}
            </>
        );
    };

    const renderProxy = () => {
        return <>
            <Form.List name={[name, 'proxy']}>
                {(fields, {add, remove}) => (
                    <>
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add({localAddress: null, containerPort: null})}
                                block
                                icon={<PlusOutlined/>}
                            >
                                Add Custom Domain
                            </Button>
                        </Form.Item>

                        {fields.map(({ key, name }) => (
                            <Row key={key} gutter={16} align="middle" style={{ marginBottom: '16px' }}>
                                <Col span={10}>
                                    <Form.Item
                                        label="Local address:"
                                        name={[name, 'local_address']}
                                        rules={[{ required: true, message: 'Please input this field' }]}
                                    >
                                        <Input
                                            prefix={<>https://</>}
                                            placeholder="custom.site.local"
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={10}>
                                    <Form.Item
                                        label="Container Port:"
                                        name={[name, 'container_port']}
                                        rules={[{ required: true, message: 'Please input this field' }]}
                                    >
                                        <Select
                                            placeholder="Enter container port"
                                            options={
                                                ports && Object.keys(ports).length > 0
                                                    ? Object.keys(ports).map((key, index) => ({
                                                        value: key,
                                                        key: index,
                                                    }))
                                                    : []
                                            }
                                        />

                                    </Form.Item>
                                </Col>

                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                        style={{
                                            color: 'red',
                                            fontSize: '18px',
                                            cursor: 'pointer',
                                        }}
                                    />
                                </Col>
                            </Row>
                        ))}

                    </>
                )}
            </Form.List>

        </>
    }

    const renderEnvFile = () => {
        return <>
            <p className={'mb-4'}>
                This file will be created insider the root of your service/repository
            </p>

            <CodeTextAreaComponent
                label={'.env'}
                name={[name, 'env']}
                code={code}
                setCode={setCode}
                language={'shell'}
            />
        </>
    }

    const handleServiceType = async () => {
        try {
            const servicesResponse = await ServiceRepository.findByServiceType(tabType);
            const organizationServices = mapper(servicesResponse);

            const officialServices = await ServiceRepository.findOfficialServices(tabType);
            const officialOptions = mapper(officialServices);

            setServiceOptions([
                {
                    label: 'Organization Services',
                    options: organizationServices,
                },
                {
                    label: 'Official Services',
                    options: officialOptions,
                },
            ]);
        } catch (error) {
            console.error('Error fetching services:', error);
        }

        setLoading(false);
    }

    useEffect(() => {
        handleServiceType();

        if (data && data.services && data.services[tabType]) {
            const service = data.services[tabType][uniqueKey];

            if (service) {
                handleServiceChange(service.service_uuid);
                handleServiceVersionChange(service.service_version_uuid);

                if (service.ports) {
                    setPorts(service.ports);
                }

                if (service.variables) {
                    setVariables(service.variables);
                }

                if (service.volumes) {
                    setVolumes(service.volumes);
                }

                if (service.env) {
                    setCode(service.env);
                }

                setServiceOptions([]);
                setServiceVersions([]);
            }
        }
    }, [data, uniqueKey]);

    const items = [
        {
            key: '1',
            label: 'Host & Port Settings',
            children: renderPorts(),
            forceRender: true
        },
        {
            key: '2',
            label: 'Service variables',
            children: renderVariables(),
            forceRender: true
        },
        {
            key: '3',
            label: 'Volumes',
            children: renderVolumes(),
            forceRender: true
        },
        isToRenderProxy(includeProxy, tabType) && {
            key: '4',
            label: 'Reverse Proxy',
            children: renderProxy(),
            forceRender: true
        },
        isToShowEnvFile(tabType) && {
            key: '5',
            label: '.env project file',
            children: renderEnvFile(),
            forceRender: true
        }
    ].filter(Boolean);

    if (loading) {
        return <>
            <Spin size="small" />
            Loading</>
    }

    return (
        <div key={uniqueKey} className="mt-10">
            <Form.Item
                name={[name, 'service_type']}
                initialValue={tabType} hidden>
                <Input />
            </Form.Item>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={6} xs={24} md={6}>
                    <ServiceSelectComponent
                        name={[name, 'service_uuid']}
                        onChange={handleServiceChange}
                        options={serviceOptions}
                    />
                </Col>

                <Col span={6} xs={24} md={6}>
                    <Form.Item label="Version:" name={[name, 'service_version_uuid']}>
                        <Select
                            placeholder="Version"
                            onChange={handleServiceVersionChange}
                            options={serviceVersions}
                        />
                    </Form.Item>
                </Col>

                <Col span={6} xs={24} md={6}>
                    <Form.Item
                        label="Name"
                        name={[name, 'name']}
                        rules={[
                            { required: true, message: 'Please input the name' },
                            {
                                pattern: /^[a-z0-9.-]+$/,
                                message: "Only letters (a-z), numbers (0-9), hyphens (-), and dots (.) are allowed, without spaces",
                            },
                        ]}
                    >
                        <Input placeholder="service1" />
                    </Form.Item>
                </Col>

                {tabType === 'application' && (
                    <>
                        <Col span={6} xs={24} md={6}>
                            <Form.Item label="Git Repository" name={[name, 'git_repository']}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </>
                )}
            </Row>


            {tabType === 'application' && (
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={32} xs={24} md={32}>
                        <Form.Item
                            label={<>
                                <Tooltip title={'Define a command to override the container’s default entry point.'}>
                                    Custom Entry point (Replace Default CMD)
                                </Tooltip>
                            </>}
                            name={[name, 'custom_cmd']}
                        >
                            <Input
                                showCount maxLength={200} placeholder={customCmdPlaceholder} />
                        </Form.Item>
                    </Col>
                </Row>
                ) }

            <Tabs defaultActiveKey="1" items={items} />

            <Popconfirm
                title="Delete"
                description="Are you sure to delete this service?"
                onConfirm={() => {
                    handleRemoveService(name)
                }}
                okText="Yes"
                cancelText="No"
            >
                <Button
                    icon={<DeleteOutlined />}
                    className="w-100 mt-10 px-2 py-1 rounded-lg mb-8 bg-slate-50 hover:bg-gray-400 text-center"
                >
                    Remove this service
                </Button>
            </Popconfirm>

            <Divider />
        </div>
    );
};

export default EnvironmentItem;
