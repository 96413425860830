import React, {useEffect, useState} from 'react';
import {ServerStackIcon, SquaresPlusIcon} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import {UserCircleIcon} from "@heroicons/react/24/solid";
import OrganizationRepository from "../Organizations/Repositories/OrganizationRepository.ts";
import {FeatureUsage} from "../Organizations/Types.ts";
import {getFeature} from "../Organizations/FeaturesHelper.tsx";
import GenericList from "../Shared/GenericList.tsx";
import {Environment} from "../LocalEnvironments/Environments/Types.ts";
import EnvironmentRepository from "../LocalEnvironments/Environments/Repositories/EnvironmentRepository.ts";
import {renderEnvironmentItem} from "../LocalEnvironments/Environments/EnvironmentsList.tsx";

const Dashboard: React.FC = () => {
    const clearBreadcrumb = useBreadcrumbStore((state) => state.clearBreadcrumb);
    const [maxUsers, setMaxUsers] = useState<FeatureUsage | null>(null);
    const [maxEnvironments, setMaxEnvironments] = useState<FeatureUsage | null>(null);

    useEffect(() => {
        clearBreadcrumb();

        const fetchFeaturesUsage = async () => {
            try {
                const response = await OrganizationRepository.findFeaturesUsage()

                setMaxUsers(getFeature(response, "max_users") ?? null);
                setMaxEnvironments(getFeature(response, "max_environments") ?? null);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchFeaturesUsage();
    }, []);

    return (
        <>
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Dashboard</h3>

                <div className="mt-4">
                    <div className="flex flex-wrap -mx-6">

                        <div className="w-full px-6 sm:w-1/2 xl:w-1/3">
                            <Link to={'/app/environments/create'}>
                                <div
                                    className="border-gray-200 flex items-center px-5 py-6 bg-white rounded-md shadow-sm">
                                    <div className="p-3 bg-orange-400 bg-opacity-75 rounded-full">
                                        <SquaresPlusIcon className="w-8 h-8 text-white"/>
                                    </div>

                                    <div className="mx-5">
                                        <h4 className="text-2xl font-semibold text-gray-700">New environment</h4>
                                        <div className="text-gray-500">Start a new project</div>
                                    </div>
                                </div>
                            </Link>
                        </div>


                        <div className="w-full px-6 mt-6 sm:w-1/2 xl:w-1/3 sm:mt-0">
                            <div className="flex items-center px-5 py-6 bg-white rounded-md shadow-sm">
                                <div className="p-3 bg-orange-400 bg-opacity-75 rounded-full">
                                    <ServerStackIcon className="w-8 h-8 text-white"/>
                                </div>

                                <div className="mx-5">
                                    <h4 className="text-2xl font-semibold text-gray-700">{maxEnvironments?.used} of {maxEnvironments?.available}</h4>
                                    <div className="text-gray-500">environment slots are in use.</div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full px-6 mt-6 sm:w-1/2 xl:w-1/3 sm:mt-0">
                            <div className="flex items-center px-5 py-6 bg-white rounded-md shadow-sm">
                                <div className="p-3 bg-orange-400 bg-opacity-75 rounded-full">
                                    <UserCircleIcon className="w-8 h-8 text-white"/>
                                </div>

                                <div className="mx-5">
                                    <h4 className="text-2xl font-semibold text-gray-700">{maxUsers?.used} of {maxUsers?.available}</h4>
                                    <div className="text-gray-500">user slots are in use.</div>
                                </div>
                            </div>
                        </div>


                        {/*<div className="w-full px-6 mt-6 sm:w-1/2 xl:w-1/3 xl:mt-0">*/}
                        {/*    <div className="flex items-center px-5 py-6 bg-white rounded-md shadow-sm">*/}
                        {/*        <div className="p-3 bg-pink-600 bg-opacity-75 rounded-full">*/}
                        {/*            <svg className="w-8 h-8 text-white" viewBox="0 0 28 28" fill="none"*/}
                        {/*                 xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                <path*/}
                        {/*                    d="M6.99998 11.2H21L22.4 23.8H5.59998L6.99998 11.2Z"*/}
                        {/*                    fill="currentColor"*/}
                        {/*                    stroke="currentColor"*/}
                        {/*                    strokeWidth="2"*/}
                        {/*                    strokeLinejoin="round"*/}
                        {/*                ></path>*/}
                        {/*                <path*/}
                        {/*                    d="M9.79999 8.4C9.79999 6.08041 11.6804 4.2 14 4.2C16.3196 4.2 18.2 6.08041 18.2 8.4V12.6C18.2 14.9197 16.3196 16.8 14 16.8C11.6804 16.8 9.79999 14.9197 9.79999 12.6V8.4Z"*/}
                        {/*                    stroke="currentColor"*/}
                        {/*                    strokeWidth="2"*/}
                        {/*                ></path>*/}
                        {/*            </svg>*/}
                        {/*        </div>*/}

                        {/*        <div className="mx-5">*/}
                        {/*            <h4 className="text-2xl font-semibold text-gray-700">215,542</h4>*/}
                        {/*            <div className="text-gray-500">Available Products</div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </div>
                </div>

                <div className="mt-8"></div>

                <div className="relative flex py-5 items-center">
                    <div className="flex-grow border-t border-gray-200"></div>
                </div>

            </div>

            <GenericList<Environment>
                fetchData={EnvironmentRepository.findAll}
                renderItem={renderEnvironmentItem}
                columns={['Name', 'Identifier', 'Description', 'Status', '']}
            />

        </>
    );
};

export default Dashboard;
