import axiosInstance from "../../../axios.ts";
import { PaginatedResponse } from '../../Shared/Types';
import {Command} from "../Types.ts";
import FiltersManager from "../../Shared/ListWIthFilters/FiltersManage.ts";

const findAll = async (filters: FiltersManager): Promise<PaginatedResponse<Command>> => {
    try {
        const response = await axiosInstance.get(`/commands?page=${filters.get("page")}&has_pagination=${filters.get("hasPagination")}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'List failed');
    }
};

const findByUuid = async (uuid: string | undefined) => {
    try {
        const response = await axiosInstance.get(`/commands/${uuid}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Fetch failed');
    }
}

const save = async (command: Command): Promise<Command> => {
    try {
        const response = await axiosInstance.post(`/commands`, command);
        return response.data;
    } catch (error) {
        console.log(error)
        throw new Error(error.response?.data?.message || 'Save failed');
    }
};

const update = async (command: Command): Promise<boolean> => {
    await axiosInstance.patch(`/commands/${command.uuid}`, command);
    return true;
};

const destroy = async (uuid: string): Promise<boolean> => {
    await axiosInstance.delete(`/commands/${uuid}`);
    return true;
};

export default {
    save,
    findAll,
    findByUuid,
    update,
    destroy
};