import ReactDOM from 'react-dom/client'
import './index.css'
import {Suspense} from "react";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import Login from "./Auth/Login.tsx";
import Home from "./Site/Home.tsx";
import SignUp from "./Auth/SignUp.tsx";
import NotFound from "./Errors/NotFound.tsx";
import ProtectedRoute from "./Auth/ProtectedRoute.tsx";
import Dashboard from "./App/Dashboard/Dashboard.tsx";
import AppLayout from "./App/AppLayout.tsx";
import Logout from "./Auth/Logout.tsx";
import ServicesList from "./App/LocalEnvironments/Services/ServicesList.tsx";
import TeamsList from "./App/Teams/TeamsList.tsx";
import CreateTeam from "./App/Teams/CreateTeam.tsx";
import EditTeam from "./App/Teams/EditTeam.tsx";
import { App as AntApp, ConfigProvider} from "antd";
import CreateService from "./App/LocalEnvironments/Services/CreateService.tsx";
import EditService from "./App/LocalEnvironments/Services/EditService.tsx";
import EditServiceVersion from "./App/LocalEnvironments/Services/EditServiceVersion.tsx";
import CreateServiceVersion from "./App/LocalEnvironments/Services/CreateServiceVersion.tsx";
import UsersList from "./App/Users/UsersList.tsx";
import ForgotPassword from "./Auth/ForgotPassword.tsx";
import ResetPassword from "./Auth/ResetPassword.tsx";
import CreateUserInvite from "./App/Users/CreateUserInvite.tsx";
import SignUpFromInvite from "./Auth/SignUpFromInvite.tsx";
import EditUserInvite from "./App/Users/EditUserInvite.tsx";
import EditUser from "./App/Users/EditUser.tsx";
import EnvironmentsList from "./App/LocalEnvironments/Environments/EnvironmentsList.tsx";
import CreateEnvironment from "./App/LocalEnvironments/Environments/CreateEnvironment.tsx";
import EditEnvironment from "./App/LocalEnvironments/Environments/EditEnvironment.tsx";
import Profile from "./App/Profile/Profile.tsx";
import KeyList from "./App/Keys/KeysList.tsx";
import CreateKey from "./App/Keys/CreateKey.tsx";
import EditKey from "./App/Keys/EditKey.tsx";
import ScrollToTop from './Components/ScrollToTop.tsx';
import SiteContact from "./Site/SiteContact.tsx";
import SiteAbout from "./Site/SiteAbout.tsx";
import Privacy from "./Site/Policies/Privacy.tsx";
import SitePoliciesLayout from "./Site/Policies/SitePoliciesLayout.tsx";
import TermsAndConditions from "./Site/Policies/TermsAndConditions.tsx";
import Cookies from "./Site/Policies/Cookies.tsx";
import Installation from "./App/Client/Installation.tsx";
import Billing from "./App/Subscriptions/Billing.tsx";
import SiteCliChangelog from "./Site/SiteCliChangelog.tsx";
import {HelmetProvider} from "react-helmet-async";
import DocumentationsList from "./App/DocumentationManager/DocumentationsList.tsx";
import CreateDocumentation from "./App/DocumentationManager/CreateDocumentation.tsx";
import EditDocumentation from "./App/DocumentationManager/EditDocumentation.tsx";
import TemplatesList from "./App/TemplatesManager/TemplatesList.tsx";
import CreateTemplate from "./App/TemplatesManager/CreateTemplate.tsx";
import EditTemplate from "./App/TemplatesManager/EditTemplate.tsx";
import EnvironmentsTemplatesList from "./App/LocalEnvironments/Environments/EnvironmentsTemplatesList.tsx";
import CreateEnvironmentFromTemplate from "./App/LocalEnvironments/Environments/CreateEnvironmentFromTemplate.tsx";

import DocumentationLayout from "./Site/DocumentationLayout.tsx";
import DocumentationIntroduction from "./Site/Docs/DocumentationIntroduction.tsx";
import DocumentationInstallation from "./Site/Docs/DocumentationInstallation.tsx";
import DocumentationFromBackend from "./Site/Docs/DocumentationFromBackend.tsx";
import DocumentationBoostRepoUpgradesWithKampa from "./Site/Docs/DocumentationBoostRepoUpgradesWithKampa.tsx";
import DocumentationPrerequisites from "./Site/Docs/DocumentationPrerequisites.tsx";
import DocumentationBasicConcepts from "./Site/Docs/DocumentationBasicConcepts.tsx";
import DocumentQuickStartGuide from "./Site/Docs/DocumentationQuickStartGuide.tsx";
import {ErrorBoundary} from "./Errors/ErrorBoundary.tsx";
import NotificationsList from "./App/Notifications/NotificationsList.tsx";
import Temporary from "./Temporary.tsx";
import LogsList from "./App/LogsModule/LogsList";
import LogViewer from "./App/LogsModule/LogViewer";
import CommandsList from "./App/Commands/CommandsList.tsx";
import CreateCommand from "./App/Commands/CreateCommand.tsx";
import EditCommand from "./App/Commands/EditCommand.tsx";
import DeploymentsList from "./App/Deployments/DeploymentsList.tsx";

// const DocumentationLayout = lazy(() => import("./Site/DocumentationLayout.tsx").then(m => ({ default: m.default as React.FC })));
// const DocumentationInstallation = lazy(() => import("./Site/Docs/DocumentationInstallation.tsx").then(m => ({ default: m.default as React.FC })));
// const DocumentationIntroduction = lazy(() => import("./Site/Docs/DocumentationIntroduction.tsx").then(m => ({ default: m.default as React.FC })));
// const DocumentationPrerequisites = lazy(() => import("./Site/Docs/DocumentationPrerequisites.tsx").then(m => ({ default: m.default as React.FC })));
// const DocumentationBasicConcepts = lazy(() => import("./Site/Docs/DocumentationBasicConcepts.tsx").then(m => ({ default: m.default as React.FC })));
// const DocumentQuickStartGuide = lazy(() => import("./Site/Docs/DocumentationQuickStartGuide.tsx").then(m => ({ default: m.default as React.FC })));
// const DocumentationFromBackend = lazy(() => import("./Site/Docs/DocumentationFromBackend.tsx").then(m => ({ default: m.default as React.FC })));
// const DocumentationBoostRepoUpgradesWithKampa = lazy(() => import("./Site/Docs/DocumentationBoostRepoUpgradesWithKampa.tsx").then(m => ({ default: m.default as React.FC })));

Sentry.init({
    dsn: "https://9bb0d1c32e2b776ac9bb94e1c17cd337@o4508761879085056.ingest.de.sentry.io/4508961031782480"
});

const router = createBrowserRouter([
    {
        path: '/',
        element: <ScrollToTop/>,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: '/issue-tester',
                element: <Temporary />,
            },
            {
                path: '/contact',
                element: <SiteContact />,
            },
            {
                path: '/login',
                element: <Login />,
            },
            {
                path: '/logout',
                element: <Logout />,
            },
            {
                path: '/sign-up',
                element: <SignUp />,
            },
            {
                path: '/invite/:uuid',
                element: <SignUpFromInvite />,
            },
            {
                path: '/forgot-password',
                element: <ForgotPassword />,
            },
            {
                path: '/reset-password/:token',
                element: <ResetPassword />,
            },
            {
                path: '/about',
                element: <SiteAbout />
            },
            {
                path: '/cli-changelog',
                element: <SiteCliChangelog />
            },
            {
                path: '/policies',
                element: <SitePoliciesLayout />,
                children: [
                    {
                        path: 'privacy',
                        element: <Privacy  />
                    },
                    {
                        path: 'terms-and-conditions',
                        element: <TermsAndConditions  />
                    },
                    {
                        path: 'cookies',
                        element: <Cookies  />
                    },
                ]
            },
            {
                path: '/docs',
                element: <DocumentationLayout />,
                children: [
                    {
                        path: 'introduction',
                        element: <DocumentationIntroduction />,
                    },
                    {
                        path: 'installation',
                        element: <DocumentationInstallation />,
                    },
                    {
                        path: 'd/:path',
                        element: <DocumentationFromBackend />
                    },
                    {
                        path: 'how-to-use-kampa-help-upgrading-your-repositories',
                        element: <DocumentationBoostRepoUpgradesWithKampa />
                    },
                    {
                        path: 'prerequisites',
                        element: <DocumentationPrerequisites />,
                    },
                    {
                        path: 'basic-concepts',
                        element: <DocumentationBasicConcepts />,
                    },
                    {
                        path: 'quick-start-guide',
                        element: <DocumentQuickStartGuide />,
                    },
                ],
            },
        ],
    },
    {
        path: '/app',
        element: <ProtectedRoute />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: '',
                element: <AppLayout />,
                children: [
                    {
                        path: 'dashboard',
                        element: <Dashboard />,
                    },
                    {
                        path: 'notifications',
                        element: <NotificationsList />,
                    },
                    {
                        path: 'profile',
                        element: <Profile />,
                    },
                    {
                        path: 'billing',
                        element: <Billing />,
                    },
                    {
                        path: 'documentations',
                        element: <DocumentationsList />
                    },
                    {
                        path: 'documentations/create',
                        element: <CreateDocumentation />,
                    },
                    {
                        path: 'documentations/:documentationUuid',
                        element: <EditDocumentation />,
                    },
                    {
                        path: 'templates',
                        element: <TemplatesList />
                    },
                    {
                        path: 'templates/create',
                        element: <CreateTemplate />,
                    },
                    {
                        path: 'templates/:templateUuid',
                        element: <EditTemplate />,
                    },
                    {
                        path: 'local-environments',
                        element: <EnvironmentsList />
                    },
                    {
                        path: 'local-environments/templates',
                        element: <EnvironmentsTemplatesList />
                    },
                    {
                        path: 'local-environments/create',
                        element: <CreateEnvironment />,
                    },
                    {
                        path: 'local-environments/create-from-template/:templateUuid',
                        element: <CreateEnvironmentFromTemplate />
                    },
                    {
                        path: 'local-environments/:environmentUuid',
                        element: <EditEnvironment />,
                    },
                    {
                        path: 'logs',
                        element: <LogsList />,
                    },
                    {
                        path: 'logs/:logUuid',
                        element: <LogViewer />,
                    },
                    {
                        path: 'commands',
                        element: <CommandsList />,
                    },
                    {
                        path: 'commands/create',
                        element: <CreateCommand />,
                    },
                    {
                        path: 'commands/:commandUuid',
                        element: <EditCommand />,
                    },
                    {
                        path: 'services',
                        element: <ServicesList />,
                    },
                    {
                        path: 'services/create',
                        element: <CreateService/>,
                    },
                    {
                        path: 'services/:serviceUuid',
                        element: <EditService/>,
                    },
                    {
                        path: 'services/:serviceUuid/versions/:versionUuid/',
                        element: <EditServiceVersion/>,
                    },
                    {
                        path: 'services/:serviceUuid/versions',
                        element: <CreateServiceVersion />
                    },
                    {
                        path: 'teams',
                        element: <TeamsList />,
                    },
                    {
                        path: 'teams/create',
                        element: <CreateTeam/>,
                    },
                    {
                        path: 'teams/:teamUuid',
                        element: <EditTeam/>,
                    },
                    {
                        path: 'keys',
                        element: <KeyList />,
                    },
                    {
                        path: 'keys/create',
                        element: <CreateKey/>,
                    },
                    {
                        path: 'keys/:keyUuid',
                        element: <EditKey/>,
                    },
                    {
                        path: 'users',
                        element: <UsersList/>,
                    },
                    {
                        path: 'users/:userUuid',
                        element: <EditUser/>,
                    },
                    {
                        path: 'users/invite',
                        element: <CreateUserInvite />
                    },
                    {
                        path: 'users/users-invites/:userInviteUuid',
                        element: <EditUserInvite/>,
                    },
                    {
                        path: 'client',
                        element: <Installation />,
                    },
                    {
                        path: 'deployments',
                        element: <DeploymentsList />
                    }
                ],
            },
        ],
    },
    {
        path: '*',
        element: <NotFound />,
    },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ConfigProvider
    theme={{
        token: {
            // Seed Token
            colorPrimary: '#fb923c',
            borderRadius: 8,

            // Alias Token
            //colorBgContainer: '#f6ffed',
            marginLG: 8, // Adjust global large margin (affects Form spacing)
            marginMD: 8, // Adjust medium margin
            marginSM: 4, // Adjust small margin
        },
    }}
    >
      <AntApp>
          <HelmetProvider>
              <Suspense fallback={<div>Loading...</div>}>
                   <RouterProvider router={router} />
               </Suspense>
          </HelmetProvider>
      </AntApp>
  </ConfigProvider>,
);
