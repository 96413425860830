import axiosInstance from "../../../../axios.ts";
import {ServiceVersion} from "../Types.ts";

const save = async (version: ServiceVersion): Promise<ServiceVersion> => {
    try {
        const response = await axiosInstance.post(`/services/${version.service_uuid}/versions`, version);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Save failed');
    }
};

const findAll = async (serviceUuid: string | undefined) => {
    try {
        const response = await axiosInstance.get(`/services/${serviceUuid}/versions?has_pagination=false`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Fetch failed');
    }
}

const findByUuid = async (serviceUuid: string, uuid: string | undefined) => {
    try {
        const response = await axiosInstance.get(`/services/${serviceUuid}/versions/${uuid}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Fetch failed');
    }
}

const update = async (version: ServiceVersion): Promise<boolean> => {
    await axiosInstance.patch(`/services/${version.service_uuid}/versions/${version.uuid}`, version);
    return true;
};

const destroy = async (serviceUuid:string, uuid: string): Promise<boolean> => {
    await axiosInstance.delete(`/services/${serviceUuid}/versions/${uuid}`);
    return true;
};

export default {
    save,
    findAll,
    findByUuid,
    update,
    destroy
};