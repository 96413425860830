import axiosInstance from "../../../axios.ts";
import { PaginatedResponse } from '../../Shared/Types';
import {Team} from "../Types.ts";

const findAll = async (page: number): Promise<PaginatedResponse<Team>> => {
    try {
        const response = await axiosInstance.get(`/teams?page=${page}&has_pagination=true`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'List failed');
    }
};

const findAllWithoutPagination = async (): Promise<Team[]> => {
    try {
        const response = await axiosInstance.get(`/teams?page=1&has_pagination=false`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'List failed');
    }
};

const save = async (team: Team): Promise<Team> => {
    try {
        const response = await axiosInstance.post(`/teams`, team);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Save failed');
    }
};

const findByUuid = async (uuid: string | undefined) => {
    try {
        const response = await axiosInstance.get(`/teams/${uuid}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Fetch failed');
    }
}

const update = async (team: Team): Promise<boolean> => {
    await axiosInstance.patch(`/teams/${team.uuid}`, team);
    return true;
};

const destroy = async (uuid: string): Promise<boolean> => {
    await axiosInstance.delete(`/teams/${uuid}`);
    return true;
};

export default {
    save,
    findAll,
    findAllWithoutPagination,
    findByUuid,
    update,
    destroy
};