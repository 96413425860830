import {
    CommandLineIcon,
    DocumentMagnifyingGlassIcon,
    KeyIcon,
    ServerStackIcon,
    Square3Stack3DIcon,
    UserGroupIcon,
    UserIcon
} from "@heroicons/react/24/outline";

const iconSize = 16;
const classes = 'object-cover w-8 mx-1 rounded-full';

export default function GetNotificationIcon(auditableType: string){
    switch(auditableType){
        case 'environment':
            return <ServerStackIcon className={classes} height={iconSize}/>
            break;

        case 'user':
            return <UserIcon className={classes} height={iconSize}/>;
            break

        case 'team':
            return <UserGroupIcon className={classes} height={iconSize}/>;
            break

        case 'service':
            return <Square3Stack3DIcon className={classes} height={iconSize}/>;
            break

        case 'key':
            return <KeyIcon className={classes} height={iconSize}/>;
            break

        case 'log':
            return <DocumentMagnifyingGlassIcon className={classes} height={iconSize} />
        break

        case 'command':
            return <CommandLineIcon className={classes} height={iconSize}/>;
            break

    }

    return <>-</>
}