import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import authRepository from "./Repositories/AuthRepository.tsx";
import {Alert} from "antd";
import useBreadcrumbStore from "../App/Stores/useBreadcrumbStore.tsx";
import {HomeIcon} from "@heroicons/react/24/outline";
import useUserStore from "../App/Stores/useUserStore.ts";
import {BreadcrumbLink} from "../App/Shared/Types.ts";

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/dashboard',
        title: <HomeIcon height={16} className="mt-1" />,
    },
];

const Login: React.FC = () => {
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const setUser = useUserStore(state => state.setUser);

    const navigate = useNavigate();
    const [error, setError] = useState<string | null>('');
    const isLoggedIn = useUserStore(state => state.isLoggedIn);

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/app/dashboard');
        }
    }, []);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const userData = await authRepository.login({ email, password });

            setUser(userData);
            setBreadcrumb(breadcrumbs);
            navigate('/app/dashboard');
        } catch (error) {
            setError(error.message ?? 'Invalid data'); // Set the error message to display the alert
        }
    };

    if (isLoggedIn) {
        return <>
        You're already logged, redirecting to the dashboard...
        </>
    }

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <Link to="/">
                        <img
                            alt="Kampa"
                            src='/images/k.svg'
                            className="mx-auto h-28 w-auto"
                        />
                    </Link>
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Sign in to your account
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    {error && (
                        <Alert message={error} type="error" showIcon closable onClose={() => setError(null)} className="mb-8" />
                    )}

                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    autoComplete="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password
                                </label>
                                <div className="text-sm">
                                    <Link to="/forgot-password" className="font-semibold text-orange-600 hover:text-orange-500">
                                        Forgot password?
                                    </Link>
                                </div>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    required
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                            >
                                Sign in
                            </button>
                        </div>
                    </form>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        Not a member?{' '}
                        <Link to="/sign-up" className="font-semibold leading-6 text-orange-600 hover:text-orange-500">
                            Start for free
                        </Link>
                    </p>
                </div>
            </div>
        </>
    );
}

export default Login;
