import { useState } from "react";
import Header from "./Components/Header.tsx";
import Footer from "./Components/Footer.tsx";
import http from "../axios.ts";

const contactAPIUrl = "/contact";

interface ContactFormData {
    first_name: string;
    last_name?: string;
    email: string;
    phone?: string;
    message: string;
}

export default function SiteContact() {
    const [formData, setFormData] = useState<ContactFormData>({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        message: "",
    });

    const [errors, setErrors] = useState<Partial<ContactFormData>>({});
    // const [formSubmitted, setFormSubmitted] = useState(false);
    const [formSubmittedSuccess, setFormSubmittedSuccess] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const validateForm = (): boolean => {
        const newErrors: Partial<ContactFormData> = {};

        if (!formData.first_name) newErrors.first_name = "First name is required";
        if (!formData.email) {
            newErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Email is not valid";
        }
        if (!formData.message) newErrors.message = "Message is required";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (validateForm()) {
            try {
                await http.post(contactAPIUrl, formData);
                setFormSubmittedSuccess(true);
            } catch (error) {
                console.error("Error sending email:", error.response?.data);
                alert("Email wasn't sent");
            } finally {
                setFormSubmittedSuccess(true);
            }
        }
    };

    return (
        <>
            <div className="bg-gray-100">
                <Header />
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <section id="contact" role="main" className="py-16">
                        <div className="container mx-auto px-6 lg:w-1/2">
                            <div className="text-center mb-10">
                                {!formSubmittedSuccess ? (
                                    <>
                                        <h2 className="gradient-text text-blue-600 text-xl font-semibold mb-4">
                                            Contact Us
                                        </h2>
                                        <p className="text-4xl font-bold text-gray-900 mb-6">
                                            Have questions or need assistance? Reach out to us, and
                                            we’ll respond as soon as possible.
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <h1 className="text-4xl font-bold text-gray-800">
                                            Thank You!
                                        </h1>
                                        <p className="text-gray-600">
                                            Your message has been sent successfully. We will contact
                                            you shortly.
                                        </p>
                                    </>
                                )}
                            </div>

                            {!formSubmittedSuccess && (
                                <form
                                    onSubmit={handleSubmit}
                                    className="bg-white p-6 shadow-md rounded-md"
                                >
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                                        <div>
                                            <label
                                                htmlFor="first_name"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                First Name
                                            </label>
                                            <input
                                                type="text"
                                                id="first_name"
                                                name="first_name"
                                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                placeholder="First name"
                                                onChange={handleChange}
                                                value={formData.first_name}
                                            />
                                            {errors.first_name && (
                                                <p className="text-sm text-red-600 mt-1">
                                                    {errors.first_name}
                                                </p>
                                            )}
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="last_name"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Last Name (Optional)
                                            </label>
                                            <input
                                                type="text"
                                                id="last_name"
                                                name="last_name"
                                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                placeholder="Last name"
                                                onChange={handleChange}
                                                value={formData.last_name || ""}
                                            />
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                placeholder="email@site.com"
                                                onChange={handleChange}
                                                value={formData.email}
                                            />
                                            {errors.email && (
                                                <p className="text-sm text-red-600 mt-1">
                                                    {errors.email}
                                                </p>
                                            )}
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="phone"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Phone (Optional)
                                            </label>
                                            <input
                                                type="text"
                                                id="phone"
                                                name="phone"
                                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                placeholder="+x(xxx)xxx-xx-xx"
                                                onChange={handleChange}
                                                value={formData.phone || ""}
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <label
                                            htmlFor="message"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Message
                                        </label>
                                        <textarea
                                            id="message"
                                            name="message"
                                            rows={4}
                                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                            placeholder="Tell us about your project or issue..."
                                            onChange={handleChange}
                                            value={formData.message}
                                        ></textarea>
                                        {errors.message && (
                                            <p className="text-sm text-red-600 mt-1">
                                                {errors.message}
                                            </p>
                                        )}
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            className="w-full py-2 px-4 bg-blue-600 text-white font-medium text-lg rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        >
                                            Send Message
                                        </button>
                                    </div>
                                </form>
                            )}
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </>
    );
}
