import axiosInstance from "../../../axios.ts";
import { PaginatedResponse } from '../../Shared/Types';
import {Key} from "../Types.ts";

const findAll = async (page: number, hasPagination: boolean): Promise<PaginatedResponse<Key>> => {
    try {
        const response = await axiosInstance.get(`/keys?page=${page}&has_pagination=${hasPagination}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'List failed');
    }
};

const save = async (team: Key): Promise<Key> => {
    try {
        const response = await axiosInstance.post(`/keys`, team);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Save failed');
    }
};

const findByUuid = async (uuid: string | undefined) => {
    try {
        const response = await axiosInstance.get(`/keys/${uuid}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Fetch failed');
    }
}

const destroy = async (uuid: string): Promise<boolean> => {
    await axiosInstance.delete(`/keys/${uuid}`);
    return true;
};

export default {
    save,
    findAll,
    findByUuid,
    destroy
};