import React, {useEffect} from 'react';
import { Button, Form, Input, message } from 'antd';
import {Team} from "./Types.ts";
import teamRepository from "./Repositories/TeamRepository.ts";
import {useNavigate} from "react-router-dom";
import {BreadcrumbLink} from "../Shared/Types.ts";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import UsersSelectComponent from "../Shared/UsersSelectComponent.tsx";

const { TextArea } = Input;

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/teams',
        title: 'Teams',
    },
    {
        href: '/app/teams/create',
        title: 'Create',
    },
];

const CreateTeam: React.FC = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
    }, []);

    const onFinish = async (values: Team) => {
        try {
            await teamRepository.save(values);
            messageApi.open({
                type: 'success',
                content: 'Team created successfully',
            });
            setTimeout(() => navigate('/app/teams'), 2250);
        } catch (error) {
            message.error('Failed to create team: ' + error.message);
        }
    };

    return (
        <>
            {contextHolder}
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Teams</h3>

                <div className="flex bg-white flex-col mt-8">
                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">

                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label="Name:"
                                    name="name"
                                    rules={[{ required: true, message: 'Please input the team name!' }]}
                                >
                                    <Input placeholder="Name" />
                                </Form.Item>

                                <Form.Item label="Description:" name="description">
                                    <TextArea
                                        rows={4}
                                        placeholder="Team's description"
                                        maxLength={150}
                                        showCount
                                    />
                                </Form.Item>

                                <UsersSelectComponent
                                    label={"Members"}
                                    name={"members"}
                                    isMultiple={true}
                                />

                                <UsersSelectComponent
                                    label={"Owners"}
                                    name={"owners"}
                                    isMultiple={true}
                                />

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">Save</Button>
                                </Form.Item>
                            </Form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateTeam;