import React, {useEffect, useState} from "react";
import { Form, Select } from "antd"; 
import UserRepository from "../Users/Repositories/UserRepository.ts";
import {User} from "../Users/Types.ts";


interface UserSelectComponentProps {
    name: string;
    label?: string;
    placeholder?: string;
    rules?: any;
    disabled?: boolean;
    isMultiple?: boolean;
}

const UserSelectComponent: React.FC<UserSelectComponentProps> = ({name, label, placeholder, rules, disabled, isMultiple = true }) => {
    const [options, setOptions] = useState(null)
    const [loading, setLoading] = useState<boolean>(true)

    const fetchData = async () => {
        const response: User[] = await UserRepository.findAllWithoutPagination();

        const formatted = response.map(item => ({
            value: item.uuid,
            label: item.first_name + ' ' + item.last_name,
        }));

        setOptions(formatted);
    }

    useEffect(() => {
        fetchData()
        setLoading(false)
    }, []);

    if (loading)
        return 'loading'

    return (
        <Form.Item
            label={label}
            name={name}
            rules={rules}>
                <Select
                    disabled={disabled}
                    showSearch
                    placeholder={placeholder}
                    optionFilterProp="label"
                    mode={isMultiple ? "multiple" : undefined}
                    options={options}
            />
        </Form.Item>
    );
};

export default UserSelectComponent;
