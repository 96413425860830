export default function SubscriptionCancel() {
    return (
        <div className="p-4 bg-white shadow rounded-lg">
            <h3 className="text-lg font-semibold text-gray-800">Need to Cancel Your Subscription?</h3>
            <p className="text-gray-600 mt-2">
                If you wish to cancel your subscription, please get in touch with our support team.
            </p>
            <a
                href="mailto:support@kamppa.io" // Replace with your support email or link
                className="inline-block mt-3 text-blue-600 hover:underline"
            >
                Contact Support
            </a>
        </div>
    );
}
