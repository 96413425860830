import axiosInstance from "../../../axios.ts";
import {OrganizationFeatureUsage} from "../Types.ts";

const findFeaturesUsage = async (): Promise<OrganizationFeatureUsage> => {
    try {
        const response = await axiosInstance.get(`/organizations/features-usage`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Error, request failed');
    }
}

export default {
    findFeaturesUsage
};