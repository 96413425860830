import axiosInstance from "../../axios.ts";
import {AxiosResponse} from "axios";
import {ForgotPassword, LoginData, RegisterData, ResetPassword} from "../Types.ts";

const login = async (data: LoginData) => {
    try {
        const response: AxiosResponse = await axiosInstance.post('/login', data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Login failed');
    }
};

const register = async (data: RegisterData) => {
    try {
        const response: AxiosResponse = await axiosInstance.post('/register', data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Register failed');
    }
};

const registerFromInvite = async (data: RegisterData) => {
    try {
        const response: AxiosResponse = await axiosInstance.post(`/users-invites/${data.invite}`, data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Register failed');
    }
};

const forgotPassword = async (data: ForgotPassword) => {
    try {
        const response: AxiosResponse = await axiosInstance.post('/forgot-password', data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Forgot password failed');
    }
};

const resetPassword = async (data: ResetPassword) => {
    try {
         const response: AxiosResponse = await axiosInstance.post('/reset-password', data)
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Reset password failed')
    }
}

export default {
    login,
    register,
    registerFromInvite,
    forgotPassword,
    resetPassword
}