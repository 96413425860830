import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, Input, message, Popconfirm, Radio, Spin, Switch} from 'antd';
import {Environment, EnvironmentService} from "./Types.ts";
import {useNavigate, useParams} from "react-router-dom";
import { BreadcrumbLink } from "../../Shared/Types.ts";
import useBreadcrumbStore from "../../Stores/useBreadcrumbStore.tsx";
import EnvironmentRepository from "./Repositories/EnvironmentRepository.ts";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import {strictSanitizeSchema} from "../../Shared/SanitizeTags.ts";
import EnvironmentItemTabs from "./EnvironmentItemTabs.tsx";

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/local-environments',
        title: 'Local Environments',
    },
    {
        href: '#',
        title: 'Edit',
    },
];

const EditEnvironment: React.FC = () => {
    const [form] = Form.useForm();
    const {environmentUuid} = useParams();
    const navigate = useNavigate();
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [messageApi, contextHolder] = message.useMessage();
    const [data, setData] = useState<Environment>();
    const [loading, setLoading] = useState<boolean>(true)
    const [includeProxy, setIncludeProxy] = useState<boolean>(false)
    const itemRefs = useRef({});

    const onFinish = async (values: Environment) => {
        try {
            values['uuid'] = environmentUuid
            values['services'] = Object.values(values.services)
                .filter(Array.isArray)
                .flat();

            await EnvironmentRepository.update(values);
            messageApi.open({
                type: 'success',
                content: 'Environment updated successfully',
            });
            setTimeout(() => navigate('/app/local-environments'), 2250);
        } catch (error) {
            const msg = error.response?.data?.message || error.message || 'An unknown error occurred.';
            message.error(`Failed to update environment: ${msg}`);
        }
    };

    const confirm: (uuid) => void = async (uuid) => {
        try {
            await EnvironmentRepository.destroy(uuid);

            messageApi.open({
                type: 'success',
                content: 'Environment deleted successfully',
            });

            setTimeout(() => navigate('/app/local-environments'), 2250);
        } catch (error) {
            message.error('Failed to delete service: ' + error.message);
        }
    };

    useEffect(() => {
        setBreadcrumb(breadcrumbs);

        const fetchEnvironment = async (environmentUuid: string | undefined) => {
            try {
                const response: Environment = await EnvironmentRepository.findByUuid(environmentUuid);
                const groupedServices = (Array.isArray(response.services) ? response.services : []).reduce(
                    (acc, service) => {
                        const key = service.service_type;
                        if (!acc[key]) {
                            acc[key] = [];
                        }
                        acc[key].push(service);
                        return acc;
                    },
                    {} as Record<string, EnvironmentService[]>
                );


                setData({
                    ...response,
                    services: groupedServices,
                });

                setIncludeProxy(response.include_proxy)

                setLoading(false);
            } catch (error) {
                console.error('Error fetching service:', error);
            }
        };

        fetchEnvironment(environmentUuid);
    }, []);

    if (loading) {
        return <>
            <div className="container px-6 py-8 mx-auto">
                <Spin size="small" />
                loading
            </div>
        </>
    }

    return (
        <>
            {contextHolder}
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Environments</h3>
                <div className="flex bg-white flex-col mt-8">
                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div
                            className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={onFinish}
                                initialValues={{
                                    ...data,
                                    instructions: data?.instructions ?? "",
                                    services: data?.services ? data.services : [],
                                }}
                            >
                                <Form.Item label="Status:" name="status">
                                    <Radio.Group>
                                        <Radio.Button value="active">Active</Radio.Button>
                                        <Radio.Button value="inactive">Inactive</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item
                                    label="Name:"
                                    name="name"
                                    rules={[{required: true, message: 'Please input the environment name!'}]}
                                >
                                    <Input placeholder="Name"/>
                                </Form.Item>

                                <Form.Item label="Description:" name="description">
                                    <Input
                                        placeholder="Environment's short description"
                                        maxLength={150}
                                        showCount
                                    />
                                </Form.Item>


                                <Form.Item label="Setup & Usage Instructions" name="instructions">
                                    <MDEditor
                                        previewOptions={{
                                            skipHtml: true,
                                            rehypePlugins: [[rehypeSanitize, strictSanitizeSchema]],
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Identifier:"
                                    name="identifier"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input the environment name!'
                                        },
                                        {
                                            pattern: /^[a-zA-Z-]+$/,
                                            message: "Only letters (a-z, A-Z) and hyphens (-) are allowed, without spaces",
                                        },
                                    ]}
                                >
                                    <Input placeholder="unique-identifier"/>
                                </Form.Item>

                                <Form.Item label="Include Reverse Proxy?" name="include_proxy">
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onChange={() => setIncludeProxy(!includeProxy)}
                                    />
                                </Form.Item>

                                <EnvironmentItemTabs
                                    itemRefs={itemRefs}
                                    form={form}
                                    includeProxy={includeProxy}
                                    data={data}
                                />

                                <Form.Item
                                    className={'mt-8'}>
                                    <Button type="primary" htmlType="submit">
                                    Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>

                <div className="flex bg-white flex-col mt-8">

                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div
                            className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <h3 className="text-xl font-medium text-gray-700 mb-4">Delete</h3>

                            <p className="text-sm text-gray-700 mb-4">
                                Deleting this item will remove all associated data and cannot be reversed.
                                Please ensure you have backed up any important information before proceeding.
                            </p>

                            <Popconfirm
                                title="Delete"
                                description="Are you sure to delete this item?"
                                onConfirm={() => confirm(environmentUuid)}
                                okText="Yes"
                                cancelText="No"
                                className={''}
                            >
                                <Button danger>Yes, I'll permanently delete it</Button>
                            </Popconfirm>

                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default EditEnvironment;