import React from 'react';
import { Link } from "react-router-dom";
import './footer.css';

const Footer: React.FC = () => {
    const scrollToTop = () => {
        setTimeout(() => {
            const element = document.querySelector('#header');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 0);
    }

    return (
        <>
            <footer className="pt-10 mt-12 pb-6 text-sm leading-6">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 text-center md:text-left">

                        <div>
                            <h2 className="font-semibold text-slate-900 dark:text-slate-100">Kampa</h2>
                            <ul className="mt-3 space-y-2">
                                <li><Link to="/about" className="hover:text-slate-900 dark:hover:text-slate-300"
                                          onClick={scrollToTop}>About</Link></li>
                                <li>
                                    <Link to={'https://dev.to/kampa'} target={'_blank'}
                                          className="hover:text-slate-900 dark:hover:text-slate-300">
                                        Blog
                                    </Link>
                                </li>
                                <li><Link to="/policies/terms-and-conditions#header"
                                          className="hover:text-slate-900 dark:hover:text-slate-300"
                                          onClick={scrollToTop}>Terms & Condition</Link></li>
                                <li><Link to="/policies/privacy#header"
                                          className="hover:text-slate-900 dark:hover:text-slate-300"
                                          onClick={scrollToTop}>Privacy & Policy</Link></li>
                                <li><Link to="/policies/cookies#header"
                                          className="hover:text-slate-900 dark:hover:text-slate-300"
                                          onClick={scrollToTop}>Cookie Policy</Link></li>
                            </ul>
                        </div>

                        <div>
                            <h2 className="font-semibold text-slate-900 dark:text-slate-100">Getting Started</h2>
                            <ul className="mt-3 space-y-2">
                                <li><Link to="/docs/introduction#header"
                                          className="hover:text-slate-900 dark:hover:text-slate-300"
                                          onClick={scrollToTop}>Introduction</Link></li>
                                <li><Link to="/docs/prerequisites#header"
                                          className="hover:text-slate-900 dark:hover:text-slate-300"
                                          onClick={scrollToTop}>Prerequisites</Link></li>
                                <li><Link to="/docs/installation#header"
                                          className="hover:text-slate-900 dark:hover:text-slate-300"
                                          onClick={scrollToTop}>Installation</Link></li>
                                <li><Link to="/docs/basic-concepts#header"
                                          className="hover:text-slate-900 dark:hover:text-slate-300"
                                          onClick={scrollToTop}>Basic
                                    concepts</Link></li>
                                <li><Link to="/docs/quick-start-guide#header"
                                          className="hover:text-slate-900 dark:hover:text-slate-300"
                                          onClick={scrollToTop}>Quick
                                    start guide</Link></li>
                                <li><Link to="/docs/d/commands"
                                          className="hover:text-slate-900 dark:hover:text-slate-300"
                                          onClick={scrollToTop}>Commands</Link></li>
                                <li><Link to="/cli-changelog#header"
                                          className="hover:text-slate-900 dark:hover:text-slate-300"
                                          onClick={scrollToTop}>CLI
                                    Changelog</Link></li>

                            </ul>

                            <h2 className="mt-8 font-semibold text-slate-900 dark:text-slate-100">
                                How to</h2>
                            <ul className="mt-3 space-y-2">
                                <li>
                                    <Link
                                        to={'/docs/d/how-to-create-your-own-environment'}
                                        className="hover:text-slate-900 dark:hover:text-slate-300"
                                        onClick={scrollToTop}
                                    >
                                        How to create an environment
                                    </Link>
                                </li>

                                <li>
                                    <Link to={'/docs/d/how-to-create-your-own-service'}
                                          className="hover:text-slate-900 dark:hover:text-slate-300"
                                          onClick={scrollToTop}
                                    >
                                        How to create a service
                                    </Link>
                                </li>

                                {/*<li>*/}
                                {/*    <Link to="/docs/boost-repo-upgrades-with-kampa"*/}
                                {/*          className="hover:text-slate-900 dark:hover:text-slate-300"*/}
                                {/*          onClick={scrollToTop}>*/}
                                {/*        Boost Repo Upgrades with Kampa*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                            </ul>
                        </div>

                        {/* Languages & Frameworks */}
                        <div>
                            <h2 className="font-semibold text-slate-900 dark:text-slate-100">Languages & Frameworks</h2>
                            <ul className="mt-3 space-y-2">
                                <li><Link to="/docs/d/docker-nodejs-with-react-and-vite"
                                          className="hover:text-slate-900 dark:hover:text-slate-300">NodeJS with React +
                                    Vite</Link></li>
                                <li><Link to="/docs/d/docker-with-php-84"
                                          className="hover:text-slate-900 dark:hover:text-slate-300">PHP 8.4 (Laravel,
                                    Symfony)</Link></li>
                                <li><Link to="/docs/d/docker-with-wordpress"
                                          className="hover:text-slate-900 dark:hover:text-slate-300">Wordpress</Link>
                                </li>

                                <li><Link to="/docs/d/docker-with-python-and-django"
                                          className="hover:text-slate-900 dark:hover:text-slate-300">Python with Django
                                    and
                                    MySQL</Link></li>
                                <li><Link to="/docs/d/docker-with-python-and-flask"
                                          className="hover:text-slate-900 dark:hover:text-slate-300">Python with
                                    Flask</Link></li>
                                <li><Link to="/docs/d/docker-with-go-example"
                                          className="hover:text-slate-900 dark:hover:text-slate-300">Go Lang</Link></li>
                                <li><Link to="/docs/d/docker-setup-for-java-with-spring-boot-development-and-hot-reload"
                                          className="hover:text-slate-900 dark:hover:text-slate-300">Java with
                                    Spring</Link>
                                </li>
                                <li><Link to="/docs/d/docker-with-quarkus-framework"
                                          className="hover:text-slate-900 dark:hover:text-slate-300">Java with
                                    Quarkus</Link></li>
                                <li><Link to="/docs/d/docker-with-dotnet-and-mvc"
                                          className="hover:text-slate-900 dark:hover:text-slate-300">.NET Core with
                                    MVC</Link></li>
                                <li><Link to="/docs/d/languages-and-frameworks"
                                          className="hover:text-slate-900 dark:hover:text-slate-300">and more...</Link>
                                </li>
                            </ul>
                        </div>

                        {/* Databases */}
                        <div>
                            <h2 className="font-semibold text-slate-900 dark:text-slate-100">Databases</h2>
                            <ul className="mt-3 space-y-2">
                                <li><Link to="/docs/d/docker-with-mysql8-database"
                                          className="hover:text-slate-900 dark:hover:text-slate-300">MySQL</Link></li>
                                <li><Link to="/docs/d/docker-with-mariadb-database"
                                          className="hover:text-slate-900 dark:hover:text-slate-300">MariaDB</Link></li>
                                <li><Link to="/docs/d/docker-with-postgres16-database"
                                          className="hover:text-slate-900 dark:hover:text-slate-300">Postgres</Link>
                                </li>
                                <li><Link to="/docs/d/docker-with-mongodb"
                                          className="hover:text-slate-900 dark:hover:text-slate-300">MongoDB</Link></li>
                                <li><Link to="/docs/d/docker-with-databases"
                                          className="hover:text-slate-900 dark:hover:text-slate-300">and more...</Link>
                                </li>
                            </ul>

                            {/* Caching Systems */}
                            <h2 className="font-semibold text-slate-900 dark:text-slate-100 mt-8">Caching Systems</h2>
                            <ul className="mt-3 space-y-2">
                                <li><Link to="/docs/d/docker-with-redis-cache"
                                          className="hover:text-slate-900 dark:hover:text-slate-300">Redis</Link></li>
                                <li><Link to="/docs/d/docker-with-memcached"
                                          className="hover:text-slate-900 dark:hover:text-slate-300">Memcached</Link>
                                </li>
                            </ul>

                            {/* Message Broker */}
                            <h2 className="font-semibold text-slate-900 dark:text-slate-100 mt-8">Message Broker</h2>
                            <ul className="mt-3 space-y-2">
                                <li><Link to="/docs/d/docker-with-rabbitmq"
                                          className="hover:text-slate-900 dark:hover:text-slate-300">RabbitMQ</Link>
                                </li>
                                <li><Link to="/docs/d/docker-with-kafka-with-kafka-ui"
                                          className="hover:text-slate-900 dark:hover:text-slate-300">Kafka + Kafka
                                    UI</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>


            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <svg className="w-full h-full" width="900" height="308"  viewBox="0 0 451 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="451" height="101"/>
                    <path  fill="#e5e7eb" d="M19.976 39.328L19.464 44.064L22.28 40.48L53 5.79199H77.064L38.152 48.032L77.192 100H53.64L24.584 59.808L19.976 64.416V100H1.16V5.79199H19.976V39.328ZM80.661 100L123.157 2.07999H124.181L166.677 100H145.173L118.037 31.136L131.477 21.92L98.965 100H80.661ZM109.717 65.952H138.005L144.533 81.568H103.957L109.717 65.952ZM180.41 100V1.952H180.538L232.89 76.192L224.954 74.4L277.178 1.952H277.434V100H258.874V43.808L260.026 53.408L228.154 98.72H227.898L195.13 53.408L198.33 44.576V100H180.41ZM330.329 5.79199C338.094 5.79199 344.665 7.11466 350.041 9.75999C355.417 12.32 359.513 15.9893 362.329 20.768C365.145 25.4613 366.553 31.0507 366.553 37.536C366.553 41.376 365.956 45.2587 364.761 49.184C363.652 53.024 361.817 56.5227 359.257 59.68C356.782 62.8373 353.412 65.3973 349.145 67.36C344.964 69.2373 339.801 70.176 333.657 70.176H320.345V100H301.785V5.79199H330.329ZM333.529 52.128C336.345 52.128 338.692 51.6587 340.569 50.72C342.446 49.696 343.897 48.4587 344.921 47.008C346.03 45.472 346.798 43.8933 347.225 42.272C347.737 40.6507 347.993 39.2427 347.993 38.048C347.993 37.1093 347.822 35.872 347.481 34.336C347.225 32.7147 346.628 31.0933 345.689 29.472C344.75 27.8507 343.3 26.4853 341.337 25.376C339.46 24.2667 336.9 23.712 333.657 23.712H320.345V52.128H333.529ZM363.036 100L405.532 2.07999H406.556L449.052 100H427.548L400.412 31.136L413.852 21.92L381.34 100H363.036ZM392.092 65.952H420.38L426.908 81.568H386.332L392.092 65.952Z" />
                </svg>
            </div>

        </>
    );
}

export default Footer;

{/*<div className="lg:flex-none lg:w-1/2">*/
}
{/*    <h2 className="font-semibold text-slate-900 dark:text-slate-100">Community</h2>*/
}
{/*    <ul className="mt-3 space-y-2">*/
}
{/*        /!*<li><a className="hover:text-slate-900 dark:hover:text-slate-300" href="/community">Community Overview</a></li>*!/*/
}
{/*        /!*<li><a className="hover:text-slate-900 dark:hover:text-slate-300" href="/community/forums">Forums</a></li>*!/*/
}
{/*        /!*<li><a className="hover:text-slate-900 dark:hover:text-slate-300" href="/community/events">Events</a></li>*!/*/
}
{/*        <li><a className="hover:text-slate-900 dark:hover:text-slate-300" href="https://twitter.com/kampaio" target="_blank">X (ex-Twitter)</a></li>*/
}
{/*        <li><a className="hover:text-slate-900 dark:hover:text-slate-300" href="https://www.youtube.com/kampaio" target="_blank">YouTube</a></li>*/
}
{/*        <li><a className="hover:text-slate-900 dark:hover:text-slate-300" href="https://github.com/kampaio" target="_blank">GitHub</a></li>*/
}
{/*    </ul>*/
}
{/*</div>*/
}
