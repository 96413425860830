import React, { useState, useEffect } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";

const breadcrumbs = [
    {
        href: "/app/client",
        title: "Download CLI",
    },
];

const Installation: React.FC = () => {
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState("mac");

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
    }, []);

    return (
        <>
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Download the CLI</h3>
                <p className="mt-4 text-gray-600">
                    Now that you have the Kampa ecosystem set up, it's time to install the client that will automate your tasks, streamlining your development workflow even further.
                </p>
                <div className="mt-6 relative inline-block">
                    <button
                        onClick={() => setDropdownOpen(!isDropdownOpen)}
                        className="flex items-center bg-blue-600 text-white font-semibold py-3 px-6 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none"
                    >
                        <ArrowDownTrayIcon className="w-5 h-5 mr-2"/>
                        Download CLI
                        <ChevronDownIcon className="w-5 h-5 ml-2"/>
                    </button>

                    {isDropdownOpen && (
                        <div className="absolute mt-2 w-64 bg-white rounded-lg shadow-lg py-2">
                            <a
                                href="/downloads/darwin-amd64/kampa.zip"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                                onClick={() => setDropdownOpen(!isDropdownOpen)}
                            >
                                Download for Mac - Intel Chip
                            </a>
                            <a
                                href="/downloads/darwin-arm64/kampa.zip"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                                onClick={() => setDropdownOpen(!isDropdownOpen)}

                            >
                                Download for Mac - Apple Silicon
                            </a>
                            <a
                                href="/downloads/windows-amd64/KampaInstaller.exe"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                                onClick={() => setDropdownOpen(!isDropdownOpen)}

                            >
                                Download for Windows - AMD64
                            </a>
                            {/*<a*/}
                            {/*    href="/downloads/windows-arm64/kampa.exe"*/}
                            {/*    target="_blank"*/}
                            {/*    rel="noopener noreferrer"*/}
                            {/*    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"*/}
                            {/*>*/}
                            {/*    Download for Windows - ARM64 (BETA)*/}
                            {/*</a>*/}
                            <a
                                href="/downloads/linux-amd64/kampa"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                                onClick={() => setDropdownOpen(!isDropdownOpen)}

                            >
                                Download for Linux
                            </a>
                        </div>
                    )}
                </div>

            </div>


            <div className="mt-4 p-6 bg-gray-100 rounded-lg">
                <h4 className="text-xl font-semibold text-gray-700">Installation Instructions</h4>

                <p className="mt-2 text-gray-600 mb-6">
                    After downloading the Kampa client, move it to a directory included in your system’s <b>PATH</b> to
                    make it executable from any terminal location.
                </p>

                <div className="flex border-b border-gray-300 mb-4">
                    <button
                        className={`px-4 py-2 ${selectedTab === "mac" ? "border-b-2 border-blue-600 text-blue-600" : "text-gray-600"}`}
                        onClick={() => setSelectedTab("mac")}>Mac
                    </button>
                    <button
                        className={`px-4 py-2 ${selectedTab === "windows" ? "border-b-2 border-blue-600 text-blue-600" : "text-gray-600"}`}
                        onClick={() => setSelectedTab("windows")}>Windows
                    </button>
                    <button
                        className={`px-4 py-2 ${selectedTab === "linux" ? "border-b-2 border-blue-600 text-blue-600" : "text-gray-600"}`}
                        onClick={() => setSelectedTab("linux")}>Linux
                    </button>
                </div>

                {selectedTab === "mac" && (
                    <div>
                        <p>Download and unzip the archive, then move the binary to the correct directory and make it executable:</p>
                        <p className="text-gray-600">Run the following commands:</p>
                        <pre className="mt-4 p-4 bg-gray-900 text-white rounded-md overflow-x-auto">
{`unzip kampa.zip
sudo mv build/darwin-arm64/kampa /usr/local/bin/kampa
chmod +x /usr/local/bin/kampa`}
    </pre>
                    </div>

                )}
                {selectedTab === "windows" && (
                    <div>
                        <p className="text-gray-600">Installing the CLI:</p>
                        <ol className="list-decimal pl-6 mt-2 text-gray-600">
                            <li>Open the installer as administrator.</li>
                            <li>We recommend to use C:\kampa as the destination folder</li>
                            <li>After fill all the settings, you'll be able to test on terminal.</li>
                            <li>Open the PowerShell and run the following command</li>
                        </ol>

                        <pre className="mt-2 p-4 bg-gray-900 text-white rounded-md overflow-x-auto">
{`kampa version`}
</pre>

                    </div>
                )}
                {selectedTab === "linux" && (
                    <div>
                        <p>Download and move the binary to the correct directory and make it executable:</p>
                        <p className="text-gray-600">Run the following commands:</p>
                        <pre className="mt-4 p-4 bg-gray-900 text-white rounded-md overflow-x-auto">
{`sudo mv kampa /usr/local/bin/kampa
chmod +x /usr/local/bin/kampa`}
    </pre>
                    </div>
                )}

        </div>

</>
)
    ;
};

export default Installation;
