import axiosInstance from "../../../axios.ts";
import {Invoice} from "../Types.ts";

const findAllInvoices = async (): Promise<Invoice[]> => {
    try {
        const response = await axiosInstance.get(`/organizations/billing/invoices`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'List failed');
    }
}

const sendInvoiceToEmail = async (invoiceId: string): Promise<boolean> => {
    try {
        await axiosInstance.post(`/organizations/billing/invoices/${invoiceId}/send-payment-link`);
        return true;
    } catch (error) {
        throw new Error('Error sending email');
    }
};


const activateSubscription = async (values): Promise<boolean> => {
    try {
        await axiosInstance.post(`/organizations/billing/activate`, values)
        return true
    } catch (error) {
        throw new Error('Error trying to activate the subscription, please contact the support')
    }
}

export default {
    findAllInvoices,
    sendInvoiceToEmail,
    activateSubscription
};