import { useEffect, useState } from "react";
import { Link, NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { documentationStructure } from "./Helper.ts";
import "highlight.js/styles/github.css";

export default function DocumentationLayout() {
    const location = useLocation();
    const [openCategory, setOpenCategory] = useState<string | null>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();

    // Automatically open the correct category based on the current URL
    useEffect(() => {
        for (const category of documentationStructure) {
            if (
                category?.routes?.some((route) =>
                    location.pathname.includes(`/docs/${route.path}`)
                )
            ) {
                setOpenCategory(category.category);
                break;
            }
        }
    }, [location.pathname]);

    const toggleCategory = (category: string) => {
        setOpenCategory((prev) => (prev === category ? null : category));
    };

    return (
        <div className="flex min-h-screen bg-gray-100">
            {/* Mobile Menu Overlay (Closes Menu on Click Outside) */}
            {isMenuOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
                    onClick={() => setIsMenuOpen(false)}
                />
            )}

            {/* Mobile Menu Button (Right Side) */}
            <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="fixed top-4 right-4 z-50 block md:hidden p-2 bg-gray-800 text-white rounded-md"
            >
                {isMenuOpen ? "✖" : "☰"} {/* X for close, ☰ for open */}
            </button>

            {/* Sidebar Navigation */}
            <nav
                className={`fixed md:relative md:block top-0 left-0 h-screen bg-gray-100 p-4 z-50 transition-transform ${
                    isMenuOpen ? "translate-x-0" : "-translate-x-full"
                } w-full md:w-64 md:translate-x-0 overflow-y-auto max-h-screen`}
            >
                <div className="flex justify-between mb-4">
                    <Link to="/" className="p-1.5">
                        <span className="sr-only">Kampa</span>
                        <img alt="Kampa Logo" src="/images/k.svg" className="h-12 w-auto" />
                    </Link>
                </div>

                <h2 className="text-lg font-semibold text-gray-700">Documentation</h2>
                <ul className="mt-4 space-y-2">
                    {documentationStructure.map(({ category, path, subcategories, routes }) => (
                        <li key={category}>
                            <button
                                onClick={() => {
                                    toggleCategory(category);
                                    if (path !== "#") navigate(`/docs/${path}`);
                                }}
                                className="w-full text-left p-2 font-bold text-gray-800 hover:bg-gray-200"
                            >
                                {category}
                            </button>

                            {openCategory === category && (
                                <ul className="ml-4 border-l border-gray-300">
                                    {subcategories ? (
                                        subcategories.map(({ label, routes }) => (
                                            <li key={label} className="ml-2">
                                                <span className="font-semibold text-gray-700">{label}</span>
                                                <ul>
                                                    {routes.map(({ path, label }) => (
                                                        <li key={path}>
                                                            <NavLink
                                                                to={`/docs/${path}`}
                                                                onClick={() => setIsMenuOpen(false)}
                                                                className={({ isActive }) =>
                                                                    `block p-2 pl-6 ${
                                                                        isActive
                                                                            ? "text-black font-bold"
                                                                            : "text-gray-700 hover:text-black"
                                                                    }`
                                                                }
                                                            >
                                                                {label}
                                                            </NavLink>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        ))
                                    ) : (
                                        routes.map(({ path, label }) => (
                                            <li key={path}>
                                                <NavLink
                                                    to={`/docs/${path}`}
                                                    onClick={() => setIsMenuOpen(false)}
                                                    className={({ isActive }) =>
                                                        `block p-2 pl-6 ${
                                                            isActive
                                                                ? "text-black font-bold"
                                                                : "text-gray-700 hover:text-black"
                                                        }`
                                                    }
                                                >
                                                    {label}
                                                </NavLink>
                                            </li>
                                        ))
                                    )}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </nav>

            {/* Content Display */}
            <div className="flex-1 p-6 bg-white text-gray-900">
                <div className="prose max-w-none">
                    <Outlet /> {/* Renders the matched documentation page */}
                </div>
            </div>
        </div>
    );
}
