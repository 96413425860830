import axiosInstance from "../../../axios.ts";
import { PaginatedResponse } from '../../Shared/Types';
import {Documentation} from "../Types.ts";

const findAll = async (page: number): Promise<PaginatedResponse<Documentation>> => {
    try {
        const response = await axiosInstance.get(`/documentations?page=${page}&has_pagination=true`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'List failed');
    }
};

const findByPath = async (path: string | undefined): Promise<Documentation[]> => {
    try {
        const response = await axiosInstance.get(`/documentations/path/${path}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Fetch by path failed');
    }
};

const findByUuid = async (uuid: string | undefined) => {
    try {
        const response = await axiosInstance.get(`/documentations/${uuid}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Fetch failed');
    }
}

const save = async (documentation: Documentation): Promise<Documentation> => {
    try {
        const response = await axiosInstance.post(`/documentations`, documentation);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Save failed');
    }
};

const update = async (documentation: Documentation): Promise<boolean> => {
    await axiosInstance.patch(`/documentations/${documentation.uuid}`, documentation);
    return true;
};

const destroy = async (uuid: string): Promise<boolean> => {
    await axiosInstance.delete(`/documentations/${uuid}`);
    return true;
};

export default {
    save,
    findAll,
    findByPath,
    findByUuid,
    update,
    destroy
};