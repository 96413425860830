export const documentationStructure = [
    {
        category: "Getting Started",
        path: "#",
        routes: [
            { path: "d/introduction", label: "Introduction" },
            { path: "prerequisites", label: "Prerequisites" },
            { path: "installation", label: "Installation" },
            { path: "basic-concepts", label: "Basic Concepts" },
            { path: "quick-start-guide", label: "Quick Start Guide" },
            { path: "d/commands", label: "Commands" },
        ],
    },
    {
        category: "How To",
        path: "#",
        routes: [
            { path: "d/how-to-create-your-own-environment", label: "Create Your Own Environment" },
            { path: "d/how-to-create-your-own-service", label: "Create Your Own Service" },
        ],
    },
    {
        category: "Language and Frameworks",
        path: "d/languages-and-frameworks",
        routes: [
            { path: "d/docker-with-dotnet-and-mvc", label: ".NET Core with MVC" },
            { path: "d/docker-with-go-example", label: "Go Lang" },
            { path: "d/docker-nodejs-with-react-and-vite", label: "NodeJS 22 + React + Vite" },
            { path: "d/docker-setup-for-java-with-spring-boot-development-and-hot-reload", label: "Java with Spring Boot" },
            { path: "d/docker-with-php-84", label: "PHP 84 with composer" },
            { path: "d/docker-with-python-and-django", label: "Python with Django" },
            { path: "d/docker-with-python-and-flask", label: "Python with Flask" },
            { path: "d/languages-and-frameworks", label: "... More" },
        ]
    },
    {
        category: "Databases",
        path: "d/docker-with-databases",
        subcategories: [
            {
                label: "SQL Databases",
                routes: [
                    { path: "d/docker-with-mariadb-database", label: "MariaDB" },
                    { path: "d/docker-with-mysql8-database", label: "MySQL" },
                    { path: "d/docker-with-postgres16-database", label: "PostgreSQL" },
                ],
            },
            {
                label: "NoSQL Databases",
                routes: [
                    { path: "d/docker-with-mongodb", label: "MongoDB" },
                ],
            },
        ],
    },
    {
        category: "Caching Systems",
        path: "#",
        routes: [
            { path: "d/docker-with-redis-cache", label: "Redis" },
            { path: "d/docker-with-memcached", label: "Memcached" },
        ],
    },
    {
        category: "Message Brokers",
        path: "#",
        routes: [
            { path: "d/docker-with-rabbitmq", label: "RabbitMQ" },
            { path: "d/docker-with-kafka-with-kafka-ui", label: "Kafka + Kafka UI" },
        ],
    },
];


/*export const documentationStructure = [
    {
        category: "Getting Started",
        path: '#',
        routes: [
            { path: "introduction", label: "Introduction" },
            { path: "prerequisites", label: "Prerequisites" },
            { path: "installation", label: "Installation" },
            { path: "basic-concepts", label: "Basic Concepts" },
            { path: "quick-start-guide", label: "Quick Start Guide" },
        ],
    },
    {
        category: "How to",
        path: '#',
        routes: [
            { path: "how-to-create-your-own-environment", label: "How to create your own environment" },
            { path: "how-to-create-your-own-service", label: "How to create your own service" },
            // { path: "how-to-use-kampa-help-upgrading-your-repositories", label: "How to use kampa to help upgrading your repositories" },
        ],
    },
    {
        category: "Language and Frameworks",
        path: 'languages-and-frameworks',
        routes: [
            { path: "docker-with-dotnet-and-mvc", label: ".NET Core with MVC" },
            { path: "docker-with-go-example", label: "Go Lang" },
            { path: "docker-with-java-spring", label: "Java with Spring" },
            { path: "docker-with-java-quarkus", label: "Java with Quarkus" },
            { path: "docker-nodejs-with-react-and-vite", label: "NodeJS with React + Vite" },
            { path: "docker-nodejs-with-vue", label: "NodeJS with VueJS" },
            { path: "docker-nodejs-with-angular", label: "NodeJS with Angular" },
            { path: "docker-with-php-84", label: "PHP 8.4 (Laravel, Symfony)" },
            { path: "docker-with-wordpress", label: "Wordpress" },
            { path: "docker-with-python-and-django", label: "Python with Django and MySQL" },
            { path: "docker-with-python-and-flask", label: "Python with Flask" },
        ],
    },
    {
        category: "Databases",
        path: 'docker-with-databases',
        routes: [
            { path: "docker-with-mariadb-database", label: "MariaDB" },
            { path: "docker-with-mysql8-database", label: "MySQL" },
            { path: "docker-with-postgres16-database", label: "Postgres" },
            { path: "docker-with-mongodb", label: "MongoDB" },
        ],
    },
    {
        category: "Caching Systems",
        path: '#',
        routes: [
            { path: "docker-with-redis-cache", label: "Redis" },
            { path: "docker-with-memcached", label: "Memcached" },
        ],
    },
    {
        category: "Message brokers",
        path: "#",
        routes: [
            { path: "docker-with-rabbitmq", label: "RabbitMQ" },
            { path: "docker-with-kafka-with-kafka-ui", label: "Kafka + Kafka UI" }
        ]
    }
];
*/