import axiosInstance from "../../../../axios.ts";
import {PaginatedResponse} from '../../../Shared/Types.ts';
import {Environment} from "../Types.ts";
import {AxiosResponse} from "axios";

const findAll = async (page: number, hasPagination: boolean): Promise<PaginatedResponse<Environment>> => {
    try {
        const response = await axiosInstance.get(`/environments?page=${page}&has_pagination=${hasPagination}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'List failed');
    }
};

const save = async (environment: Environment): Promise<Environment> => {
    try {
        const response = await axiosInstance.post(`/environments`, environment);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Save failed');
    }
};

const findByUuid = async (uuid: string | undefined) => {
    try {
        const response: AxiosResponse<Environment> = await axiosInstance.get(`/environments/${uuid}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Fetch failed');
    }
}

const update = async (environment: Environment): Promise<boolean> => {
    await axiosInstance.patch(`/environments/${environment.uuid}`, environment);
    return true;
};

const destroy = async (uuid: string): Promise<boolean> => {
    await axiosInstance.delete(`/environments/${uuid}`);
    return true;
};

export default {
    findAll,
    save,
    findByUuid,
    update,
    destroy
}