import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {Alert} from 'antd'
import authRepository from './Repositories/AuthRepository.js'

const ForgotPassword: React.FC = () => {
  const [error, setError] = useState<string | null>('');
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await authRepository.forgotPassword({
        email: email
      });

      if (response?.errors) {
        setError(response.errors.email);
      } else {
        setSuccess(response.status);
      }
    } catch (error) {
      setError(error.message ?? 'Invalid data'); // Set the error message to display the alert
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <Link to="/">
            <img
              alt="Kampa"
              src='/images/k.svg'
              className="mx-auto h-28 w-auto"
            />
          </Link>
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Recover your password
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          {error && (
              <Alert message={error} type="error" showIcon closable onClose={() => setError(null)} className="mb-8" />
          )}

          {success && (
              <Alert message={success} type="success" showIcon closable onClose={() => setError(null)} className="mb-8" />
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
              >
                Recover password
              </button>
            </div>
          </form>


        </div>
      </div>
    </>
  );
}

export default ForgotPassword;