import axiosInstance from "../../../axios.ts";
import {AuditLogEntry} from "../Types.ts";
import {PaginatedResponse} from "../../Shared/Types.ts";

const findAll = async (page: number, hasPagination: boolean): Promise<PaginatedResponse<AuditLogEntry>> => {
    try {
        const response = await axiosInstance.get(`/notifications?page=${page}&has_pagination=${hasPagination}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Error, request failed');
    }
}

const findAllWithoutPagination = async (): Promise<AuditLogEntry[]> => {
    try {
        const response = await axiosInstance.get(`/notifications?limit=5&has_pagination=false`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Error, request failed');
    }
}

export default {
    findAll,
    findAllWithoutPagination,
};