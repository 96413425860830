import React, { useEffect, useState } from 'react';
import {
    Button,
    Form,
    Input,
    Radio,
    message,
    TabsProps,
    Tabs,
    Switch,
    Col,
    Row,
    Tooltip,
    InputNumber,
    Popconfirm, Divider
} from 'antd';
import { Service } from "./Types.ts";
import {useNavigate} from "react-router-dom";
import { BreadcrumbLink } from "../../Shared/Types.ts";
import useBreadcrumbStore from "../../Stores/useBreadcrumbStore.tsx";
import TeamSelectComponent from "../../Shared/TeamSelectComponent.tsx";
import ServiceTypeSelectComponent from "../../Shared/ServiceTypeSelectComponent.tsx";
import CodeTextAreaComponent from "../../Shared/CodeTextAreaComponent.tsx";
import {MinusCircleIcon, PlusCircleIcon} from "@heroicons/react/24/outline";
import serviceRepository from "./Repositories/ServiceRepository.ts";
import {DeleteOutlined} from "@ant-design/icons";

const { TextArea } = Input;

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/local-environments',
        title: 'Local environments',
    },
    {
        href: '/app/services',
        title: 'Services',
    },
    {
        href: '/app/services/create',
        title: 'Create',
    },
];

const dockerExample = `# Dockerfile Example
FROM node:18
WORKDIR /app
COPY . .
RUN npm install
CMD ["npm", "start"]
`;

const CreateService: React.FC = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [messageApi, contextHolder] = message.useMessage();
    const [code, setCode] = useState<string>(dockerExample);
    const [entrypoint, setEntrypoint] = useState<string>('');

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
    }, []);

    const onFinish = async (values: Service) => {
        try {
            await serviceRepository.save(values);
            messageApi.open({
                type: 'success',
                content: 'Service created successfully',
            });
            setTimeout(() => navigate('/app/services'), 2250);
        } catch (error) {
            message.error('Failed to create service: ' + error.message);
        }
    };

    const versionItens: TabsProps['items'] = [
        {
            key: '1',
            label: 'Identification',
            forceRender: true,
            children: <>
                <Form.Item
                    label={'Name of this image'}
                    name={['version', 'name']}
                    rules={[
                        {
                            required: true,
                            message: 'This is a required field'
                        }
                    ]}
                >
                    <Input
                        placeholder={'Ex: Laravel 11 - With PHP 8.2'}
                    />
                </Form.Item>

                <Form.Item
                    label={<>
                        <Tooltip title="Unique identifier for the image">
                            Image identifier
                        </Tooltip>
                    </>}
                    name={['version', 'image']}
                    rules={[
                        {
                            required: true,
                            message: 'This is a required field'
                        }
                    ]}
                >
                    <Input
                        placeholder={'Ex: laravel-11-PHP-8.2'}
                    />
                </Form.Item>


                <Form.Item
                    label={'Instructions'}
                    name={['version', 'instructions']}
                >
                    <TextArea rows={15}/>
                </Form.Item>

            </>,
        },
        {
            key: '2',
            label: 'Build Details',
            forceRender: true,
            children: <>
                <CodeTextAreaComponent
                    label={'Dockerfile:'}
                    name={['version', 'dockerfile']}
                    rules={[
                        {
                            required: true,
                            message: 'This is a required field'
                        }
                    ]}
                    code={code}
                    setCode={setCode}
                    language={'docker'}
                />


                <CodeTextAreaComponent
                    label={'Entrypoint:'}
                    name={['version', 'entrypoint']}

                    code={entrypoint}
                    setCode={setEntrypoint}
                    language={'shell'}
                />
            </>,
        },
        {
            key: '3',
            label: 'Configuration',
            forceRender: true,
            children: <>
                <h2 className={'mt-2 mb-2 text-xl'}>Variables</h2>

                <Form.List name={['version', 'variables']}>
                    {(fields, {add, remove}) => (
                        <>
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusCircleIcon className="h-5 w-5"/>}
                                >
                                    Add variable
                                </Button>
                            </Form.Item>

                            {fields.map(({key, name, fieldKey, ...restField}) => (
                                <Row key={key} gutter={[16, 16]} align="middle">
                                    <Col span={6}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'name']}
                                            label="Name"
                                            rules={[{required: true, message: 'Missing name'}]}
                                        >
                                            <Input placeholder="MYSQL_ROOT_PASSWORD"/>
                                        </Form.Item>

                                    </Col>

                                    <Col span={6}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'default']}
                                            label="Default value"
                                            rules={[{required: true, message: 'Missing default value'}]}
                                        >
                                            <Input placeholder="Password"/>
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'is_required']}
                                            label="Is required?"
                                            valuePropName="checked"
                                        >
                                            <Switch/>
                                        </Form.Item>
                                    </Col>

                                    <MinusCircleIcon
                                        onClick={() => remove(name)}
                                        className="h-5 w-5 text-red-500 cursor-pointer"
                                    />
                                </Row>
                            ))}
                        </>
                    )}
                </Form.List>

                <div className={'m-6'}></div>

                <h2 className={'mt-2 mb-2 text-xl'}>Volumes</h2>

                <Form.List name={['version', 'volumes']}>
                    {(fields, {add, remove}) => (
                        <>
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusCircleIcon className="h-5 w-5"/>}
                                >
                                    Add volume
                                </Button>
                            </Form.Item>

                            {fields.map(({key, name, fieldKey, ...restField}) => (
                                <Row key={key} gutter={[16, 16]} align="middle">
                                    <Col span={6}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'local_folder']}
                                            label="Local folder:"
                                            rules={[{required: true, message: 'Missing local folder'}]}
                                        >
                                            <Input placeholder="data"/>
                                        </Form.Item>

                                    </Col>

                                    <Col span={6}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'container_folder']}
                                            label="Container Folder:"
                                            rules={[{required: true, message: 'Missing container folder'}]}
                                        >
                                            <Input placeholder="/var/lib/mysql"/>
                                        </Form.Item>
                                    </Col>

                                    <MinusCircleIcon
                                        onClick={() => remove(name)}
                                        className="h-5 w-5 text-red-500 cursor-pointer"
                                    />
                                </Row>
                            ))}
                        </>
                    )}
                </Form.List>

                <div className={'m-6'}></div>

                <h2 className={'mt-2 mb-2 text-xl'}>Ports</h2>
                <Form.List name={['version', 'ports']}>
                    {(fields, {add, remove}) => (
                        <>
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusCircleIcon className="h-5 w-5"/>}
                                >
                                    Add port
                                </Button>
                            </Form.Item>

                            {fields.map(({key, name, fieldKey, ...restField}) => (
                                <Row key={key} gutter={[16, 16]} align="middle">
                                    <Col span={6}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'number']}
                                            label="Number:"
                                            rules={[{required: true, message: 'Missing number'}]}
                                        >
                                            <InputNumber min={1}/>
                                        </Form.Item>

                                    </Col>

                                    <Col span={6}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'protocol']}
                                            label="Protocol:"
                                            rules={[{required: true, message: 'Missing container folder'}]}
                                        >
                                            <Input placeholder="tcp/ip"/>
                                        </Form.Item>
                                    </Col>

                                    <MinusCircleIcon
                                        onClick={() => remove(name)}
                                        className="h-5 w-5 text-red-500 cursor-pointer"
                                    />
                                </Row>
                            ))}
                        </>
                    )}
                </Form.List>
            </>,
        },
        {
            key: '4',
            label: 'Auxiliary Files',
            forceRender: true,
            children: <>
                <Form.List name={['version', 'files']}>
                    {(fields, {add, remove}) => (
                        <>
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusCircleIcon className="h-5 w-5"/>}
                                >
                                    Add file
                                </Button>
                            </Form.Item>

                            {fields.map(({key, name, fieldKey, ...restField}) => (
                                <>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'name']}
                                        label="Name"
                                        rules={[{required: true, message: 'Missing name'}]}
                                    >
                                        <Input placeholder="site.conf"/>
                                    </Form.Item>


                                    <Form.Item
                                        {...restField}
                                        name={[name, 'container_path']}
                                        label={
                                            <Tooltip title="Example: /etc/supervisor/conf.d/supervisor.conf">
                                                <span>Container Path with the file name</span>
                                            </Tooltip>
                                        }
                                        rules={[{required: true, message: 'Missing container path'}]}
                                    >
                                        <Input placeholder="/etc/supervisor/conf.d/supervisor.conf"/>
                                    </Form.Item>


                                    <Form.Item
                                        {...restField}
                                        name={[name, 'content']}
                                        label="File Content"
                                        rules={[{required: true, message: 'Missing file content'}]}
                                    >
                                        <TextArea rows={15} />
                                    </Form.Item>


                                    <Popconfirm
                                        title="Delete"
                                        description="Are you sure to delete this file?"
                                        onConfirm={() => remove(name)}
                                        okText="Yes"
                                        cancelText="No"
                                        className={''}
                                    >
                                        <Button
                                            icon={<DeleteOutlined />}
                                            className="w-100 mt-4 px-2 py-1 rounded-lg mb-4 bg-slate-50 hover:bg-gray-400 text-center"
                                        >
                                            Remove this file
                                        </Button>
                                    </Popconfirm>

                                    <Divider className={"mb-8"} />
                                </>
                            ))}
                        </>
                    )}
                </Form.List>
            </>
        }
    ];

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Data',
            forceRender: true,
            children: <>
                <div className={'mb-2'}></div>
                <Form.Item label="Visibility:" name="is_private">
                    <Radio.Group>
                        <Radio.Button value="false">Public</Radio.Button>
                        <Radio.Button value="true">Private</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <TeamSelectComponent
                    name={'team_uuid'}
                    label={'Team:'}
                    placeholder={'Select one of your teams'}
                    rules={[{required: true, message: 'Select one team'}]}
                />
                <ServiceTypeSelectComponent
                    name={'type'}
                    placeholder={'Select the type of the service'}
                    rules={[{required: true, message: 'Select the type of the service'}]}
                />
                <Form.Item
                    label="Name:"
                    name="name"
                    rules={[{required: true, message: 'Please input the team name!'}]}
                >
                    <Input placeholder="Name"/>
                </Form.Item>
                <Form.Item label="Description:" name="description">
                    <TextArea
                        rows={4}
                        placeholder="Service's description"
                        maxLength={150}
                        showCount
                    />
                </Form.Item>
            </>,
        },
        {
            key: '2',
            label: 'Version (Configuration)',
            forceRender: true,
            children: <>

                <Tabs defaultActiveKey="1" items={versionItens}/>

            </>,
        },
    ];

    return (
        <>
            {contextHolder}
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Services</h3>
                <div className="flex bg-white flex-col mt-8">
                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div
                            className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <Form
                                layout="vertical"
                                form={form}
                                initialValues={{
                                    is_private: 'true',
                                    version: {
                                        dockerfile: dockerExample
                                    }
                                }}
                                onFinish={onFinish}
                            >
                                <Tabs defaultActiveKey="1" items={items}/>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateService;
