import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import TemplateRepository from "./Repositories/TemplateRepository.ts";
import {Button, Form, Input, message, Popconfirm} from "antd";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import templateRepository from "./Repositories/TemplateRepository.ts";
import {BreadcrumbLink} from "../Shared/Types.ts";
import {Template} from "./Types.ts";
import CodeTextAreaComponent from "../Shared/CodeTextAreaComponent.tsx";

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/templates',
        title: 'Templates',
    },
    {
        href: '#',
        title: 'Edit',
    },
];

const EditTemplate: React.FC = () => {
    const [form] = Form.useForm();
    const {templateUuid} = useParams();
    const navigate = useNavigate();
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [messageApi, contextHolder] = message.useMessage();
    const [template, setTemplate] = useState<Template>();
    const [loading, setLoading] = useState<boolean>(true);
    const [code, setCode] = useState<string>('');

    const onFinish = async (values: Template) => {
        try {
            if (templateUuid != null) {
                values.uuid = templateUuid;
            }
            await templateRepository.update(values);

            messageApi.open({
                type: 'success',
                content: 'Template updated successfully',
            });

            setTimeout(() => navigate('/app/templates'), 2250);
        } catch (error) {
            message.error('Failed to update template: ' + error.message);
        }
    };

    const confirm: (uuid) => void = async (uuid) => {
        try {
            await templateRepository.destroy(uuid);

            messageApi.open({
                type: 'success',
                content: 'Template deleted successfully',
            });

            setTimeout(() => navigate('/app/templates'), 2250);
        } catch (error) {
            message.error('Failed to delete template: ' + error.message);
        }
    };

    useEffect(() => {
        setBreadcrumb(breadcrumbs);

        const fetchTemplate = async (templateUuid: string | undefined) => {
            try {
                const response:Template = await TemplateRepository.findByUuid(templateUuid);
                form.setFieldsValue(response);
                setCode(response.content)
                setTemplate(response)
            } catch (error) {
                console.error('Error fetching templates:', error);
            }
        };

        fetchTemplate(templateUuid);
        setLoading(false)
     }, []);

    if (loading) {
        return <>Loading...</>
    }

    return (
        <>
            {contextHolder}
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Templates</h3>

                <div className="flex bg-white flex-col mt-8">

                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">


                        <div
                            className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <h3 className="text-xl font-medium text-gray-700 mb-7">Update</h3>

                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label="Name:"
                                    name="name"
                                    rules={[{required: true, message: 'Please input the template name!'}]}
                                >
                                    <Input placeholder="Name"/>
                                </Form.Item>

                                <Form.Item
                                    label="Description:"
                                    name="description"
                                    rules={[{ required: true, message: 'Please input the template path!' }]}
                                >
                                    <Input placeholder="description" />
                                </Form.Item>

                                <CodeTextAreaComponent
                                    label={''}
                                    name={'content'}
                                    code={code}
                                    setCode={setCode}
                                    language={'json'}
                                />

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">Update</Button>
                                </Form.Item>
                            </Form>

                        </div>
                    </div>
                </div>

                <div className="flex bg-white flex-col mt-8">

                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">

                        <div
                            className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <h3 className="text-xl font-medium text-gray-700 mb-4">Delete</h3>

                            <p className="text-sm text-gray-700 mb-4">
                                Deleting this item will remove all associated data and cannot be reversed.
                                Please ensure you have backed up any important information before proceeding.
                            </p>

                            <Popconfirm
                                title="Delete"
                                description="Are you sure to delete this item?"
                                onConfirm={() => confirm(template.uuid)}
                                okText="Yes"
                                cancelText="No"
                                className={''}
                            >
                                <Button danger>Yes, I'll permanently delete it</Button>
                            </Popconfirm>

                        </div>
                    </div>
                </div>


            </div>

        </>
    );
};

export default EditTemplate;
