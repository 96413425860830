import React, {useEffect} from 'react';
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import {BreadcrumbLink} from "../Shared/Types.ts";
import ProjectCard from "./Components/ProjectCard.tsx";
import NewProjectCard from "./Components/NewProjectCard.tsx";
import { CloudOutlined, GithubOutlined, PlusOutlined } from '@ant-design/icons';

import {Button } from "antd";

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/deployments',
        title: 'Deployments',
    },
];

const DeploymentsList: React.FC = () => {
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
    }, []);

    return (
        <>
             <div className="container px-6 py-8 mx-auto">
                 <h3 className="text-3xl font-medium text-gray-700">Deployments</h3>

                 <div className="mt-5">
                     <div className="flex gap-4 "> {/* gap entre os botões e margem abaixo do grupo */}
                         <Button
                             type="default"
                             className="flex items-center gap-2 border border-gray-300 shadow-sm rounded-xl px-3 py-3 text-sm font-medium text-gray-800 hover:border-gray-400 hover:shadow-md transition"
                             icon={
                                 <span className="relative">
                                  <CloudOutlined className="text-xl" />
                                  <PlusOutlined className="absolute -bottom-1 -right-1 text-[11px] bg-white rounded-full text-blue-500" />
                                </span>
                             }
                         >
                             Cloud connections
                         </Button>

                         <Button
                             type="default"
                             className="flex items-center gap-2 border border-gray-300 shadow-sm rounded-xl px-3 py-3 text-sm font-medium text-gray-800 hover:border-gray-400 hover:shadow-md transition"
                             icon={
                                 <span className="relative">
                                      <GithubOutlined className="text-xl" />
                                      <PlusOutlined className="absolute -bottom-1 -right-1 text-[11px] bg-white rounded-full text-blue-500" />
                                    </span>
                             }
                         >
                             Git connections
                         </Button>
                     </div>
                 </div>

             </div>

            <div className="px-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                <ProjectCard name="Default" />
                <ProjectCard name="Oggo" servers={1} cameras={2} deployments={1} />
                <ProjectCard name="oggo-with-forge" servers={3} deployments={3} />
                <ProjectCard name="kampa-test" servers={1} deployments={1}  />
                <ProjectCard name="kampa-prod" servers={2} deployments={2} />
                <NewProjectCard />
            </div>
        </>
    );
};

export default DeploymentsList;
