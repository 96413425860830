import TerminalComponent from "../Components/TerminalComponent.tsx";
import {ReactTyped} from "react-typed";
import {typedExample} from "./Components/ExampleConsoleWritter.tsx";
import {Link} from "react-router-dom";

export default function CLICardComponent() {
    return (
        <section className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-8 mb-32">
            <div className="mx-auto max-w-4xl text-center mb-14">
                <h2 className="gradient-text text-blue-600 text-xl font-semibold mb-4">Kampa CLI</h2>
                <p className="text-4xl font-bold text-gray-900 mb-6">
                    A powerful tool to manage your development environments.
                </p>
            </div>


            <div className="flex flex-col lg:flex-row items-start">
                <div className="lg:w-2/5 bg-gray-100 p-6 rounded-lg">

                    <p className="text-xl text-gray-600 mb-4">
                        Environment Configuration - Easily create, manage, and switch between development environments
                    </p>
                    <p className="text-xl text-gray-600 mb-4">
                        Fast sharing among team members.
                    </p>
                    <p className="text-xl text-gray-600 mb-4">
                        Compatible with technologies like
                        <Link to={'/docs/d/docker-with-go-example'}>
                            <span className="font-semibold text-blue-600"> GoLang</span>
                        </Link>,
                        <Link to={'/docs/d/docker-with-python-and-django'}>
                            <span className="font-semibold text-blue-600"> Python with Django</span>
                        </Link>,
                        <Link to={'/docs/d/docker-with-php-84'}>
                            <span className="font-semibold text-blue-600"> PHP 8.4</span>
                        </Link>
                        , and
                        <Link to={'/docs/d/docker-nodejs-with-react-and-vite'}>
                            <span className="font-semibold text-blue-600"> NodeJS with React</span>
                        </Link>, among others.
                    </p>
                    <p className="text-xl text-gray-600 mb-6">
                        Avoid conflicts and save time with automated setups.
                    </p>
                    <div className="flex justify-center lg:justify-start space-x-4">
                        <Link to={'/docs/introduction'} >
                            <span className="sr-only">Learn more about Kampa.io documentation</span>
                            <button
                                className="px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition duration-200">
                                Learn more
                            </button>
                        </Link>
                        <Link to={'/login'}>
                            <button
                                className="px-4 py-2 border border-gray-800 text-gray-800 rounded-lg hover:bg-gray-200 transition duration-200">
                                Login to download the CLI
                            </button>
                        </Link>
                    </div>
                </div>


                <div className="lg:w-3/5 w-full  pl-4 pr-4">
                    <TerminalComponent>
                        <ReactTyped
                            strings={typedExample}
                            typeSpeed={25}
                            backSpeed={20}
                        />
                    </TerminalComponent>
                </div>
            </div>
        </section>
    );
}
