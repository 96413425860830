import React, { useState } from "react";
import { Link } from "react-router-dom";
import CodeComponent from "./CodeComponent.tsx";

const DocumentationInstallation: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState("mac");

    const installationInstructions = {
        mac: {
            description: "Unzip the file and move the binary to the correct directory and make it executable:",
            commands: `\`\`\`shell
unzip kampa.zip 
sudo mv kampa /usr/local/bin/kampa
chmod +x /usr/local/bin/kampa
\`\`\``,
        },
        windows: {
            description: "Open the kampa windows installer",
            steps: [
                "Open the installer as administrator",
                "We recommend to use C:\\kampa as the destination folder",
                "After fill all the settings, you'll be able to test on terminal",
            ],
            commands: [],
            note: "Close and reopen the terminal.",
        },
        linux: {
            description: "Move the binary to the correct directory and make it executable:",
            commands: `\`\`\`shell
sudo mv kampa /usr/local/bin/kampa
chmod +x /usr/local/bin/kampa
\`\`\``,
        },
    };

    return (
        <div className="container mx-auto p-6 text-gray-900 font-sans">
            {/* Installation Header */}
            <section className="mb-2">
                <h1 className="gradient-text text-4xl font-bold mb-6">Installation</h1>
            </section>

            <h2 className="text-2xl font-semibold mb-4">
                Create your account
            </h2>

            <p className="text-gray-600">
                To access the download, you need to create an account. Sign up now and get instant access!
            </p>

            <h2 className="mt-8 text-2xl font-semibold mb-4">
                Login
            </h2>
            <p className="text-gray-600">
                Only logged-in users can access the download. Please log in
                and go to <b>Kampa Client</b> to continue.
            </p>


            <div className="mt-6">
                <Link to="/login">
                    <button
                        className="bg-blue-600 text-white font-semibold py-3 px-6 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none">
                        Login
                    </button>
                </Link>
            </div>

            {/* Installation Instructions Section */}
            <div className="mt-8 p-6 bg-gray-100 rounded-lg">
                <h4 className="text-xl font-semibold text-gray-700">Installation Instructions</h4>
                <p className="mt-2 text-gray-600">
                    After downloading the Kampa client, move it to a directory included in your system’s <b>PATH</b> to
                    make it executable from any terminal location.
                </p>

                {/* Tabs for Different OS */}
                <div className="flex border-b border-gray-300 mt-4">
                    {Object.keys(installationInstructions).map((tab) => (
                        <button
                            key={tab}
                            className={`px-4 py-2 transition-colors ${
                                selectedTab === tab ? "border-b-2 border-blue-600 text-blue-600" : "text-gray-600"
                            }`}
                            onClick={() => setSelectedTab(tab)}
                            aria-selected={selectedTab === tab}
                        >
                            {tab.charAt(0).toUpperCase() + tab.slice(1)}
                        </button>
                    ))}
                </div>

                {/* Installation Instructions Content */}
                <div className="mt-4 text-gray-600">
                    <p>{installationInstructions[selectedTab].description}</p>

                    {installationInstructions[selectedTab].steps && (
                        <ol className="list-decimal pl-6 mt-2">
                            {installationInstructions[selectedTab].steps?.map((step, index) => (
                                <li key={index}>{step}</li>
                            ))}
                        </ol>
                    )}

                    {installationInstructions[selectedTab].commands?.length > 0 && (
                        <CodeComponent content={installationInstructions[selectedTab].commands} />
                    )}

                    {installationInstructions[selectedTab].note && (
                        <p className="mt-2 italic">{installationInstructions[selectedTab].note}</p>
                    )}
                </div>
                <p className={'mt-12'}>
                    After completing the previous process and adding it to your system's PATH, verify the installation by running the following command:
                </p>

                <CodeComponent content={`\`\`\`shell
kampa version
\`\`\`\
`} />
            </div>
        </div>
    );
};

export default DocumentationInstallation;
