import React, { ReactNode } from 'react';

interface TerminalComponentProps {
    children: ReactNode;
}

const TerminalComponent: React.FC<TerminalComponentProps> = ({ children }) => {
    return (
        <div className="w-full code-snippet bg-gray-900 text-gray-200 font-mono text-sm rounded-md mx-auto mt-1 shadow-2xl">
            <div className="flex justify-between items-center bg-gray-800 px-4 py-2 rounded-t-md">
                <div className="flex space-x-2">
                    <span className="w-3 h-3 bg-red-500 rounded-full"></span>
                    <span className="w-3 h-3 bg-yellow-500 rounded-full"></span>
                    <span className="w-3 h-3 bg-green-500 rounded-full"></span>
                </div>
            </div>
            <div className="px-4 py-6">{children}</div>
        </div>
    );
};

export default TerminalComponent;
