import React from 'react';

const DocumentationIntroduction: React.FC = () => {
    return (
        <div className="container mx-auto p-6 text-gray-900 font-sans">
            <section className="mb-12">
                <h1 className="gradient-text text-4xl font-bold mb-6">Introduction</h1>

                <div className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">What is Kampa.io?</h2>
                    <p className="text-lg leading-relaxed">
                        Kampa.io is a powerful tool designed to simplify the setup and sharing of local development
                        environments. It provides developers with the ability to create consistent, reproducible
                        environments that can be easily shared across teams. No more "it works on my machine" problems –
                        Kampa.io ensures that every member of your team is working in the same environment, minimizing
                        configuration issues and maximizing productivity.
                    </p>
                </div>

                <div className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Key Features</h2>

                    <div className="mb-4">
                        <h3 className="text-xl font-semibold mb-2">Easy Environment Setup:</h3>
                        <p className="text-lg leading-relaxed">
                            Create local development environments with just a few clicks. Kampa.io supports various
                            programming languages and frameworks, making it versatile and adaptable to your project
                            needs.
                        </p>
                    </div>

                    <div className="mb-4">
                        <h3 className="text-xl font-semibold mb-2">Shareable Environments:</h3>
                        <p className="text-lg leading-relaxed">
                            Share your development environment with team members effortlessly. Kampa.io generates unique
                            links that allow others to replicate your setup quickly, ensuring consistency across all
                            workstations.
                        </p>
                    </div>

                    <div className="mb-4">
                        <h3 className="text-xl font-semibold mb-2">Version Control Integration:</h3>
                        <p className="text-lg leading-relaxed">
                            Seamlessly integrate with your favorite version control systems. Kampa.io tracks changes in
                            your environment configurations, enabling you to roll back to previous versions if needed.
                        </p>
                    </div>

                    <div className="mb-4">
                        <h3 className="text-xl font-semibold mb-2">Customizable Templates:</h3>
                        <p className="text-lg leading-relaxed">
                            Utilize predefined templates or create your own to standardize environments for different
                            projects or teams. Save time and ensure best practices are followed.
                        </p>
                    </div>

                    <div className="mb-4">
                        <h3 className="text-xl font-semibold mb-2">Cross-Platform Compatibility:</h3>
                        <p className="text-lg leading-relaxed">
                            Whether you are on Windows, macOS, or Linux, Kampa.io works flawlessly across all major
                            operating systems.
                        </p>
                    </div>
                </div>

                <div className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Why Choose Kampa.io?</h2>
                    <p className="text-lg leading-relaxed">
                        Kampa.io is designed with developers in mind. We understand the challenges of setting up and
                        maintaining development environments, especially in a collaborative setting. Our goal is to make
                        these tasks as straightforward as possible, reducing friction and enhancing your development
                        workflow.
                    </p>
                </div>

                <div className="mb-8">
                    <h3 className="text-xl font-semibold mb-2">Consistency</h3>
                    <p className="text-lg leading-relaxed">
                        Ensure every team member works in the same environment, reducing "it works on my machine"
                        issues.
                    </p>
                </div>

                <div className="mb-8">
                    <h3 className="text-xl font-semibold mb-2">Efficiency</h3>
                    <p className="text-lg leading-relaxed">
                        Save time on environment setup and focus more on coding.
                    </p>
                </div>

                <div className="mb-8">
                    <h3 className="text-xl font-semibold mb-2">Scalability</h3>
                    <p className="text-lg leading-relaxed">
                        Adaptable to projects of any size, from solo endeavors to large team collaborations.
                    </p>
                </div>

                <div className="mb-8">
                    <p className="text-lg leading-relaxed">
                        Join the growing community of developers who trust Kampa.io for their local environment needs.
                        Get started today and experience a seamless development process like never before.
                    </p>
                </div>
            </section>
        </div>
    );
};

export default DocumentationIntroduction;
