import React, {useEffect, useState} from 'react';
import {Button, Form, message, Select} from 'antd';
import {Key, KeyAuthTypeOptions, KeyOwnerTypeOptions, KeyTypeOptions} from "./Types.ts";
import KeyRepository from "./Repositories/KeyRepository.ts";
import {useNavigate} from "react-router-dom";
import {BreadcrumbLink} from "../Shared/Types.ts";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import UsersSelectComponent from "../Shared/UsersSelectComponent.tsx";

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/keys',
        title: 'Keys',
    },
    {
        href: '/app/keys/create',
        title: 'Create',
    },
];

const CreateKey: React.FC = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
    }, []);

    const onFinish = async (values: Key) => {
        setLoading(true)

        try {
            await KeyRepository.save(values);
            messageApi.open({
                type: 'success',
                content: 'Key created successfully',
            });
            setTimeout(() => navigate('/app/keys'), 2250);
        } catch (error) {
            message.error('Failed to create key: ' + error.message);
        }
        setLoading(false);
    };

    return (
        <>
            {contextHolder}
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Keys</h3>

                <div className="flex bg-white flex-col mt-8">
                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">

                            <Form
                                layout="vertical"
                                form={form}
                                initialValues={{
                                    visibility: 'private'
                                }}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label="Type:"
                                    name="type"
                                    rules={[{ required: true, message: 'Please input the type!' }]}
                                >
                                    <Select
                                        options={KeyTypeOptions}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Auth Type:"
                                    name="auth_type"
                                    rules={[{ required: true, message: 'Please input the type!' }]}
                                >
                                    <Select
                                        options={KeyAuthTypeOptions}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Owner Type:"
                                    name="owner_type"
                                    rules={[{ required: true, message: 'Please input the owner type!' }]}
                                >
                                    <Select
                                        options={KeyOwnerTypeOptions}
                                    />
                                </Form.Item>

                                <UsersSelectComponent
                                    label={"User"}
                                    name={"owner_uuid"}
                                    isMultiple={false}
                                />

                                <Form.Item>
                                    <Button
                                        loading={loading}
                                        type="primary" htmlType="submit">Save</Button>
                                </Form.Item>
                            </Form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateKey;