import { create } from "zustand";
import { persist, createJSONStorage, PersistOptions } from "zustand/middleware";
import { BreadcrumbLink } from "../Shared/Types.ts";

type BreadcrumbStore = {
    links: BreadcrumbLink[];
    setBreadcrumb: (links: BreadcrumbLink[]) => void;
    clearBreadcrumb: () => void;
};


type BreadcrumbPersist = PersistOptions<BreadcrumbStore>;

const useBreadcrumbStore = create<BreadcrumbStore>()(
    persist<BreadcrumbStore>(
        (set) => ({
            links: [],

            setBreadcrumb: (links: BreadcrumbLink[]) =>
                set({
                    links: [
                        {
                            href: "/app/dashboard",
                            title: "HOME_ICON",
                        },
                        ...links,
                    ],
                }),

            clearBreadcrumb: () =>
                set({
                    links: [
                        {
                            href: "/app/dashboard",
                            title: "HOME_ICON",
                        },
                    ],
                }),
        }),
        {
            name: "breadcrumb-store",
            storage: createJSONStorage(() => localStorage),
        } as BreadcrumbPersist
    )
);

export default useBreadcrumbStore;
