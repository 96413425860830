import axiosInstance from "../../../axios.ts";
import {PaginatedResponse} from "../../Shared/Types.ts";
import {UserInvite} from "../Types.ts";

const findAll = async (page: number, hasPagination: boolean): Promise<PaginatedResponse<UserInvite>> => {
    try {
        const response = await axiosInstance.get(`/users-invites?page=${page}&has_pagination=${hasPagination}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'List failed');
    }
};

const findByUuid = async (inviteUuid): Promise<UserInvite> => {
    try {
        const response = await axiosInstance.get(`/users-invites/${inviteUuid}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Find item failed');
    }
}

const save = async (data: UserInvite) => {
    try {
        const response = await axiosInstance.post('/users-invites', data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Registration failed');
    }
};

const destroy = async (uuid: string): Promise<boolean> => {
    await axiosInstance.delete(`/users-invites/${uuid}`);
    return true;
};

export default {
    findAll,
    findByUuid,
    save,
    destroy,
};