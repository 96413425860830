import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Button, message, Popconfirm } from "antd";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import { BreadcrumbLink } from "../Shared/Types.ts";
import {LogEntry} from "./Types.ts";
import LogRepository from "./Repositories/LogRepository.ts";
import LogReader from "./Components/LogReader.tsx";

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/environments',
        title: 'Environments',
    },
    {
        href: '/app/logs',
        title: 'Log Viewer',
    },
];


const LogViewer: React.FC = () => {
    const { logUuid } = useParams();
    const navigate = useNavigate();
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [messageApi, contextHolder] = message.useMessage();
    const [log, setLog] = useState<LogEntry>();

    const confirm = async (uuid: string) => {
        try {
            await LogRepository.destroy(uuid);
            messageApi.open({ type: 'success', content: 'Log deleted successfully' });
            setTimeout(() => navigate('/app/environments'), 2250);
        } catch (error) {
            message.error('Failed to delete key: ' + error.message);
        }
    };

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
        const fetchLog = async (logUuid: string | undefined) => {
            try {
                const response: LogEntry = await LogRepository.findByUuid(logUuid);
                setLog(response);
            } catch (error) {
                console.error('Error fetching logs:', error);
            }
        };
        fetchLog(logUuid);
    }, []);

    return (
        <>
            {contextHolder}
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Log viewer</h3>

                <div className="flex bg-white flex-col mt-8">
                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <LogReader url={"logs/" + logUuid + "/stream"} />
                        </div>
                    </div>
                </div>

                <div className="flex bg-white flex-col mt-8">
                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <h3 className="text-xl font-medium text-gray-700 mb-4">Delete</h3>
                            <p className="text-sm text-gray-700 mb-4">
                                Deleting this item will remove all associated data and cannot be reversed.
                                Please ensure you have backed up any important information before proceeding.
                            </p>
                            <Popconfirm
                                title="Delete"
                                description="Are you sure you want to delete this item?"
                                onConfirm={() => confirm(log?.uuid ?? '')}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button danger>Yes, I'll permanently delete it</Button>
                            </Popconfirm>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LogViewer;
