import { ReactTyped } from "react-typed";
import { Link } from "react-router-dom";
import TerminalComponent from "../../Components/TerminalComponent.tsx";

const arrow:string = '<span class="text-orange-400">➜</span>' //
const ok:string = '<span class="text-green-300">✔</span>' // ➜
const info:string = '<span class="bg-blue-600 text-white px-1">INFO</span>';
const repository:string = ' <span class="gradient-text">project</span> <span class="text-amber-300">✗</span>'

const commandExample:string[]  = [
    arrow + repository + " kampa cmd list<br />" +
    "List of available commands: 2<br />" +
    '<span class="text-green-300">migrate</span> Run php artisan migrate<br />' +
    '<span class="text-green-300">cache:clear</span> it will clear the cache on a prompted container<br />' +
    arrow + repository + " kampa cmd run migrate<br />" +
    ok + " Running migrate<br />" +
    "Attempting to execute command inside container: environment-backend-1<br />" +
    "Command: php artisan migrate<br />" +
    info + " Nothing to migrate.<br />" +
    arrow + repository + " "
];


export default function CommandsCardComponent() {
    return (
        <section className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-8 mb-32">
            <div className="mx-auto max-w-4xl text-center mb-14">
                <p className="text-4xl font-bold text-gray-900 mb-6">
                    Standardize, Automate, and Share Development Commands.
                </p>
            </div>

            <div className="flex flex-col lg:flex-row items-start">

                <div className="lg:w-3/5 w-full pl-4 pr-4">
                    <TerminalComponent>
                        <ReactTyped
                            strings={commandExample}
                            typeSpeed={25}
                            backSpeed={20}
                        />
                    </TerminalComponent>
                </div>


                <div className="lg:w-2/5 bg-gray-100 p-6 rounded-lg">
                    <p className="text-xl text-gray-600 mb-4">
                        <strong>Create custom commands</strong> and make them available across your organization.
                    </p>
                    <p className="text-xl text-gray-600 mb-4">
                        Execute commands <strong>on your host machine, in a prompted container, or a fixed container</strong>.
                    </p>
                    <p className="text-xl text-gray-600 mb-4">
                        Share workflows easily with your team and <strong>ensure consistency</strong> in development processes.
                    </p>

                    {/* Call to Actions */}
                    <div className="flex justify-center lg:justify-start space-x-4 mt-6">
                        <Link to={'/docs/d/commands'}>
                            <button className="px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition duration-200">
                                Learn More
                            </button>
                        </Link>
                        <Link to={'/app/commands/create'}>
                            <button className="px-4 py-2 border border-gray-800 text-gray-800 rounded-lg hover:bg-gray-200 transition duration-200">
                                Create a Command
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}
