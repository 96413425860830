import axiosInstance from "../../../../axios.ts";
import {PaginatedResponse} from '../../../Shared/Types.ts';
import {Service} from "../Types.ts";

const findAll = async (page: number, hasPagination: boolean): Promise<PaginatedResponse<Service>> => {
    try {
        const response = await axiosInstance.get(`/services?page=${page}&has_pagination=${hasPagination}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'List failed');
    }
};

const findByServiceType = async (type: string): Promise<Service[]> => {
    try {
        const response = await axiosInstance.get(`/services?has_pagination=false&type=${type}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'List failed');
    }
}

const findOfficialServices = async (type: string): Promise<Service[]> => {
    try {
        const response = await axiosInstance.get(`/official-services?type=${type}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'List failed');
    }
}

const save = async (service: Service): Promise<Service> => {
    try {
        const response = await axiosInstance.post(`/services`, service);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Save failed');
    }
};

const findByUuid = async (uuid: string | undefined) => {
    try {
        const response = await axiosInstance.get(`/services/${uuid}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Fetch failed');
    }
}

const update = async (service: Service): Promise<boolean> => {
    await axiosInstance.patch(`/services/${service.uuid}`, service);
    return true;
};

const destroy = async (uuid: string): Promise<boolean> => {
    await axiosInstance.delete(`/services/${uuid}`);
    return true;
};

export default {
    save,
    findAll,
    findByServiceType,
    findOfficialServices,
    findByUuid,
    update,
    destroy
};