import React, {useEffect} from 'react';
import {Link, useSearchParams} from "react-router-dom";
import {BreadcrumbLink} from "../Shared/Types.ts";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import { Button } from 'antd';
import {LogEntry} from "./Types.ts";
import LogRepository from "./Repositories/LogRepository.ts";
import FiltersManager from "../Shared/ListWIthFilters/FiltersManage.ts";
import GenericListWithFilters from "../Shared/ListWIthFilters/GenericListWithFilters.tsx";

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/environments',
        title: 'Environments',
    },
    {
        href: '/app/logs',
        title: 'Logs',
    },
];

const renderLogEntry = (log: LogEntry) => (
    <>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="flex items-center">
                <div className="ml-4">
                    <div className="text-sm font-medium leading-5 text-gray-900">{log.environment}</div>
                </div>
            </div>
        </td>

        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="flex items-center">
                <div className="ml-4">
                    <div className="text-sm font-medium leading-5 text-gray-900">{log.username}</div>
                </div>
            </div>
        </td>

        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
            <div className="flex items-center">
                <div className="ml-4">
                    <div className="text-sm font-medium leading-5 text-gray-900">{log.created_at}</div>
                </div>
            </div>
        </td>

        <td className="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap border-b border-gray-200">

            <Link to={`/app/logs/${log.uuid}`}>
                <Button>See log</Button>
            </Link>
        </td>
    </>
);

const LogsList: React.FC = () => {
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [searchParams] = useSearchParams();
    const environmentUuid = searchParams.get("environment_uuid"); // ✅ Pega o valor do parâmetro

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
    }, []);

    return (
        <>
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Logs</h3>
            </div>

            <GenericListWithFilters<LogEntry>
                fetchData={LogRepository.findAll}
                renderItem={renderLogEntry}
                initialFilters={new FiltersManager({
                    page: 1,
                    hasPagination: true,
                    environmentUuid: environmentUuid,
                })}
                columns={['Environment', 'Username', 'Date', '']}
            />;
        </>
    );
}

export default LogsList;
