import React, { useEffect, useState } from "react";
import useBreadcrumbStore from "../../Stores/useBreadcrumbStore.tsx";
import { BreadcrumbLink } from "../../Shared/Types.ts";
import {Template} from "../../TemplatesManager/Types.ts";
import TemplateRepository from "../../TemplatesManager/Repositories/TemplateRepository.ts";
import {Link} from "react-router-dom";

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: "/app/local-environments",
        title: 'Local Environments',
    },
    {
        href: "/app/local-environments/templates",
        title: "Templates",
    },
];

const EnvironmentsList: React.FC = () => {
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<Template[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const response: Template[] = await TemplateRepository.findAllWithoutPagination();
            setData(response);
            setLoading(false)


        }

        fetchData();
        setBreadcrumb(breadcrumbs);
    }, []);


    if (loading) {
        return <>
        Loading...
        </>
    }

    return (
        <div className="container px-6 py-8 mx-auto">
            <h3 className="text-3xl font-medium text-gray-700">Environments Templates</h3>

            <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {data.map((template) => (
                    <Link to={`/app/local-environments/create-from-template/${template.uuid}`}>
                        <div key={template.uuid} className="p-5 border rounded-lg shadow-sm bg-white hover:shadow-md transition">
                            <div className="flex items-center space-x-3">
                                {/*<div className="text-orange-500 text-2xl">🔥</div>*/}
                                <h4 className="text-lg font-semibold">{template.name}</h4>
                            </div>
                            <p className="text-gray-600 mt-2">{template.description}</p>
                            {/*<div className="flex justify-between items-center mt-4 text-gray-500 text-sm">*/}
                            {/*    <span>⭐ 1.2K</span>*/}
                            {/*    <span>⬇ {template.downloads}</span>*/}
                            {/*</div>*/}
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default EnvironmentsList;
