import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface OnboardStore {
    isOnboarded: boolean;
    setOnboarded: (isOnboarded: boolean) => void;
    clearOnboard: () => void;
}

const useOnboardStore = create<OnboardStore>()(
    persist(
        (set) => ({
            isOnboarded: false,
            setOnboarded: (isOnboarded) => set({ isOnboarded }),

            clearOnboard: () => set({ isOnboarded: false }),
        }),
        {
            name: "onboard-store",
            storage: createJSONStorage(() => localStorage),
        }
    )
);

export default useOnboardStore;
