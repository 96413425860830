import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const images = [
    "/images/animation/step1.webp",
    "/images/animation/step2.webp",
    "/images/animation/step3.webp",
    "/images/animation/step4.webp",
];

export default function ImageCarousel() {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 4500);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="relative flex items-center justify-center bg-gray-100 p-8 mb-24 w-full">
            <div className="w-full max-w-6xl overflow-hidden rounded-lg shadow-xl border border-gray-200">
                <AnimatePresence mode="wait">
                    <motion.img
                        key={index}
                        src={images[index]}
                        alt="environment area"
                        className="w-full h-auto object-cover"
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -50 }}
                        transition={{ duration: 0.8 }}
                    />
                </AnimatePresence>
            </div>
        </div>
    );
}
