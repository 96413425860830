import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import KeyRepository from "./Repositories/KeyRepository.ts";
import { Button, Form, Input, message, Popconfirm, Select } from "antd";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import { BreadcrumbLink } from "../Shared/Types.ts";
import { Key, KeyAuthTypeOptions, KeyOwnerTypeOptions, KeyTypeOptions } from "./Types.ts";
import UsersSelectComponent from "../Shared/UsersSelectComponent.tsx";
import { EyeInvisibleOutlined, EyeTwoTone, CopyOutlined } from '@ant-design/icons';

const breadcrumbs: BreadcrumbLink[] = [
    { href: '/app/keys', title: 'Keys' },
    { href: '#', title: 'Edit' },
];

const EditKey: React.FC = () => {
    const [form] = Form.useForm();
    const { keyUuid } = useParams();
    const navigate = useNavigate();
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [messageApi, contextHolder] = message.useMessage();
    const [key, setKey] = useState<Key>();
    const [keyVisible, setKeyVisible] = useState(false);

    const confirm = async (uuid: string) => {
        try {
            await KeyRepository.destroy(uuid);
            messageApi.open({ type: 'success', content: 'Key deleted successfully' });
            setTimeout(() => navigate('/app/keys'), 2250);
        } catch (error) {
            message.error('Failed to delete key: ' + error.message);
        }
    };

    const copyToClipboard = async () => {
        if (key?.key) {
            try {
                await navigator.clipboard.writeText(key.key);
                message.success('Key copied to clipboard!');
            } catch (err) {
                message.error('Failed to copy key');
            }
        }
    };

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
        const fetchKey = async (keyUuid: string | undefined) => {
            try {
                const response: Key = await KeyRepository.findByUuid(keyUuid);
                form.setFieldsValue(response);
                setKey(response);
            } catch (error) {
                console.error('Error fetching keys:', error);
            }
        };
        fetchKey(keyUuid);
    }, []);

    return (
        <>
            {contextHolder}
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Keys</h3>

                <div className="flex bg-white flex-col mt-8">
                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <Form layout="vertical" form={form}>
                                <Form.Item label="Type:" name="type" rules={[{ required: true, message: 'Please input the type!' }]}>
                                    <Select disabled options={KeyTypeOptions} />
                                </Form.Item>

                                <Form.Item label="Auth Type:" name="auth_type" rules={[{ required: true, message: 'Please input the auth type!' }]}>
                                    <Select disabled options={KeyAuthTypeOptions} />
                                </Form.Item>

                                <Form.Item label="Owner Type:" name="owner_type" rules={[{ required: true, message: 'Please input the owner type!' }]}>
                                    <Select disabled options={KeyOwnerTypeOptions} />
                                </Form.Item>

                                <UsersSelectComponent label="User" name="owner_uuid" isMultiple={false} disabled={true} />

                                <Form.Item label="Key">
                                    <Input
                                        disabled
                                        value={keyVisible ? key?.key : key?.key?.substring(0, 10) + '...'}
                                        readOnly
                                        suffix={
                                            <>
                                                <Button
                                                    type="text"
                                                    className="bg-gray-50 border rounded-md px-3 py-1 hover:bg-gray-300"
                                                    icon={keyVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                                                    onClick={() => setKeyVisible(!keyVisible)}
                                                >{keyVisible ? "Hide Key" : "Show Key"}</Button>
                                                {keyVisible && (
                                                     <Button
                                                        type="text"
                                                        className="bg-gray-50 border rounded-md px-3 py-1 hover:bg-gray-300 ml-2"
                                                        icon={<CopyOutlined />}
                                                        onClick={copyToClipboard}
                                                    >Copy key</Button>
                                                )}
                                            </>
                                        }
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>

                <div className="flex bg-white flex-col mt-8">
                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <h3 className="text-xl font-medium text-gray-700 mb-4">Delete</h3>
                            <p className="text-sm text-gray-700 mb-4">
                                Deleting this item will remove all associated data and cannot be reversed.
                                Please ensure you have backed up any important information before proceeding.
                            </p>
                            <Popconfirm
                                title="Delete"
                                description="Are you sure you want to delete this item?"
                                onConfirm={() => confirm(key?.uuid ?? '')}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button danger>Yes, I'll permanently delete it</Button>
                            </Popconfirm>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditKey;
