import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import {Organization} from "../Organizations/Types.ts";

interface UserState {
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    createdAt: string;
    updatedAt: string;
    token: string;
    role: string;
    isLoggedIn: boolean;
    organization: Organization;
    setUser: (user: {
        first_name: string;
        last_name: string;
        email: string;
        username: string;
        createdAt: string;
        updatedAt: string;
        role: string;
        token: string;
        organization?: Organization;
    }) => void;
    updateOrganizationStatus: (status: string) => void;
    clearUser: () => void;
}

const useUserStore = create<UserState>()(
    persist(
        (set) => ({
            firstName: "",
            lastName: "",
            email: "",
            username: "",
            createdAt: "",
            updatedAt: "",
            token: "",
            role: "",
            isLoggedIn: false,
            organization: null,

            setUser: (user) =>
                set({
                    firstName: user.first_name,
                    lastName: user.last_name,
                    email: user.email,
                    username: user.username,
                    createdAt: user.createdAt,
                    updatedAt: user.updatedAt,
                    role: user.role,
                    token: user.token,
                    isLoggedIn: true,
                    organization: user.organization,
                }),

            updateOrganizationStatus: (status: string) =>
                set((state) => ({
                    organization: state.organization ? { ...state.organization, status } : null,
                })),

            clearUser: () =>
                set({
                    firstName: "",
                    lastName: "",
                    email: "",
                    username: "",
                    createdAt: "",
                    updatedAt: "",
                    token: "",
                    role: "",
                    isLoggedIn: false,
                    organization: null,
                }),
        }),
        {
            name: "user-store", // unique name
            storage: createJSONStorage(() => localStorage),
        }
    )
);

export default useUserStore;
