import axiosInstance from "../../../axios.ts";
import {PaginatedResponse} from '../../Shared/Types';
import {LogEntry} from "../Types.ts";
import {AxiosResponse} from "axios";
import FiltersManager from "../../Shared/ListWIthFilters/FiltersManage.ts";

const findAll = async (filters: FiltersManager): Promise<PaginatedResponse<LogEntry>> => {
    const page = filters.get("page")
    const hasPagination = filters.get("hasPagination")
    const environmentUuid = filters.get("environmentUuid");

    try {
        const response = await axiosInstance.get(`/logs?page=${page}&has_pagination=${hasPagination}&environment_uuid=${environmentUuid}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'List failed');
    }
}

const findByUuid = async (uuid: string | undefined) => {
    try {
        const response: AxiosResponse<LogEntry> = await axiosInstance.get(`/logs/${uuid}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Fetch failed');
    }
}

const destroy = async (uuid: string): Promise<boolean> => {
    await axiosInstance.delete(`/logs/${uuid}`);
    return true;
};

export default {
    findAll,
    findByUuid,
    destroy
}