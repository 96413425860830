import React, { useEffect, useRef } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { tomorrow } from "react-syntax-highlighter/dist/cjs/styles/prism";
import { Form, Input } from "antd";
import { TextAreaRef } from "antd/es/input/TextArea";

const { TextArea } = Input;

interface CodeRules {
    required: boolean;
    message: string;
}

interface CodeTextAreaComponentProps {
    label: string;
    name: string | number | (string | number)[];
    code: string;
    setCode: (value: string) => void;
    language: string;
    rules?: CodeRules[];
}

const CodeTextAreaComponent: React.FC<CodeTextAreaComponentProps> = ({
                                                                         name,
                                                                         label,
                                                                         rules = [], // Define um valor padrão vazio para evitar erro
                                                                         code,
                                                                         setCode,
                                                                         language
                                                                     }) => {
    const textAreaRef = useRef<TextAreaRef>(null);
    const highlighterRef = useRef<HTMLDivElement>(null);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCode(event.target.value);
    };

    const syncScroll = () => {
        if (textAreaRef.current?.resizableTextArea?.textArea && highlighterRef.current) {
            highlighterRef.current.scrollTop = textAreaRef.current.resizableTextArea.textArea.scrollTop;
            highlighterRef.current.scrollLeft = textAreaRef.current.resizableTextArea.textArea.scrollLeft;
        }
    };

    useEffect(() => {
        const textAreaElement = textAreaRef.current?.resizableTextArea?.textArea;
        if (textAreaElement) {
            textAreaElement.addEventListener("scroll", syncScroll);
            return () => textAreaElement.removeEventListener("scroll", syncScroll);
        }
    }, []);

    return (
        <Form.Item
            label={label}
            name={name}
            rules={rules}
        >
            <div style={{ position: "relative" }}>
                <div
                    ref={highlighterRef}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        pointerEvents: "none",
                        whiteSpace: "pre-wrap",
                        overflow: "hidden",
                        padding: "8px",
                        borderRadius: '10px',
                        lineHeight: "1.5",
                        fontSize: "14px",
                        fontFamily: "monospace",
                        background: "#2d2d2d",
                    }}
                >
                    <SyntaxHighlighter
                        language={language}
                        style={tomorrow}
                        customStyle={{
                            margin: 0,
                            padding: 0,
                            minHeight: "150px",
                            overflow: "hidden",
                            background: "none",
                            fontSize: "14px",
                            lineHeight: "1.5",
                            fontFamily: "monospace",
                        }}
                    >
                        {code}
                    </SyntaxHighlighter>
                </div>
                <TextArea
                    ref={textAreaRef}
                    value={code}
                    onChange={handleChange}
                    autoSize={{ minRows: 15 }}
                    style={{
                        background: "transparent",
                        color: "transparent",
                        caretColor: "white",
                        zIndex: 1,
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        border: "none",
                        padding: "8px",
                        fontSize: "14px",
                        lineHeight: "1.5",
                        fontFamily: "monospace",
                    }}
                />
            </div>
        </Form.Item>
    );
};

export default CodeTextAreaComponent;
