import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import "highlight.js/styles/base16/dracula.css"; // Dark theme

interface CodeComponent {
    content?: string;
}

const CodeComponent: React.FC<CodeComponent> = ({content}) => {

    return (
        <>
            <div className="rounded-md w-full overflow-hidden">
                <ReactMarkdown
                    className="prose max-w-none"
                    rehypePlugins={[rehypeHighlight]}
                >
                    {content}
                </ReactMarkdown>
            </div>
        </>
    )
}

export default CodeComponent;