import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import CommandRepository from "./Repositories/CommandRepository.ts";
import {Button, Form, Input, message, Popconfirm, Select, Switch} from "antd";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import { BreadcrumbLink } from "../Shared/Types.ts";
import { Command } from "./Types.ts";
import TeamSelectComponent from "../Shared/TeamSelectComponent.tsx";

const breadcrumbs: BreadcrumbLink[] = [
    { href: '/app/commands', title: 'Commands' },
    { href: '#', title: 'Edit' },
];

const EditCommand: React.FC = () => {
    const [form] = Form.useForm();
    const { commandUuid } = useParams();
    const navigate = useNavigate();
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [messageApi, contextHolder] = message.useMessage();
    const [command, setCommand] = useState<Command>();
    const typeValue = Form.useWatch('type', form);
    const isFixedContainer = Form.useWatch('is_fixed_container', form);
    const [loading, setLoading] = useState<boolean>(true);

    const onFinish = async (values: Command) => {
        try {
            values['uuid'] = commandUuid
            await CommandRepository.update(values);
            messageApi.open({
                type: 'success',
                content: 'Command updated successfully',
            });
            setTimeout(() => navigate('/app/commands'), 2250);
        } catch (error) {
            message.error('Failed to update command: ' + error.message);
        }
    };

    const confirm = async (uuid: string) => {
        try {
            await CommandRepository.destroy(uuid);
            messageApi.open({ type: 'success', content: 'Command deleted successfully' });
            setTimeout(() => navigate('/app/commands'), 2250);
        } catch (error) {
            message.error('Failed to delete command: ' + error.message);
        }
    };

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
        const fetchCommand = async (commandUuid: string | undefined) => {
            try {
                const response: Command = await CommandRepository.findByUuid(commandUuid);
                form.setFieldsValue(response);
                setCommand(response);
            } catch (error) {
                console.error('Error fetching commands:', error);
            }
        };

        fetchCommand(commandUuid);
        setLoading(false)
    }, []);

    if (loading) {
        return <>Loading</>
    }

    return (
        <>
            {contextHolder}
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Commands</h3>

                <div className="flex bg-white flex-col mt-8">
                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <Form
                                layout="vertical"
                                form={form}
                                initialValues={command}
                                onFinish={onFinish}
                            >
                                <TeamSelectComponent
                                    name="team_uuid"
                                    label="Team:"
                                    placeholder="Select one of your teams"
                                    includeAll={true}
                                    rules={[{ required: false, message: 'Select one team' }]}
                                />

                                <Form.Item
                                    label="Execution Type:"
                                    name="type"
                                    rules={[{ required: true, message: 'Please select where to run the command!' }]}
                                >
                                    <Select placeholder="Select execution type">
                                        <Select.Option value="host">Host</Select.Option>
                                        <Select.Option value="container">Container</Select.Option>
                                    </Select>
                                </Form.Item>

                                {typeValue === 'container' && (
                                    <Form.Item
                                        label="Is Fixed Container?"
                                        name="is_fixed_container"
                                        valuePropName="checked"
                                    >
                                        <Switch />
                                    </Form.Item>
                                )}

                                {typeValue === 'container' && isFixedContainer && (
                                    <Form.Item
                                        label="Container Name:"
                                        name="container"
                                        rules={[{ required: true, message: 'Please input the container name!' }]}
                                    >
                                        <Input placeholder="Container" />
                                    </Form.Item>
                                )}

                                <Form.Item
                                    label="Name:"
                                    name="name"
                                    rules={[{ required: true, message: 'Please input the command name!' }]}
                                >
                                    <Input placeholder="Name" />
                                </Form.Item>

                                <Form.Item
                                    label="Identifier:"
                                    name="identifier"
                                    rules={[
                                        { required: true, message: 'Please input the identifier!' },
                                        {
                                            pattern: /^[a-zA-Z0-9_:.-]+$/,
                                            message: 'Only letters, numbers, colons (:), underscores (_), hyphens (-), and periods (.) are allowed!'
                                        }
                                    ]}
                                >
                                    <Input placeholder="Identifier" />
                                </Form.Item>

                                <Form.Item
                                    label="Description (optional):"
                                    name="description"
                                >
                                    <Input.TextArea placeholder="Command description" rows={3} />
                                </Form.Item>

                                <Form.Item
                                    label="Command:"
                                    name="command"
                                    rules={[{ required: true, message: 'Please input the command!' }]}
                                >
                                    <Input placeholder="Enter command here" />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">Save</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>

                <div className="flex bg-white flex-col mt-8">
                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <h3 className="text-xl font-medium text-gray-700 mb-4">Delete</h3>
                            <p className="text-sm text-gray-700 mb-4">
                                Deleting this item will remove all associated data and cannot be reversed.
                                Please ensure you have backed up any important information before proceeding.
                            </p>
                            <Popconfirm
                                title="Delete"
                                description="Are you sure you want to delete this item?"
                                onConfirm={() => confirm(command?.uuid ?? '')}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button danger>Yes, I'll permanently delete it</Button>
                            </Popconfirm>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditCommand;
