import React, {useEffect, useState} from "react";
import { Form, Select } from "antd";
import {Team} from "../Teams/Types.ts";
import teamRepository from "../Teams/Repositories/TeamRepository.ts";

interface TeamRule {
    required: boolean,
    message: string,
}

interface TeamSelectComponentProps {
    name: string;
    label: string;
    placeholder?: string;
    rules?: TeamRule[];
    disabled?: boolean;
    includeAll?: boolean
}

const TeamSelectComponent: React.FC<TeamSelectComponentProps> = ({ name, label, placeholder, rules, disabled, includeAll }) => {
    const [options, setOptions] = useState(null)
    const [loading, setLoading] = useState<boolean>(true)

    const fetchData = async () => {
        const response: Team[] = await teamRepository.findAllWithoutPagination();

        const formattedTeams = response?.map(item => ({
            value: item.uuid,
            label: item.name,
        }));

        if (includeAll) {
            setOptions([{ value: null, label: "All teams" }, ...formattedTeams])
        } else {
            setOptions(formattedTeams);
        }
    }

    useEffect(() => {
        fetchData()
        setLoading(false)
    }, []);

    if (loading)
        return 'loading teams'

    return (
        <Form.Item
            label={label}
            name={name}
            rules={rules}>
                <Select
                    disabled={disabled}
                    showSearch
                    placeholder={placeholder}
                    optionFilterProp="label"

                    options={options}
            />
        </Form.Item>
    );
};

export default TeamSelectComponent;
