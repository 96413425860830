import React from 'react';
import CodeComponent from "./CodeComponent.tsx";

const DocumentationPrerequisites: React.FC = () => {
    return (
        <div className="container mx-auto p-6 text-gray-900 font-sans">
            <section className="mb-12">
                <h1 className="gradient-text text-4xl font-bold mb-6">Prerequisites</h1>

                <div className="mb-8">
                    <p className="text-lg leading-relaxed">
                        To ensure a smooth setup and operation of your local development environment using our project,
                        please ensure your system meets the following requirements:
                    </p>
                </div>

                <div className="mb-12">
                    <h2 className="text-2xl font-semibold mb-4">System Requirements</h2>

                    <div className="mb-4">
                        <h3 className="text-xl font-semibold mb-2">Docker</h3>
                        <p className="text-lg leading-relaxed">
                            <span className="font-semibold">Version:</span> Ensure you have Docker installed. We
                            recommend using the latest stable version of Docker.
                        </p>
                        <p className="text-lg leading-relaxed">
                            <span className="font-semibold">Installation Guide:</span> You can follow the official
                            Docker installation guide <a href="https://docs.docker.com/get-docker/"
                                                         className="text-blue-500 underline">here</a>.
                        </p>
                    </div>

                    <div className="mb-4">
                        <h3 className="text-xl font-semibold mb-2">Memory</h3>
                        <p className="text-lg leading-relaxed">
                            <span className="font-semibold">Minimum Requirement:</span> 8 GB of RAM
                        </p>
                        <p className="text-lg leading-relaxed">
                            This is to ensure that Docker containers can run efficiently without performance issues.
                        </p>
                    </div>

                    <div className="mb-4">
                        <h3 className="text-xl font-semibold mb-2">Disk Space</h3>
                        <p className="text-lg leading-relaxed">
                            <span className="font-semibold">Minimum Requirement:</span> 10 GB of available disk space
                        </p>
                        <p className="text-lg leading-relaxed">
                            This space is necessary to accommodate Docker images and containers, as well as any
                            additional project dependencies.
                        </p>
                    </div>
                </div>

                <div className="mb-12">
                    <h2 className="text-2xl font-semibold mb-4">Checking it with Kampa Client</h2>
                    <p className="text-lg leading-relaxed">
                        If you have the kampa cli installed you can run the following command:
                    </p>
                    <CodeComponent content={`
\`\`\`shell
kampa check
\`\`\`
    `} />
                </div>

                <div>
                    <p className="text-lg leading-relaxed">
                        Ensure to regularly check for updates on these requirements, especially when new versions of
                        Docker or other dependencies are released.
                    </p>
                </div>
            </section>
        </div>
    );
};

export default DocumentationPrerequisites;
