import React, { useEffect, useState } from 'react';
import {Button, Form, Input, message, Switch, Col, Row, Tooltip, Popconfirm, InputNumber, Tabs, Divider} from 'antd';
import {ServiceVersion} from "./Types.ts";
import {useNavigate, useParams} from "react-router-dom";
import { BreadcrumbLink } from "../../Shared/Types.ts";
import useBreadcrumbStore from "../../Stores/useBreadcrumbStore.tsx";
import CodeTextAreaComponent from "../../Shared/CodeTextAreaComponent.tsx";
import {MinusCircleIcon, PlusCircleIcon} from "@heroicons/react/24/outline";
import ServiceVersionRepository from "./Repositories/ServiceVersionRepository.ts";
import serviceVersionRepository from "./Repositories/ServiceVersionRepository.ts";
import type { TabsProps } from 'antd';
import {DeleteOutlined} from "@ant-design/icons";

const { TextArea } = Input;


const EditServiceVersion: React.FC = () => {
    const [form] = Form.useForm();
    const params = useParams();
    const navigate = useNavigate();
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [messageApi, contextHolder] = message.useMessage();
    const [code, setCode] = useState<string>('');
    const [entrypoint, setEntrypoint] = useState<string>('');
    const [data, setData] = useState<ServiceVersion>();
    const [loading, setLoading] = useState<boolean>(true)

    const serviceUuid = params.serviceUuid;
    const versionUuid = params.versionUuid

    const breadcrumbs: BreadcrumbLink[] = [
        {
            href: '/app/services',
            title: 'Services',
        },
        {
            href: `/app/services/${serviceUuid}/versions/${versionUuid}`,
            title: 'Edit Version',
        },
    ];

    useEffect(() => {
        setBreadcrumb(breadcrumbs);

        const fetchService = async (serviceUuid: string | undefined) => {
            try {
                const response: ServiceVersion = await ServiceVersionRepository.findByUuid(serviceUuid, versionUuid);
                //form.setFieldsValue(response);

                setData(response)
                setCode(response.dockerfile)
                setEntrypoint(response.entrypoint)
                setLoading(false)
            } catch (error) {
                console.error('Error fetching service:', error);
            }
        };

        fetchService(serviceUuid);
    }, []);

    const onFinish = async (values: ServiceVersion) => {
        values['service_uuid'] = serviceUuid;
        values['uuid'] = versionUuid;

        try {
            await serviceVersionRepository.update(values);
            messageApi.open({
                type: 'success',
                content: 'Service version updated successfully',
            });
            setTimeout(() => navigate(`/app/services/${values.service_uuid}`), 2250);
        } catch (error) {
            message.error('Failed to save: ' + error?.response?.data?.message);
        }
    };

    const confirm: (uuid) => void = async (uuid) => {
        try {
            await ServiceVersionRepository.destroy(serviceUuid, uuid);

            messageApi.open({
                type: 'success',
                content: 'Service version deleted successfully',
            });

            setTimeout(() => navigate(`/app/services/${serviceUuid}`), 2250);
        } catch (error) {
            message.error('Failed to delete service: ' + error.message);
        }
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Identification',
            forceRender: true,
            children: <>
                <Form.Item
                    label={'Name of this image'}
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: 'This is a required field'
                        }
                    ]}
                >
                    <Input
                        placeholder={'Ex: Laravel 11 - With PHP 8.2'}
                    />
                </Form.Item>

                <Form.Item
                    label={<>
                        <Tooltip title="Unique identifier for the image">
                            Image identifier
                        </Tooltip>
                    </>}
                    name='image'
                    rules={[
                        {
                            required: true,
                            message: 'This is a required field'
                        }
                    ]}
                >
                    <Input
                        placeholder={'Ex: laravel-11-PHP-8.2'}
                    />
                </Form.Item>


                <Form.Item
                    label={'Instructions'}
                    name='instructions'
                >
                    <TextArea rows={15}/>
                </Form.Item>


            </>,
        },
        {
            key: '2',
            label: 'Build Details',
            forceRender: true,
            children: <>
                <CodeTextAreaComponent
                    label={'Dockerfile:'}
                    name={'dockerfile'}
                    rules={[
                        {
                            required: true,
                            message: 'This is a required field'
                        }
                    ]}
                    code={code}
                    setCode={setCode}
                    language={'docker'}
                />

                <CodeTextAreaComponent
                    label={'Entrypoint:'}
                    name={'entrypoint'}

                    code={entrypoint}
                    setCode={setEntrypoint}
                    language={'shell'}
                />
            </>,
        },
        {
            key: '3',
            label: 'Configuration',
            forceRender: true,
            children: <>
                <h2 className={'mt-2 mb-2 text-xl'}>Variables</h2>

                <Form.List name={'variables'}>
                    {(fields, {add, remove}) => (
                        <>
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusCircleIcon className="h-5 w-5"/>}
                                >
                                    Add variable
                                </Button>
                            </Form.Item>

                            {fields.map(({key, name, fieldKey, ...restField}) => (
                                <Row key={key} gutter={[16, 16]} align="middle">
                                    <Col span={6}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'name']}
                                            label="Name"
                                            rules={[{required: true, message: 'Missing name'}]}
                                        >
                                            <Input placeholder="MYSQL_ROOT_PASSWORD"/>
                                        </Form.Item>

                                    </Col>

                                    <Col span={6}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'default']}
                                            label="Default value"
                                            rules={[{required: true, message: 'Missing default value'}]}
                                        >
                                            <Input placeholder="Password"/>
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'is_required']}
                                            label="Is required?"
                                            valuePropName="checked"
                                        >
                                            <Switch/>
                                        </Form.Item>
                                    </Col>

                                    <MinusCircleIcon
                                        onClick={() => remove(name)}
                                        className="h-5 w-5 text-red-500 cursor-pointer"
                                    />
                                </Row>
                            ))}
                        </>
                    )}
                </Form.List>

                <div className={'m-6'}></div>

                <h2 className={'mt-2 mb-2 text-xl'}>Volumes</h2>

                <Form.List name={'volumes'}>
                    {(fields, {add, remove}) => (
                        <>
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusCircleIcon className="h-5 w-5"/>}
                                >
                                    Add volume
                                </Button>
                            </Form.Item>

                            {fields.map(({key, name, fieldKey, ...restField}) => (
                                <Row key={key} gutter={[16, 16]} align="middle">
                                    <Col span={6}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'local_folder']}
                                            label="Local folder:"
                                            rules={[{required: true, message: 'Missing local folder'}]}
                                        >
                                            <Input placeholder="data"/>
                                        </Form.Item>

                                    </Col>

                                    <Col span={6}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'container_folder']}
                                            label="Container Folder:"
                                            rules={[{required: true, message: 'Missing container folder'}]}
                                        >
                                            <Input placeholder="/var/lib/mysql"/>
                                        </Form.Item>
                                    </Col>

                                    <MinusCircleIcon
                                        onClick={() => remove(name)}
                                        className="h-5 w-5 text-red-500 cursor-pointer"
                                    />
                                </Row>
                            ))}
                        </>
                    )}
                </Form.List>

                <div className={'m-6'}></div>

                <h2 className={'mt-2 mb-2 text-xl'}>Ports</h2>

                <Form.List name={'ports'}>
                    {(fields, {add, remove}) => (
                        <>
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusCircleIcon className="h-5 w-5"/>}
                                >
                                    Add port
                                </Button>
                            </Form.Item>

                            {fields.map(({key, name, fieldKey, ...restField}) => (
                                <Row key={key} gutter={[16, 16]} align="middle">
                                    <Col span={6}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'number']}
                                            label="Number:"
                                            rules={[{required: true, message: 'Missing local folder'}]}
                                        >
                                            <InputNumber min={1}/>
                                        </Form.Item>

                                    </Col>

                                    <Col span={6}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'protocol']}
                                            label="Protocol:"
                                            rules={[{required: true, message: 'Missing container folder'}]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>

                                    <MinusCircleIcon
                                        onClick={() => remove(name)}
                                        className="h-5 w-5 text-red-500 cursor-pointer"
                                    />
                                </Row>
                            ))}
                        </>
                    )}
                </Form.List>

                <div className={'m-6'}></div>

            </>,
        },
        {
            key: '4',
            label: 'Auxiliary files',
            forceRender: true,
            children: <>
                <Form.List name={'files'}>
                    {(fields, {add, remove}) => (
                        <>
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusCircleIcon className="h-5 w-5"/>}
                                >
                                    Add file
                                </Button>
                            </Form.Item>

                            {fields.map(({key, name, fieldKey, ...restField}) => (
                                <>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'name']}
                                        label="Name"
                                        rules={[{required: true, message: 'Missing name'}]}
                                    >
                                        <Input placeholder="site.conf"/>
                                    </Form.Item>


                                    <Form.Item
                                        {...restField}
                                        name={[name, 'container_path']}
                                        label={
                                            <Tooltip title="Example: /etc/supervisor/conf.d/supervisor.conf">
                                                <span>Container Path with the file name</span>
                                            </Tooltip>
                                        }
                                        rules={[{required: true, message: 'Missing container path'}]}
                                    >
                                        <Input placeholder="/etc/supervisor/conf.d/supervisor.conf"/>
                                    </Form.Item>




                                    <Form.Item
                                        {...restField}
                                        name={[name, 'content']}
                                        label="File Content"
                                        rules={[{required: true, message: 'Missing file content'}]}
                                    >
                                        <TextArea rows={15} />
                                    </Form.Item>


                                    <Popconfirm
                                        title="Delete"
                                        description="Are you sure to delete this file?"
                                        onConfirm={() => remove(name)}
                                        okText="Yes"
                                        cancelText="No"
                                        className={''}
                                    >
                                        <Button
                                            icon={<DeleteOutlined />}
                                            className="w-100 mt-4 px-2 py-1 rounded-lg mb-4 bg-slate-50 hover:bg-gray-400 text-center"
                                        >
                                            Remove this file
                                        </Button>
                                    </Popconfirm>

                                    <Divider className={"mb-8"} />
                                </>
                            ))}
                        </>
                    )}
                </Form.List>
            </>
        }
    ];

    if (loading) {
        return <>
            <div className="container px-6 py-8 mx-auto">
                loading
            </div>
        </>
    }

    return (
        <>
        {contextHolder}
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">Services</h3>
                <div className="flex bg-white flex-col mt-8">
                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div
                            className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">

                            <h3 className="text-xl font-medium text-gray-700 mb-4">Version</h3>

                            <Form
                                layout="vertical"
                                form={form}
                                initialValues={data}
                                onFinish={onFinish}
                            >
                                <Tabs defaultActiveKey="1" items={items}/>


                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>

                <div className="flex bg-white flex-col mt-8">

                        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">


                            <div
                                className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                                <h3 className="text-xl font-medium text-gray-700 mb-4">Delete</h3>

                                <p className="text-sm text-gray-700 mb-4">
                                    Deleting this item will remove all associated data and cannot be reversed.
                                    Please ensure you have backed up any important information before proceeding.
                                </p>

                                <Popconfirm
                                    title="Delete"
                                    description="Are you sure to delete this item?"
                                    onConfirm={() => confirm(versionUuid)}
                                    okText="Yes"
                                    cancelText="No"
                                    className={''}
                                >
                                    <Button danger>Yes, I'll permanently delete it</Button>
                                </Popconfirm>

                            </div>
                        </div>
                    </div>
            </div>
        </>
    );
};

export default EditServiceVersion;
