import React from 'react';
import { Form, Select } from 'antd';

interface Item {
    label: string;
    value: string;
}

interface ServiceSelectComponentProps {
    name: any;
    options: Item[];
    onChange: any;
}

const ServiceSelectComponent: React.FC<ServiceSelectComponentProps> = ({ name, options, onChange }) => {
    const combinedOptions = [
        ...options,
        // {
        //     label: 'From the public registry',
        //     value: 'public',
        // },
    ];

    if (undefined === onChange) {
        onChange = () => {};
    }

    return (
        <Form.Item
            label="Service:"
            name={name}
            rules={[{ required: true, message: 'Please select a service' }]}
        >
            <Select
                placeholder="Select a service"
                onChange={onChange}
                options={combinedOptions}
            />
        </Form.Item>
    );
};

export default ServiceSelectComponent;
