import React, {useEffect} from "react";
import useBreadcrumbStore from "../Stores/useBreadcrumbStore.tsx";
import {BreadcrumbLink} from "../Shared/Types.ts";
import {Button, Form, Input, message, Select} from "antd";
import {useNavigate} from "react-router-dom";
import {UserInvite} from "./Types.ts";
import userInviteRepository from "./Repositories/UserInviteRepository.ts";

// const { TextArea } = Input;

const breadcrumbs: BreadcrumbLink[] = [
    {
        href: '/app/users',
        title: 'Users',
    },
    {
        href: '/app/users/create',
        title: 'Invite',
    },
];

const CreateUserInvite: React.FC = () => {
    const setBreadcrumb = useBreadcrumbStore((state) => state.setBreadcrumb);
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        setBreadcrumb(breadcrumbs);
    }, []);

    const onFinish = async (values: UserInvite) => {
        try {
            await userInviteRepository.save(values);

            messageApi.open({
                type: 'success',
                content: 'User invited successfully',
            });

            setTimeout(() => navigate('/app/users'), 2250);
        } catch (error) {
            message.error('Failed to create service: ' + error.message);
        }
    };

    return (
        <>
            {contextHolder}
            <div className="container px-6 py-8 mx-auto">
                <h3 className="text-3xl font-medium text-gray-700">User invite</h3>
                <div className="flex bg-white flex-col mt-8">
                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div
                            className="inline-block p-4 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <Form
                                layout="vertical"
                                form={form}
                                 onFinish={onFinish}
                            >

                                <Form.Item
                                    label="Name:"
                                    name="invitee_name"
                                    rules={[{ required: true, message: 'Please input the name' }]}
                                >
                                    <Input placeholder="Name" />
                                </Form.Item>


                                <Form.Item
                                    label="Email:"
                                    name="invitee_email"
                                    rules={[{ required: true, message: 'Please input the email' }]}
                                >
                                    <Input placeholder="Email" />
                                </Form.Item>


                                <Form.Item
                                    label="Role:"
                                    name="role"
                                    rules={[{ required: true, message: 'Please input the role' }]}
                                >
                                    <Select
                                        options={[
                                            { value: 'manager', label: 'Manager'},
                                            { value: 'user', label: 'Common user'},
                                        ]}
                                    />
                                </Form.Item>


                                {/*<Form.Item label="Message:" name="message">*/}
                                {/*    <TextArea*/}
                                {/*        rows={4}*/}
                                {/*        placeholder="Send a custom message"*/}
                                {/*        maxLength={150}*/}
                                {/*        showCount*/}
                                {/*    />*/}
                                {/*</Form.Item>*/}

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateUserInvite;
