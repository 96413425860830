import axiosInstance from "../../../axios.ts";
import {Documentation} from "../../../App/DocumentationManager/Types.ts";

const findByPath = async (path: string | undefined): Promise<Documentation> => {
    try {
        const response = await axiosInstance.get(`/documentations/path/${path}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Fetch by path failed');
    }
};


export default {
    findByPath,
};