import React from 'react';

interface AkamaiLogoProps {
    className?: string;
    fill?: string;
    size?: number | string;
}

const AkamaiLogo: React.FC<AkamaiLogoProps> = ({
                                           className = 'w-4 h-4',
                                           fill = '#009CDE',
                                           size = 12, // default baseado no w-20 original
                                       }) => {
    return (
        <svg
            viewBox="0 0 50 54"
            xmlns="http://www.w3.org/2000/svg"
            className={`h-full rounded-sm ${className}`}
            width={size}
            height={size}
            fill={fill}
        >
            <path d="M27.591946,52.7910448 C28.8576316,53.1940299 28.8070041,54 27.3894363,54 C12.302464,54 0,41.9104478 0,27 C0,12.0895522 12.2518366,0 27.3894363,0 C28.8070041,0 29.1107687,0.755597015 27.9463379,1.10820896 C16.605795,4.33208955 8.3028975,14.7089552 8.3028975,27 C8.3028975,39.1399254 16.4032853,49.4160448 27.591946,52.7910448 M13.4668947,33.2966418 C13.4162673,32.5914179 13.3656399,31.8358209 13.3656399,31.0802239 C13.3656399,19.2425373 23.0354778,9.62126866 34.9329224,9.62126866 C46.1722105,9.62126866 49.5642479,14.608209 49.9692673,14.3059701 C50.4249141,13.9533582 45.868446,4.02985075 32.6546883,4.02985075 C20.7572438,4.02985075 11.0874058,13.6511194 11.0874058,25.488806 C11.0874058,28.2089552 11.59368,30.8283582 12.5556011,33.2462687 C12.9606205,34.2537313 13.5681496,34.3041045 13.4668947,33.2966418 M22.5292036,17.8320896 C28.1488476,15.4141791 35.1860595,15.3134328 42.0713892,17.7313433 C46.7291122,19.3432836 49.4123656,21.6604478 49.6148753,21.5597015 C49.9692673,21.4085821 46.9316219,16.5727612 41.3626052,14.4570896 C34.6291579,11.9384328 27.4400637,13.2481343 22.1748116,17.3787313 C21.61791,17.8320896 21.8204197,18.1343284 22.5292036,17.8320896" />
        </svg>
    );
};

export default AkamaiLogo;
